import { useSingleActivityAttachmentQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { ActivityAttachment } from '../api';
  
  export const ActivityAttachmentPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Activity Attachments"
        queryHook={useSingleActivityAttachmentQuery}
        Details={<ActivityAttachmentDetails />}
      />
    );
  };

  export const ActivityAttachmentDetails = () => {
    const [activityattachment] = useSingleEntity<ActivityAttachment>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Activity Attachments" EntityField="ID" />} value={activityattachment?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Activity Attachments" EntityField="Attachments" />} value={activityattachment?.Attachments} />
        <Field label={<EntityFieldDisplayName Entity="Activity Attachments" EntityField="CreatedAt" />} value={activityattachment?.CreatedAt &&
            DateTime.fromMillis(activityattachment.CreatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Activity Attachments" EntityField="UpdatedAt" />} value={activityattachment?.UpdatedAt &&
            DateTime.fromMillis(activityattachment.UpdatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
      </div>
    );
  };
