import { useSingleAuthorizationRoleQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { AuthorizationRole } from '../api';
  
  export const AuthorizationRolePage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Authorization Roles"
        queryHook={useSingleAuthorizationRoleQuery}
        Details={<AuthorizationRoleDetails />}
      />
    );
  };

  export const AuthorizationRoleDetails = () => {
    const [authorizationrole] = useSingleEntity<AuthorizationRole>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Authorization Roles" EntityField="ID" />} value={authorizationrole?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Authorization Roles" EntityField="AuthorizationID" />} value={<EntityLink Entity="Authorization Roles" EntityField="AuthorizationID">{authorizationrole?.AuthorizationID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Authorization Roles" EntityField="RoleID" />} value={<EntityLink Entity="Authorization Roles" EntityField="RoleID">{authorizationrole?.RoleID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Authorization Roles" EntityField="Type" />} value={authorizationrole?.Type} />
        <Field label={<EntityFieldDisplayName Entity="Authorization Roles" EntityField="CreatedAt" />} value={authorizationrole?.CreatedAt &&
            DateTime.fromMillis(authorizationrole.CreatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Authorization Roles" EntityField="UpdatedAt" />} value={authorizationrole?.UpdatedAt &&
            DateTime.fromMillis(authorizationrole.UpdatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Authorization Roles" EntityField="Role" />} value={authorizationrole?.Role} />
      </div>
    );
  };
