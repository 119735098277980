import { StackLayout as _StackLayout } from '@progress/kendo-react-layout';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { useHideChrome } from '../../hooks/useHideChrome';
import { Title } from '../atoms';

type TableWithHeaderProps = {
  title: string;
  titleToolbar?: ReactNode;
  header: ReactNode;
  enableHeader?: boolean;
  table: ReactNode;
};

const StackLayout = styled(_StackLayout)`
  padding: ${({ theme }) => theme.spacing(1)};
  padding-bottom: 0;
`;

const ShortBox = styled.div`
  display: flex;
  flex: 0 1;
  flex-direction: column;
`;

const TitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  flex: 1 1;
`;

export const TableWithHeader = ({
  header,
  enableHeader = true,
  title,
  titleToolbar,
  table,
}: TableWithHeaderProps) => {
  const hideChrome = useHideChrome();
  return hideChrome ? (
    <>{table}</>
  ) : (
    <StackLayout orientation="vertical" gap={2} align={{ vertical: 'top' }}>
      <ShortBox>
        <TitleRow>
          <Title>{title}</Title>
          {titleToolbar}
        </TitleRow>
        {enableHeader && header}
      </ShortBox>
      {table}
    </StackLayout>
  );
};
