import { useSingleProjectRateTypeQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { ProjectRateType } from '../api';
  
  export const ProjectRateTypePage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Project Rate Types"
        queryHook={useSingleProjectRateTypeQuery}
        Details={<ProjectRateTypeDetails />}
      />
    );
  };

  export const ProjectRateTypeDetails = () => {
    const [projectratetype] = useSingleEntity<ProjectRateType>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Project Rate Types" EntityField="ID" />} value={projectratetype?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Project Rate Types" EntityField="Name" />} value={projectratetype?.Name} />
        <Field label={<EntityFieldDisplayName Entity="Project Rate Types" EntityField="Code" />} value={projectratetype?.Code} />
        <Field label={<EntityFieldDisplayName Entity="Project Rate Types" EntityField="Description" />} value={projectratetype?.Description} />
        <Field label={<EntityFieldDisplayName Entity="Project Rate Types" EntityField="CreatedAt" />} value={projectratetype?.CreatedAt &&
            DateTime.fromMillis(projectratetype.CreatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Project Rate Types" EntityField="UpdatedAt" />} value={projectratetype?.UpdatedAt &&
            DateTime.fromMillis(projectratetype.UpdatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
      </div>
    );
  };
