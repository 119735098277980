import { useSingleUserViewRunDetailQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { UserViewRunDetail } from '../api';
  
  export const UserViewRunDetailPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="User View Run Details"
        queryHook={useSingleUserViewRunDetailQuery}
        Details={<UserViewRunDetailDetails />}
      />
    );
  };

  export const UserViewRunDetailDetails = () => {
    const [userviewrundetail] = useSingleEntity<UserViewRunDetail>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="User View Run Details" EntityField="ID" />} value={userviewrundetail?.ID} />
        <Field label={<EntityFieldDisplayName Entity="User View Run Details" EntityField="UserViewRunID" />} value={<EntityLink Entity="User View Run Details" EntityField="UserViewRunID">{userviewrundetail?.UserViewRunID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="User View Run Details" EntityField="RecordID" />} value={userviewrundetail?.RecordID} />
        <Field label={<EntityFieldDisplayName Entity="User View Run Details" EntityField="UserViewID" />} value={userviewrundetail?.UserViewID} />
        <Field label={<EntityFieldDisplayName Entity="User View Run Details" EntityField="EntityID" />} value={userviewrundetail?.EntityID} />
      </div>
    );
  };
