import { gql, useQuery } from '@apollo/client';
import { ModifierDetails } from '@apollo/client/cache';
import { CompositeFilterDescriptor } from '@progress/kendo-data-query';
import { chain, isEqual } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { MIN_SEARCH_LENGTH, SEARCH_DEBOUNCE_MS } from '../system/config';
import { ensureArray, parseJSON, queryField } from '../system/util';
import { useDebounce } from './useDebounce';

const viewQuery = ({ ClassName, fields }: { ClassName: string; fields: string }) => gql`
  query ${ClassName}Grid($input: RunViewByIDInput!) {
    Run${ClassName}ViewByID(input: $input) {
      Results {
        ID
        ${fields}
      }
    }
  }
`;

const unsavedViewQuery = ({ ClassName, fields }: { ClassName: string; fields: string }) => gql`
  query ${ClassName}Grid($input: RunUnsavedViewInput!) {
    Run${ClassName}UnsavedView(input: $input) {
      Results { 
        ID
        ${fields}
      }
    }
  }
`;

const getQueryState = ({
  EntityName,
  ViewID,
  ClassName,
  fields,
}: {
  EntityName: string;
  ViewID: number | undefined;
  ClassName: string;
  fields: string;
}) =>
  ViewID === undefined
    ? {
        query: unsavedViewQuery({ ClassName, fields }),
        input: { EntityName },
      }
    : {
        query: viewQuery({ ClassName, fields }),
        input: { ViewID },
      };

export const useEntityListData = ({
  entity,
  visibleFieldNames,
  missingViewData,
  ViewID,
  initalSearch,
  ExtraFilter = '',
  filter,
}: {
  entity: {
    AllowUserSearchAPI: boolean;
    AllowAllRowsAPI: boolean;
    ClassName: string;
    Name: string;
  };
  visibleFieldNames: string[];
  missingViewData: boolean;
  ViewID?: number;
  initalSearch?: string;
  ExtraFilter?: string;
  filter?: CompositeFilterDescriptor;
}) => {
  const { AllowUserSearchAPI, AllowAllRowsAPI, ClassName, Name: EntityName } = entity;
  const [search, setSearch] = useState(initalSearch ?? '');
  const debouncedSearch = useDebounce(search, SEARCH_DEBOUNCE_MS);
  const UserSearchString = AllowUserSearchAPI ? debouncedSearch : '';
  const rowsField = ViewID === undefined ? `Run${ClassName}UnsavedView` : `Run${ClassName}ViewByID`;

  const hasFilterOrSearch =
    (filter?.filters.length ?? 0) > 0 || UserSearchString.length >= MIN_SEARCH_LENGTH;
  const invalidSearch = !hasFilterOrSearch && !AllowAllRowsAPI && !ExtraFilter;

  const skip = invalidSearch || missingViewData;
  const fields = chain(visibleFieldNames).sort().join(' ').value();

  const [{ query, input }, setQueryState] = useState(
    getQueryState({ EntityName, ViewID, ClassName, fields })
  );
  useEffect(() => {
    setQueryState(getQueryState({ EntityName, ViewID, ClassName, fields }));
  }, [ViewID, ClassName, fields, EntityName]);

  const {
    data,
    loading: dataLoading,
    // not available due to https://github.com/apollographql/apollo-client/issues/6190
    // refetch: refetchViewData,
    client,
  } = useQuery(query, {
    variables: { input: { ...input, ExtraFilter, UserSearchString } },
    skip,
  });

  const refetchViewData = useCallback(() => {
    // workaround for https://github.com/apollographql/apollo-client/issues/6190
    const field = queryField(query);
    field &&
      client.cache.modify({
        fields: {
          [field]: (existing, { storeFieldName, DELETE }: ModifierDetails) => {
            const queryVars = parseJSON(
              storeFieldName.substring(field.length + 1, storeFieldName.length - 1)
            );
            return isEqual(queryVars, {
              input: { ...input, ExtraFilter, UserSearchString },
            })
              ? DELETE
              : existing;
          },
        },
      });
  }, [query, client.cache, input, ExtraFilter, UserSearchString]);

  const rows = ensureArray<Record<string, unknown>[]>(data?.[rowsField]?.Results);

  const [refetching, setRefetching] = useState(false);
  const refetch = useCallback(async () => {
    setRefetching(true);
    await refetchViewData();
    setRefetching(false);
  }, [refetchViewData]);

  const loading = !skip && (dataLoading || refetching);
  const handleSearch = useCallback((searchString: string) => setSearch(searchString), []);

  return { rows, loading, handleSearch, refetch };
};
