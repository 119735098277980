import { process, State } from '@progress/kendo-data-query';
import { Icon } from '@progress/kendo-react-common';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import {
  Grid,
  GridColumn,
  GridDataStateChangeEvent,
  GridToolbar,
} from '@progress/kendo-react-grid';
import { chain, filter, isEqual, keyBy, map } from 'lodash';
import { createRef, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { ColumnSettings, LoadingGrid, LoadingGridProps } from '../../components';
import { Entity } from '../../context/EntitiesProvider';
import { useEntityListData } from '../../hooks/useEntityListData';
import { useEntityNavigation } from '../../hooks/useEntityNavigation';
import { useView, ViewGridState } from '../../hooks/useView';

import { Button } from '@progress/kendo-react-buttons';
import { LoadingContext } from '../../context/LoadingContext';
import { emptyObject, ifDifferent, toExcelColumn, toKendoColumn } from '../../system/util';

type EntityTableProps = Omit<
  LoadingGridProps,
  'data' | 'sort' | 'filter' | 'total' | 'onDataStateChange'
> & {
  entity: Entity;
  ViewID?: number;
  columnSettings?: Array<ColumnSettings>;
  onDataStateChange?: (e: Pick<GridDataStateChangeEvent, 'dataState'>) => void;
  onViewGridState?: (view: ViewGridState) => void;
  dataState?: State;
  search?: string;
  ExtraFilter?: string;
};

const GridStyle = styled.div`
  min-height: calc(100vh - 280px);
  max-height: calc(100vh - 280px);
  min-width: 100%;
  & .k-grid {
    min-height: calc(100vh - 280px);
    max-height: calc(100vh - 280px);
  }
  & .k-table-row {
    cursor: pointer;
  }
  & .k-toolbar {
    order: 1;
    border-top: 1px solid ${({ theme }) => theme.colors.divider};
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
  & .toolbar--top {
    order: 0;
  }
  & .k-grid td {
    white-space: nowrap;
  }
`;

export const EntityTable = ({
  entity,
  ViewID,
  dataState: controlledDataState,
  onDataStateChange,
  onViewGridState,
  columnSettings: controlledColumnSettings,
  search,
  ExtraFilter,
  ...props
}: EntityTableProps) => {
  const { navigateToEntity } = useEntityNavigation(entity);
  const { EntityFields } = entity;

  const [columnSettings, setColumnSettings] = useState<Array<ColumnSettings>>(
    controlledColumnSettings ??
      EntityFields.map(({ ID, Name, DisplayName, DefaultInView }) => ({
        ID,
        Name,
        DisplayName,
        hidden: !DefaultInView,
      }))
  );
  const [dataState, setDataState] = useState<State>(controlledDataState ?? emptyObject);
  useEffect(() => {
    controlledDataState && setDataState(ifDifferent(controlledDataState));
  }, [controlledDataState]);

  useEffect(() => {
    controlledColumnSettings && setColumnSettings(ifDifferent(controlledColumnSettings));
  }, [controlledColumnSettings]);

  const { missingViewData, usingView } = useView({
    ViewID,
    onViewGridState: useCallback(
      (view: ViewGridState) => {
        view?.dataState && setDataState(ifDifferent(view.dataState));
        view?.columnSettings &&
          setColumnSettings((prev) => {
            const byId = keyBy(view.columnSettings, 'ID');
            const newColumnSettings = prev.map((col) =>
              byId[col.ID]
                ? {
                    ...col,
                    ...byId[col.ID],
                  }
                : col
            );
            return isEqual(prev, newColumnSettings) ? prev : newColumnSettings;
          });
        onViewGridState?.(view);
      },
      [onViewGridState]
    ),
  });

  useEffect(() => {
    !usingView &&
      setColumnSettings(
        ifDifferent<ColumnSettings[]>(
          EntityFields.map(({ ID, Name, DisplayName, DefaultInView }) => ({
            ID,
            Name,
            DisplayName,
            hidden: !DefaultInView,
          }))
        )
      );
  }, [EntityFields, usingView]);

  const visibleColumns = useMemo(() => {
    const settingsByID = keyBy(columnSettings, 'ID');
    return chain(EntityFields)
      .filter(({ ID }) => !settingsByID[ID]?.hidden)
      .map((c) => ({
        ...c,
        width: settingsByID[c.ID]?.width,
        orderIndex: settingsByID[c.ID]?.orderIndex,
      }))
      .value();
  }, [EntityFields, columnSettings]);

  const [activeFilter, setActiveFilter] = useState(dataState.filter);
  const { rows, loading, handleSearch, refetch } = useEntityListData({
    entity,
    visibleFieldNames: map(
      filter(columnSettings, ({ hidden }) => !hidden),
      'Name'
    ),
    missingViewData,
    ViewID,
    initalSearch: search,
    ExtraFilter,
    filter: activeFilter,
  });

  useEffect(() => {
    search !== undefined && handleSearch(search);
  }, [handleSearch, search]);

  useEffect(() => {
    setActiveFilter(ifDifferent(dataState.filter));
  }, [dataState.filter]);

  useEffect(() => {
    refetch();
  }, [ViewID, activeFilter, refetch]);

  const [skip, setSkip] = useState(0);
  const pageSize = 100;

  const gridRef = createRef<Grid>();

  const [result, setResult] = useState(process(rows, { ...dataState, skip, take: pageSize }));
  useEffect(
    () => setResult(ifDifferent(process(rows, { ...dataState, skip, take: pageSize }))),
    [dataState, rows, skip]
  );

  const excelRef = useRef<ExcelExport>(null);

  return (
    <LoadingContext loading={loading}>
      <GridStyle>
        <ExcelExport ref={excelRef} />
        <LoadingGrid
          ref={gridRef}
          fixedScroll
          reorderable={!loading}
          resizable={!loading}
          sortable={!loading}
          scrollable="virtual"
          rowHeight={50}
          skip={skip}
          pageSize={pageSize}
          onPageChange={(e) => setSkip(e.page.skip)}
          selectable={{ mode: 'single' }}
          style={{ height: '100%' }}
          dataItemKey="ID"
          data={result.data}
          total={result.total}
          sort={dataState.sort}
          filter={dataState.filter}
          onDataStateChange={(e) => {
            setDataState(ifDifferent(e.dataState));
            onDataStateChange?.(e);
          }}
          onSelectionChange={(e) => {
            navigateToEntity(e.dataItems[e.startRowIndex]?.ID);
          }}
          {...props}
        >
          <GridToolbar className="toolbar--top">
            <Button
              title="Export to Excel"
              onClick={() =>
                gridRef.current &&
                excelRef.current?.save(
                  process(rows, { ...dataState, skip: 0, take: rows.length }),
                  visibleColumns.map(toExcelColumn)
                )
              }
            >
              Export to Excel
            </Button>
            <Button onClick={() => refetch()} title="Refresh data">
              <Icon name="refresh" />
            </Button>
          </GridToolbar>
          {visibleColumns.map(toKendoColumn).map(({ width, ...col }) => (
            <GridColumn key={col.id} {...col} width={Number(width)} />
          ))}
          <GridToolbar>{result.total} items</GridToolbar>
        </LoadingGrid>
      </GridStyle>
    </LoadingContext>
  );
};
