import { CompositeFilterDescriptor, State } from '@progress/kendo-data-query';
import { Icon, Typography } from '@progress/kendo-react-common';
import { Filter, FilterChangeEvent } from '@progress/kendo-react-data-tools';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { GridLayout, GridLayoutItem, StackLayout } from '@progress/kendo-react-layout';
import { isEqual, keyBy } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { Entity } from '../../context/EntitiesProvider';
import { useNotification } from '../../hooks/useNotification';
import { useResponsiveCols } from '../../hooks/useResponsiveCols';
import { emptyFilter, ifDifferent, toKendoFilterField } from '../../system/util';
import { LoadingButton } from '../atoms';
import { FormTextArea, FormTextBox, max, min } from '../form';
import { ConfirmButton } from '../molecules/ConfirmButton';
import { ColumnVisibilityList } from './ColumnVisibilityList';

const RootGridLayout = styled(GridLayout)`
  padding: ${({ theme }) => theme.spacing(2)};
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: baseline;

  & :first-child {
    margin-right: ${({ theme }) => theme.spacing(1)};
  }
`;

const ActionRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  & > :not(:last-child) {
    margin-right: ${({ theme }) => theme.spacing(2)};
  }
`;

export type ColumnSettings = {
  ID: number;
  Name: string;
  DisplayName?: string;
  orderIndex?: number;
  hidden?: boolean;
  width?: string | number;
};

export type ViewFormFields = {
  ViewID?: number;
  FilterState: string;
  GridState: string;
  Name: string;
  Description: string;
  EntityID: number;
};

type ViewEditorProps = {
  columnSettings: Array<ColumnSettings>;
  dataState: State;
  Description: string;
  entity: Entity;
  Name: string;
  onDeleteView?: (ViewID: number) => Promise<void>;
  onSaveView?: (input: ViewFormFields, filter: CompositeFilterDescriptor) => Promise<void>;
  ViewID?: number;
};

const Hidden = () => <></>;

export const ViewEditor = ({
  columnSettings,
  dataState,
  Description,
  entity,
  Name,
  onDeleteView,
  onSaveView,
  ViewID,
}: ViewEditorProps) => {
  const formRef = useRef<Form>(null);
  const twoCols = useResponsiveCols(2);
  const { sendNotification } = useNotification();
  const { ID: EntityID, EntityFields } = entity;

  const [initialValues] = useState<ViewFormFields>({
    Name,
    Description,
    FilterState: JSON.stringify(dataState.filter ?? emptyFilter),
    GridState: JSON.stringify({ dataState, columnSettings }),
    EntityID,
    ViewID,
  });

  const [editedColumnSettings, setEditedColumnSettings] =
    useState<Array<ColumnSettings>>(columnSettings);

  const onColumnVisibilityChange = useCallback((columns: Array<ColumnSettings>) => {
    setEditedColumnSettings((prevColumnSettings) => {
      const updatedColumns = keyBy(columns, 'ID');
      const newColumnSettings: Array<ColumnSettings> = prevColumnSettings.map((c) => ({
        ...c,
        hidden: !!updatedColumns[c.ID]?.hidden,
      }));

      return isEqual(prevColumnSettings, newColumnSettings)
        ? prevColumnSettings
        : newColumnSettings;
    });
  }, []);

  const [editedFilter, setEditedFilter] = useState<CompositeFilterDescriptor>(
    dataState.filter ?? emptyFilter
  );

  const onFilterChange = useCallback(({ filter }: FilterChangeEvent) => {
    setEditedFilter(ifDifferent(filter));
  }, []);

  useEffect(() => {
    formRef.current?.onChange('FilterState', {
      value: JSON.stringify(editedFilter),
    });
    formRef.current?.onChange('GridState', {
      value: JSON.stringify({
        dataState: { ...dataState, filter: editedFilter },
        columnSettings: editedColumnSettings,
      }),
    });
  }, [formRef, dataState, editedColumnSettings, editedFilter]);

  const [submitting, setSubmitting] = useState(false);
  const onSubmit = useCallback(
    async (values: ViewFormFields) => {
      setSubmitting(true);

      await onSaveView?.(values, editedFilter);
      sendNotification('View saved', 'success');

      setSubmitting(false);
    },
    [editedFilter, onSaveView, sendNotification]
  );

  const [deleting, setDeleting] = useState(false);
  const onDelete = useCallback(async () => {
    if (ViewID !== undefined) {
      setDeleting(true);
      await onDeleteView?.(ViewID);
      setDeleting(false);
    }
  }, [ViewID, onDeleteView]);

  return (
    <RootGridLayout cols={twoCols} gap={{ cols: 32 }}>
      <GridLayoutItem>
        <HeaderRow>
          <Icon name="filter" size="large" />
          <Typography.h5>Filter</Typography.h5>
        </HeaderRow>
        <Filter
          onChange={onFilterChange}
          value={editedFilter}
          fields={EntityFields.map(toKendoFilterField)}
        />
      </GridLayoutItem>
      <GridLayoutItem style={{ paddingLeft: 32 }}>
        <HeaderRow>
          <Icon name="columns" size="large" />
          <Typography.h5>Columns Displayed</Typography.h5>
        </HeaderRow>
        <ColumnVisibilityList
          columnSettings={editedColumnSettings}
          onColumnVisibilityChange={onColumnVisibilityChange}
        />
      </GridLayoutItem>

      <GridLayoutItem colSpan={2}>
        <Form
          ref={formRef}
          initialValues={initialValues}
          onSubmit={(value) => onSubmit(value as ViewFormFields)}
          render={({ allowSubmit }) => (
            <FormElement>
              <StackLayout orientation="vertical" gap={16}>
                <StackLayout orientation="horizontal" gap={16}>
                  <Field name={'FilterState'} component={Hidden} />
                  <Field name={'GridState'} component={Hidden} />
                  <Field name={'EntityID'} component={Hidden} />
                  <Field name={'ViewID'} component={Hidden} />
                  <Field
                    id="Name"
                    name="Name"
                    label="View Name"
                    component={FormTextBox}
                    validator={[min(1), max(50)]}
                  />

                  <Field
                    id="Description"
                    name="Description"
                    label="View Description"
                    component={FormTextArea}
                    rows={3}
                    validator={max(250)}
                  />
                </StackLayout>
                <ActionRow>
                  <ConfirmButton
                    type="button"
                    themeColor="error"
                    fillMode="outline"
                    loading={deleting}
                    disabled={submitting}
                    onClick={() => onDelete()}
                  >
                    Delete
                  </ConfirmButton>
                  <LoadingButton
                    icon="save"
                    type="submit"
                    disabled={!allowSubmit || deleting}
                    loading={submitting}
                  >
                    Save
                  </LoadingButton>
                </ActionRow>
              </StackLayout>
            </FormElement>
          )}
        />
      </GridLayoutItem>
    </RootGridLayout>
  );
};
