import { createPortal } from 'react-dom';
import { useLoadingContext } from '../../context/LoadingContext';

export const LoadingPanel = ({
  loading,
  containerSelector,
}: {
  loading?: boolean;
  containerSelector?: string;
}) => {
  const loadingFromContext = useLoadingContext();
  const loadingPanel = (
    <div className="k-loading-mask">
      <span className="k-loading-text">Loading</span>
      <div className="k-loading-image"></div>
      <div className="k-loading-color"></div>
    </div>
  );
  const showLoading = loading === undefined ? loadingFromContext : loading;

  const container = document && containerSelector && document.querySelector(containerSelector);
  return !showLoading ? <></> : container ? createPortal(loadingPanel, container) : loadingPanel;
};
