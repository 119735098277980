import { useSingleInvoiceQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { Invoice } from '../api';
  
  export const InvoicePage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Invoices"
        queryHook={useSingleInvoiceQuery}
        Details={<InvoiceDetails />}
      />
    );
  };

  export const InvoiceDetails = () => {
    const [invoice] = useSingleEntity<Invoice>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Invoices" EntityField="ID" />} value={invoice?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Invoices" EntityField="BCMID" />} value={invoice?.BCMID} />
        <Field label={<EntityFieldDisplayName Entity="Invoices" EntityField="InvoiceDate" />} value={invoice?.InvoiceDate &&
            DateTime.fromMillis(invoice.InvoiceDate).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Invoices" EntityField="AccountID" />} value={<EntityLink Entity="Invoices" EntityField="AccountID">{invoice?.AccountID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Invoices" EntityField="ContactID" />} value={<EntityLink Entity="Invoices" EntityField="ContactID">{invoice?.ContactID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Invoices" EntityField="SubTotal" />} value={invoice?.SubTotal !== undefined
              ? formatCurrency(Math.floor(invoice.SubTotal), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Invoices" EntityField="Tax" />} value={invoice?.Tax !== undefined
              ? formatCurrency(Math.floor(invoice.Tax), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Invoices" EntityField="Total" />} value={invoice?.Total !== undefined
              ? formatCurrency(Math.floor(invoice.Total), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Invoices" EntityField="CompanyIntegrationID" />} value={<EntityLink Entity="Invoices" EntityField="CompanyIntegrationID">{invoice?.CompanyIntegrationID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Invoices" EntityField="ExternalSystemRecordID" />} value={invoice?.ExternalSystemRecordID} />
        <Field label={<EntityFieldDisplayName Entity="Invoices" EntityField="InvoiceNumber" />} value={invoice?.InvoiceNumber} />
        <Field label={<EntityFieldDisplayName Entity="Invoices" EntityField="PostingDate" />} value={invoice?.PostingDate &&
            DateTime.fromMillis(invoice.PostingDate).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Invoices" EntityField="DueDate" />} value={invoice?.DueDate &&
            DateTime.fromMillis(invoice.DueDate).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Invoices" EntityField="StatusID" />} value={<EntityLink Entity="Invoices" EntityField="StatusID">{invoice?.StatusID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Invoices" EntityField="PaymentTermsID" />} value={<EntityLink Entity="Invoices" EntityField="PaymentTermsID">{invoice?.PaymentTermsID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Invoices" EntityField="CreatedAt" />} value={invoice?.CreatedAt &&
            DateTime.fromMillis(invoice.CreatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Invoices" EntityField="UpdatedAt" />} value={invoice?.UpdatedAt &&
            DateTime.fromMillis(invoice.UpdatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Invoices" EntityField="Company" />} value={invoice?.Company} />
        <Field label={<EntityFieldDisplayName Entity="Invoices" EntityField="Account" />} value={invoice?.Account} />
        <Field label={<EntityFieldDisplayName Entity="Invoices" EntityField="AccountWebsite" />} value={invoice?.AccountWebsite} />
        <Field label={<EntityFieldDisplayName Entity="Invoices" EntityField="Contact" />} value={invoice?.Contact} />
        <Field label={<EntityFieldDisplayName Entity="Invoices" EntityField="ContactEmail" />} value={<Email>{invoice?.ContactEmail}</Email>} />
        <Field label={<EntityFieldDisplayName Entity="Invoices" EntityField="CompanyID" />} value={invoice?.CompanyID} />
      </div>
    );
  };
