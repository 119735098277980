import { Typography as _Typography } from '@progress/kendo-react-common';
import { Card as _Card, CardBody } from '@progress/kendo-react-layout';
import styled from 'styled-components';

const Typography = styled(_Typography.p)`
  line-height: 1;
`;

const Card = styled(_Card)`
  padding: ${({ theme }) => theme.spacing(2)};
  height: 230px;
`;

type AddressCardProps = {
  AddressLine1?: string;
  AddressLine2?: string;
  AddressLine3?: string;
  City?: string;
  StateProvince?: string;
  PostalCode?: string;
  Country?: string;
};

export const AddressCard = ({
  AddressLine1,
  AddressLine2,
  AddressLine3,
  City,
  StateProvince,
  PostalCode,
  Country,
}: AddressCardProps) => {
  return (
    <Card>
      <CardBody>
        <Typography>{AddressLine1}</Typography>
        <Typography>{AddressLine2}</Typography>
        <Typography>{AddressLine3}</Typography>
        <Typography>{City}</Typography>
        <Typography>{StateProvince}</Typography>
        <Typography>{PostalCode}</Typography>
        <Typography>{Country}</Typography>
      </CardBody>
    </Card>
  );
};
