import { ApolloClient, from, HttpLink } from '@apollo/client';
import { GRAPHQL_URI, NODE_ENV } from '../system/config';
import { createCache } from './cache';
import { errorLink } from './errorLink';

export const createClient = async ({
  token,
  onErrorNotification,
  onRefreshError,
}: {
  token: string;
  onErrorNotification: (error: string) => void;
  onRefreshError: () => void;
}) =>
  new ApolloClient({
    link: from(
      token
        ? [
            errorLink({ onErrorNotification, onRefreshError }),
            new HttpLink({
              uri: GRAPHQL_URI,
              headers: token ? { authorization: `Bearer ${token}` } : {},
            }),
          ]
        : []
    ),
    cache: await createCache(),
    credentials: 'include',
    connectToDevTools: NODE_ENV !== 'production',
  });
