import { Switch } from '@progress/kendo-react-inputs';
import { StackLayout as _StackLayout } from '@progress/kendo-react-layout';
import { ListView as _ListView } from '@progress/kendo-react-listview';
import { sumBy } from 'lodash';
import { useCallback } from 'react';
import styled from 'styled-components';

type Column = {
  ID: number;
  Name?: string;
  DisplayName?: string;
  hidden?: boolean;
};

const ListView = styled(_ListView)`
  max-height: 350px;
`;

export type ColumnVisibilityListProps<T extends Column> = {
  columnSettings: Array<T>;
  onColumnVisibilityChange: (columnSettings: Array<T>) => void;
};

const ShrinkBox = styled.div`
  display: flex;
  flex: initial;
  padding-left: ${({ theme }) => theme.spacing(2)};
  padding-right: ${({ theme }) => theme.spacing(4)};
`;

const StackLayout = styled(_StackLayout)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const NarrowBox = styled.div`
  padding-bottom: ${({ theme }) => theme.spacing(2)};
  & > * {
    margin-bottom: ${({ theme }) => theme.spacing(1)};
  }
`;

export const ColumnVisibilityList = <T extends Column = Column>({
  columnSettings,
  onColumnVisibilityChange,
}: ColumnVisibilityListProps<T>) => {
  const setHidden = useCallback(
    (ID: number, hidden: boolean, prev: Array<T>) => {
      const itemIndex = prev.findIndex((item) => item.ID === ID);
      const newColumnSettings =
        itemIndex < 0
          ? prev
          : [
              ...prev.slice(0, itemIndex),
              { ...prev[itemIndex], hidden },
              ...prev.slice(itemIndex + 1),
            ];

      onColumnVisibilityChange(newColumnSettings);
    },
    [onColumnVisibilityChange]
  );

  return (
    <NarrowBox>
      <ListView
        data={columnSettings}
        item={({ dataItem }) => {
          const { DisplayName, hidden, ID, Name } = dataItem;
          return (
            <StackLayout key={ID}>
              <ShrinkBox>
                <Switch
                  onChange={(e) => setHidden(ID, !e.target.value, columnSettings)}
                  checked={!hidden}
                  disabled={sumBy(columnSettings, (c) => (c.hidden ? 0 : 1)) < 2 && !hidden}
                />
              </ShrinkBox>
              {DisplayName ?? Name}
            </StackLayout>
          );
        }}
      />
    </NarrowBox>
  );
};
