import { Card as _Card, CardBody, CardTitle } from '@progress/kendo-react-layout';
import { sortBy } from 'lodash';
import styled from 'styled-components';
import { Entity } from '../../context/EntitiesProvider';
import { LoadingPanel } from '../atoms';
import { EntityFieldValue } from '../molecules';

const Tiles = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  grid-auto-flow: dense;
  grid-auto-rows: 100px;
  background-color: ${({ theme }) => theme.colors.paper};

  padding: ${({ theme }) => theme.spacing(2)};
`;

const Card = styled(_Card)`
  grid-column: span ${({ colSpan }) => colSpan};
  padding: ${({ theme }) => theme.spacing(1)};

  & .k-card-title {
    font-size: 1em;
  }
`;

type DetailTilesProps = Pick<Entity, 'EntityFields' | 'Name'> & {
  EntityData?: Record<string, unknown>;
};

export const DetailTiles = ({ EntityFields, EntityData = {} }: DetailTilesProps) => {
  const tiles = EntityFields.map((entityField) => ({
    key: entityField.Name,
    order: entityField.Sequence,
    colSpan: Math.max(Math.floor((entityField.DefaultColumnWidth ?? 100) / 100), 1),
    header: `${entityField.DisplayName ?? entityField.Name}`,
    body: <EntityFieldValue EntityField={entityField} value={EntityData?.[entityField.Name]} />,
  }));

  return (
    <Tiles id="detail-tiles" style={{ width: 'calc(100% - 15px' }}>
      <LoadingPanel />
      {sortBy(tiles, 'order').map(({ key, header, body, colSpan }) => (
        <Card key={key} colSpan={colSpan}>
          <CardTitle>{header}</CardTitle>
          <CardBody>{body}</CardBody>
        </Card>
      ))}
    </Tiles>
  );
};
