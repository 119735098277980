import { useSingleEntityPermissionQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { EntityPermission } from '../api';
  
  export const EntityPermissionPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Entity Permissions"
        queryHook={useSingleEntityPermissionQuery}
        Details={<EntityPermissionDetails />}
      />
    );
  };

  export const EntityPermissionDetails = () => {
    const [entitypermission] = useSingleEntity<EntityPermission>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Entity Permissions" EntityField="ID" />} value={entitypermission?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Entity Permissions" EntityField="EntityID" />} value={<EntityLink Entity="Entity Permissions" EntityField="EntityID">{entitypermission?.EntityID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Entity Permissions" EntityField="RoleID" />} value={<EntityLink Entity="Entity Permissions" EntityField="RoleID">{entitypermission?.RoleID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Entity Permissions" EntityField="CanCreate" />} value={entitypermission?.CanCreate} />
        <Field label={<EntityFieldDisplayName Entity="Entity Permissions" EntityField="CanRead" />} value={entitypermission?.CanRead} />
        <Field label={<EntityFieldDisplayName Entity="Entity Permissions" EntityField="CanUpdate" />} value={entitypermission?.CanUpdate} />
        <Field label={<EntityFieldDisplayName Entity="Entity Permissions" EntityField="CanDelete" />} value={entitypermission?.CanDelete} />
        <Field label={<EntityFieldDisplayName Entity="Entity Permissions" EntityField="ReadRLSFilterID" />} value={<EntityLink Entity="Entity Permissions" EntityField="ReadRLSFilterID">{entitypermission?.ReadRLSFilterID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Entity Permissions" EntityField="CreateRLSFilterID" />} value={<EntityLink Entity="Entity Permissions" EntityField="CreateRLSFilterID">{entitypermission?.CreateRLSFilterID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Entity Permissions" EntityField="UpdateRLSFilterID" />} value={<EntityLink Entity="Entity Permissions" EntityField="UpdateRLSFilterID">{entitypermission?.UpdateRLSFilterID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Entity Permissions" EntityField="DeleteRLSFilterID" />} value={<EntityLink Entity="Entity Permissions" EntityField="DeleteRLSFilterID">{entitypermission?.DeleteRLSFilterID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Entity Permissions" EntityField="CreatedAt" />} value={entitypermission?.CreatedAt &&
            DateTime.fromMillis(entitypermission.CreatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Entity Permissions" EntityField="UpdatedAt" />} value={entitypermission?.UpdatedAt &&
            DateTime.fromMillis(entitypermission.UpdatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Entity Permissions" EntityField="Entity" />} value={entitypermission?.Entity} />
        <Field label={<EntityFieldDisplayName Entity="Entity Permissions" EntityField="Role" />} value={entitypermission?.Role} />
        <Field label={<EntityFieldDisplayName Entity="Entity Permissions" EntityField="RoleSQLName" />} value={entitypermission?.RoleSQLName} />
        <Field label={<EntityFieldDisplayName Entity="Entity Permissions" EntityField="CreateRLSFilter" />} value={entitypermission?.CreateRLSFilter} />
        <Field label={<EntityFieldDisplayName Entity="Entity Permissions" EntityField="ReadRLSFilter" />} value={entitypermission?.ReadRLSFilter} />
        <Field label={<EntityFieldDisplayName Entity="Entity Permissions" EntityField="UpdateRLSFilter" />} value={entitypermission?.UpdateRLSFilter} />
        <Field label={<EntityFieldDisplayName Entity="Entity Permissions" EntityField="DeleteRLSFilter" />} value={entitypermission?.DeleteRLSFilter} />
      </div>
    );
  };
