import { useEffect, useState } from 'react';

export const usePersistentState = <S = undefined>(
  key: string,
  initialState: S | (() => S)
): [S, React.Dispatch<React.SetStateAction<S>>] => {
  const [state, setState] = useState<S>(() => {
    let cachedState;
    try {
      cachedState = JSON.parse(String(localStorage.getItem(key))) as S;
    } catch {
      /* no op */
    }

    return (
      cachedState ??
      (initialState && typeof initialState === 'function'
        ? (initialState as () => S)()
        : initialState)
    );
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
};
