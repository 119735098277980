import { Navigate, useLocation, useParams } from 'react-router-dom';
import { useEntities } from '../context/EntitiesProvider';
import { EntityPages } from '../generated/EntityPages';

export const EntityPageRoute = () => {
  const { pathname } = useLocation();
  const { appName, entityName, recordID } = useParams();
  const { AllEntitiesBySlug } = useEntities();

  const entity = AllEntitiesBySlug[entityName ?? ''];
  const EntityPage = EntityPages[entity?.Name ?? ''];

  const incompleteUrl = !appName || !entityName;
  const dataNotFound = !entity || !isFinite(Number(recordID)) || !EntityPage;

  return incompleteUrl || dataNotFound ? (
    <Navigate to="/not-found" />
  ) : (
    <EntityPage key={pathname} RecordID={Number(recordID)} />
  );
};
