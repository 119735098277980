import { createContext, PropsWithChildren, useContext } from 'react';

type LoadingContextProps = PropsWithChildren<{
  loading?: boolean;
}>;

const LoadingContextState = createContext(false);

export const useLoadingContext = () => Boolean(useContext(LoadingContextState));

export const LoadingContext = ({ loading = false, children }: LoadingContextProps) => {
  return <LoadingContextState.Provider value={loading}>{children}</LoadingContextState.Provider>;
};
