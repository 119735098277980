import { useSingleAccountCompanyIntegrationQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { AccountCompanyIntegration } from '../api';
  
  export const AccountCompanyIntegrationPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Account Company Integrations"
        queryHook={useSingleAccountCompanyIntegrationQuery}
        Details={<AccountCompanyIntegrationDetails />}
      />
    );
  };

  export const AccountCompanyIntegrationDetails = () => {
    const [accountcompanyintegration] = useSingleEntity<AccountCompanyIntegration>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Account Company Integrations" EntityField="ID" />} value={accountcompanyintegration?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Account Company Integrations" EntityField="AccountID" />} value={<EntityLink Entity="Account Company Integrations" EntityField="AccountID">{accountcompanyintegration?.AccountID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Account Company Integrations" EntityField="CompanyIntegrationID" />} value={<EntityLink Entity="Account Company Integrations" EntityField="CompanyIntegrationID">{accountcompanyintegration?.CompanyIntegrationID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Account Company Integrations" EntityField="ExternalSystemRecordID" />} value={accountcompanyintegration?.ExternalSystemRecordID} />
        <Field label={<EntityFieldDisplayName Entity="Account Company Integrations" EntityField="CreatedAt" />} value={accountcompanyintegration?.CreatedAt &&
            DateTime.fromMillis(accountcompanyintegration.CreatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Account Company Integrations" EntityField="UpdatedAt" />} value={accountcompanyintegration?.UpdatedAt &&
            DateTime.fromMillis(accountcompanyintegration.UpdatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Account Company Integrations" EntityField="AccountTypeID" />} value={<EntityLink Entity="Account Company Integrations" EntityField="AccountTypeID">{accountcompanyintegration?.AccountTypeID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Account Company Integrations" EntityField="CompanyID" />} value={accountcompanyintegration?.CompanyID} />
        <Field label={<EntityFieldDisplayName Entity="Account Company Integrations" EntityField="CompanyName" />} value={accountcompanyintegration?.CompanyName} />
        <Field label={<EntityFieldDisplayName Entity="Account Company Integrations" EntityField="Account" />} value={accountcompanyintegration?.Account} />
        <Field label={<EntityFieldDisplayName Entity="Account Company Integrations" EntityField="AccountType" />} value={accountcompanyintegration?.AccountType} />
      </div>
    );
  };
