import { useSingleEntityQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { Entity } from '../api';
  
  export const EntityPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Entities"
        queryHook={useSingleEntityQuery}
        Details={<EntityDetails />}
      />
    );
  };

  export const EntityDetails = () => {
    const [entity] = useSingleEntity<Entity>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Entities" EntityField="ID" />} value={entity?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Entities" EntityField="ParentID" />} value={<EntityLink Entity="Entities" EntityField="ParentID">{entity?.ParentID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Entities" EntityField="Name" />} value={entity?.Name} />
        <Field label={<EntityFieldDisplayName Entity="Entities" EntityField="Description" />} value={entity?.Description} />
        <Field label={<EntityFieldDisplayName Entity="Entities" EntityField="BaseTable" />} value={entity?.BaseTable} />
        <Field label={<EntityFieldDisplayName Entity="Entities" EntityField="BaseView" />} value={entity?.BaseView} />
        <Field label={<EntityFieldDisplayName Entity="Entities" EntityField="BaseViewGenerated" />} value={entity?.BaseViewGenerated} />
        <Field label={<EntityFieldDisplayName Entity="Entities" EntityField="SchemaName" />} value={entity?.SchemaName} />
        <Field label={<EntityFieldDisplayName Entity="Entities" EntityField="TrackRecordChanges" />} value={entity?.TrackRecordChanges} />
        <Field label={<EntityFieldDisplayName Entity="Entities" EntityField="AuditRecordAccess" />} value={entity?.AuditRecordAccess} />
        <Field label={<EntityFieldDisplayName Entity="Entities" EntityField="AuditViewRuns" />} value={entity?.AuditViewRuns} />
        <Field label={<EntityFieldDisplayName Entity="Entities" EntityField="IncludeInAPI" />} value={entity?.IncludeInAPI} />
        <Field label={<EntityFieldDisplayName Entity="Entities" EntityField="AllowAllRowsAPI" />} value={entity?.AllowAllRowsAPI} />
        <Field label={<EntityFieldDisplayName Entity="Entities" EntityField="AllowUpdateAPI" />} value={entity?.AllowUpdateAPI} />
        <Field label={<EntityFieldDisplayName Entity="Entities" EntityField="AllowCreateAPI" />} value={entity?.AllowCreateAPI} />
        <Field label={<EntityFieldDisplayName Entity="Entities" EntityField="AllowDeleteAPI" />} value={entity?.AllowDeleteAPI} />
        <Field label={<EntityFieldDisplayName Entity="Entities" EntityField="CustomResolverAPI" />} value={entity?.CustomResolverAPI} />
        <Field label={<EntityFieldDisplayName Entity="Entities" EntityField="AllowUserSearchAPI" />} value={entity?.AllowUserSearchAPI} />
        <Field label={<EntityFieldDisplayName Entity="Entities" EntityField="UserViewMaxRows" />} value={entity?.UserViewMaxRows} />
        <Field label={<EntityFieldDisplayName Entity="Entities" EntityField="spCreate" />} value={entity?.spCreate} />
        <Field label={<EntityFieldDisplayName Entity="Entities" EntityField="spUpdate" />} value={entity?.spUpdate} />
        <Field label={<EntityFieldDisplayName Entity="Entities" EntityField="spDelete" />} value={entity?.spDelete} />
        <Field label={<EntityFieldDisplayName Entity="Entities" EntityField="spCreateGenerated" />} value={entity?.spCreateGenerated} />
        <Field label={<EntityFieldDisplayName Entity="Entities" EntityField="spUpdateGenerated" />} value={entity?.spUpdateGenerated} />
        <Field label={<EntityFieldDisplayName Entity="Entities" EntityField="spDeleteGenerated" />} value={entity?.spDeleteGenerated} />
        <Field label={<EntityFieldDisplayName Entity="Entities" EntityField="UserFormGenerated" />} value={entity?.UserFormGenerated} />
        <Field label={<EntityFieldDisplayName Entity="Entities" EntityField="UserFormClassName" />} value={entity?.UserFormClassName} />
        <Field label={<EntityFieldDisplayName Entity="Entities" EntityField="UserFormImportPath" />} value={entity?.UserFormImportPath} />
        <Field label={<EntityFieldDisplayName Entity="Entities" EntityField="EntityObjectSubclassName" />} value={entity?.EntityObjectSubclassName} />
        <Field label={<EntityFieldDisplayName Entity="Entities" EntityField="EntityObjectSubclassImportPath" />} value={entity?.EntityObjectSubclassImportPath} />
        <Field label={<EntityFieldDisplayName Entity="Entities" EntityField="CreatedAt" />} value={entity?.CreatedAt &&
            DateTime.fromMillis(entity.CreatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Entities" EntityField="UpdatedAt" />} value={entity?.UpdatedAt &&
            DateTime.fromMillis(entity.UpdatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Entities" EntityField="CodeName" />} value={entity?.CodeName} />
        <Field label={<EntityFieldDisplayName Entity="Entities" EntityField="ClassName" />} value={entity?.ClassName} />
        <Field label={<EntityFieldDisplayName Entity="Entities" EntityField="BaseTableCodeName" />} value={entity?.BaseTableCodeName} />
        <Field label={<EntityFieldDisplayName Entity="Entities" EntityField="ParentEntity" />} value={entity?.ParentEntity} />
        <Field label={<EntityFieldDisplayName Entity="Entities" EntityField="ParentBaseTable" />} value={entity?.ParentBaseTable} />
        <Field label={<EntityFieldDisplayName Entity="Entities" EntityField="ParentBaseView" />} value={entity?.ParentBaseView} />
      </div>
    );
  };
