import { useSingleContactEmailQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { ContactEmail } from '../api';
  
  export const ContactEmailPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Contact Emails"
        queryHook={useSingleContactEmailQuery}
        Details={<ContactEmailDetails />}
      />
    );
  };

  export const ContactEmailDetails = () => {
    const [contactemail] = useSingleEntity<ContactEmail>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Contact Emails" EntityField="ID" />} value={contactemail?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Contact Emails" EntityField="ContactID" />} value={<EntityLink Entity="Contact Emails" EntityField="ContactID">{contactemail?.ContactID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Contact Emails" EntityField="Email" />} value={<Email>{contactemail?.Email}</Email>} />
        <Field label={<EntityFieldDisplayName Entity="Contact Emails" EntityField="AccountID" />} value={<EntityLink Entity="Contact Emails" EntityField="AccountID">{contactemail?.AccountID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Contact Emails" EntityField="IsActive" />} value={contactemail?.IsActive} />
        <Field label={<EntityFieldDisplayName Entity="Contact Emails" EntityField="EmailDomain" />} value={contactemail?.EmailDomain} />
        <Field label={<EntityFieldDisplayName Entity="Contact Emails" EntityField="Contact" />} value={contactemail?.Contact} />
        <Field label={<EntityFieldDisplayName Entity="Contact Emails" EntityField="Account" />} value={contactemail?.Account} />
      </div>
    );
  };
