import { useSingleTaskQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { Task } from '../api';
  
  export const TaskPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Tasks"
        queryHook={useSingleTaskQuery}
        Details={<TaskDetails />}
      />
    );
  };

  export const TaskDetails = () => {
    const [task] = useSingleEntity<Task>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Tasks" EntityField="ID" />} value={task?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Tasks" EntityField="ExternalSystemRecordID" />} value={task?.ExternalSystemRecordID} />
        <Field label={<EntityFieldDisplayName Entity="Tasks" EntityField="Name" />} value={task?.Name} />
        <Field label={<EntityFieldDisplayName Entity="Tasks" EntityField="ParentID" />} value={<EntityLink Entity="Tasks" EntityField="ParentID">{task?.ParentID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Tasks" EntityField="AssignedEmployeeID" />} value={<EntityLink Entity="Tasks" EntityField="AssignedEmployeeID">{task?.AssignedEmployeeID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Tasks" EntityField="Role" />} value={task?.Role} />
        <Field label={<EntityFieldDisplayName Entity="Tasks" EntityField="Skills" />} value={task?.Skills} />
        <Field label={<EntityFieldDisplayName Entity="Tasks" EntityField="BudgetedEffort" />} value={task?.BudgetedEffort} />
        <Field label={<EntityFieldDisplayName Entity="Tasks" EntityField="ActualEffort" />} value={task?.ActualEffort} />
        <Field label={<EntityFieldDisplayName Entity="Tasks" EntityField="EstimateToComplete" />} value={task?.EstimateToComplete} />
        <Field label={<EntityFieldDisplayName Entity="Tasks" EntityField="StartDate" />} value={task?.StartDate &&
            DateTime.fromMillis(task.StartDate).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Tasks" EntityField="EndDate" />} value={task?.EndDate &&
            DateTime.fromMillis(task.EndDate).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Tasks" EntityField="IsCompleted" />} value={task?.IsCompleted} />
        <Field label={<EntityFieldDisplayName Entity="Tasks" EntityField="IsDeleted" />} value={task?.IsDeleted} />
        <Field label={<EntityFieldDisplayName Entity="Tasks" EntityField="CreatedAt" />} value={task?.CreatedAt &&
            DateTime.fromMillis(task.CreatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Tasks" EntityField="UpdatedAt" />} value={task?.UpdatedAt &&
            DateTime.fromMillis(task.UpdatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Tasks" EntityField="ProjectID" />} value={<EntityLink Entity="Tasks" EntityField="ProjectID">{task?.ProjectID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Tasks" EntityField="Section" />} value={task?.Section} />
        <Field label={<EntityFieldDisplayName Entity="Tasks" EntityField="Status" />} value={task?.Status} />
        <Field label={<EntityFieldDisplayName Entity="Tasks" EntityField="ForecastedEffort" />} value={task?.ForecastedEffort} />
        <Field label={<EntityFieldDisplayName Entity="Tasks" EntityField="ForecastedVariance" />} value={task?.ForecastedVariance} />
        <Field label={<EntityFieldDisplayName Entity="Tasks" EntityField="ActualVariance" />} value={task?.ActualVariance} />
        <Field label={<EntityFieldDisplayName Entity="Tasks" EntityField="CalculatedActual" />} value={task?.CalculatedActual} />
        <Field label={<EntityFieldDisplayName Entity="Tasks" EntityField="TaskTypeID" />} value={<EntityLink Entity="Tasks" EntityField="TaskTypeID">{task?.TaskTypeID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Tasks" EntityField="Parent" />} value={task?.Parent} />
        <Field label={<EntityFieldDisplayName Entity="Tasks" EntityField="AssignedEmployee" />} value={task?.AssignedEmployee} />
        <Field label={<EntityFieldDisplayName Entity="Tasks" EntityField="Project" />} value={task?.Project} />
      </div>
    );
  };
