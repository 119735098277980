import { Typography } from '@progress/kendo-react-common';
import { isValidElement, ReactNode } from 'react';
import styled from 'styled-components';

type FieldWithValueProps = {
  label: string;
  value?: unknown;
};

const isReactNode = (value: unknown): value is ReactNode =>
  typeof value === 'string' ||
  typeof value === 'number' ||
  typeof value === 'boolean' ||
  isValidElement(value);

const FieldTypography = styled(Typography.p)`
  margin-bottom: 0;
`;

const ValueTypography = styled(Typography.p)`
  font-weight: bold;
`;

export const FieldWithValue = ({ label, value }: FieldWithValueProps) => {
  const valueElement = isReactNode(value) ? value : <>{String(value ?? '')}</>;

  return (
    <>
      <FieldTypography>{label}</FieldTypography>
      <ValueTypography>{valueElement}</ValueTypography>
    </>
  );
};
