import { useSingleUserQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { User } from '../api';
  
  export const UserPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Users"
        queryHook={useSingleUserQuery}
        Details={<UserDetails />}
      />
    );
  };

  export const UserDetails = () => {
    const [user] = useSingleEntity<User>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Users" EntityField="ID" />} value={user?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Users" EntityField="Name" />} value={user?.Name} />
        <Field label={<EntityFieldDisplayName Entity="Users" EntityField="Type" />} value={user?.Type} />
        <Field label={<EntityFieldDisplayName Entity="Users" EntityField="EmployeeID" />} value={user?.EmployeeID} />
        <Field label={<EntityFieldDisplayName Entity="Users" EntityField="IsActive" />} value={user?.IsActive} />
        <Field label={<EntityFieldDisplayName Entity="Users" EntityField="CreatedAt" />} value={user?.CreatedAt &&
            DateTime.fromMillis(user.CreatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Users" EntityField="UpdatedAt" />} value={user?.UpdatedAt &&
            DateTime.fromMillis(user.UpdatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Users" EntityField="FirstLast" />} value={user?.FirstLast} />
        <Field label={<EntityFieldDisplayName Entity="Users" EntityField="Email" />} value={<Email>{user?.Email}</Email>} />
        <Field label={<EntityFieldDisplayName Entity="Users" EntityField="Supervisor" />} value={user?.Supervisor} />
        <Field label={<EntityFieldDisplayName Entity="Users" EntityField="SupervisorEmail" />} value={<Email>{user?.SupervisorEmail}</Email>} />
      </div>
    );
  };
