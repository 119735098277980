import { CompositeFilterDescriptor, SortDescriptor, State } from '@progress/kendo-data-query';
import { Icon } from '@progress/kendo-react-common';
import { GridColumnReorderEvent, GridColumnResizeEvent } from '@progress/kendo-react-grid';
import { InputPrefix, TextBox } from '@progress/kendo-react-inputs';
import { StackLayout } from '@progress/kendo-react-layout';
import { isEqual, keyBy } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
  ColumnSettings,
  EntityTable,
  Expander,
  TableWithHeader,
  ViewEditor,
  ViewFormFields,
} from '../components';
import { ViewDropDown } from '../components/organisms/ViewDropdown';
import { Entity } from '../context/EntitiesProvider';
import { useDebounce } from '../hooks/useDebounce';
import { useView, ViewGridState } from '../hooks/useView';
import { AUTOSAVE_DEBOUNCE_MS } from '../system/config';
import { emptyFilter, ifDifferent } from '../system/util';
import { useViewEditor } from './useViewEditor';

type EntityListProps = {
  entity: Entity;
  ViewID?: number;
  ExtraFilter?: string;
  UserSearchString?: string;
};

const initialSort: Array<SortDescriptor> = [{ field: 'ID', dir: 'asc' }];

export const EntityList = ({ entity, ViewID }: EntityListProps) => {
  const { AllowUserSearchAPI, EntityFields } = entity;
  const { toggleSettings, settingsExpanded, saveView, deleteView, hideSettings } = useViewEditor();
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [dataState, setDataState] = useState<State>({ sort: initialSort, filter: emptyFilter });
  const [columnSettings, setColumnSettings] = useState<Array<ColumnSettings>>(
    EntityFields.map(({ ID, Name, DisplayName, DefaultInView }) => ({
      ID,
      Name,
      DisplayName,
      hidden: !DefaultInView,
    }))
  );

  const onViewGridState = useCallback((view: ViewGridState) => {
    view?.dataState && setDataState(ifDifferent(view.dataState));
    view?.columnSettings &&
      setColumnSettings((prev) => {
        const byId = keyBy(view.columnSettings, 'ID');
        const newColumnSettings = prev.map((col) =>
          byId[col.ID]
            ? {
                ...col,
                ...byId[col.ID],
              }
            : col
        );
        return isEqual(prev, newColumnSettings) ? prev : newColumnSettings;
      });
  }, []);

  const { UserView, usingView } = useView({ ViewID, onViewGridState });

  useEffect(() => {
    !usingView &&
      setColumnSettings(
        ifDifferent<ColumnSettings[]>(
          EntityFields.map(({ ID, Name, DisplayName, DefaultInView }) => ({
            ID,
            Name,
            DisplayName,
            hidden: !DefaultInView,
          }))
        )
      );
  }, [EntityFields, usingView]);

  const navigateToView = useCallback(
    (ID?: number) => {
      const currentViewID = searchParams.get('ViewID');
      if (currentViewID === ID?.toString()) {
        return;
      }

      if (ID === undefined) {
        searchParams.delete('ViewID');
      } else {
        searchParams.set('ViewID', ID.toString());
      }

      const to = { pathname: location.pathname, search: searchParams.toString() };
      navigate(to, { replace: true });
    },
    [location.pathname, navigate, searchParams]
  );

  const onSaveView = useCallback(
    async (values: ViewFormFields, filter: CompositeFilterDescriptor) => {
      hideSettings();
      const newViewID = await saveView(values);
      navigateToView(newViewID);

      setDataState((prev) => {
        const newState = { ...prev, filter };
        return isEqual(prev, newState) ? prev : newState;
      });
    },
    [hideSettings, navigateToView, saveView]
  );

  const onDeleteView = useCallback(
    async (ID: number) => {
      await deleteView(ID);
      navigateToView();
    },
    [deleteView, navigateToView]
  );

  const updatedUserView = useCallback(
    ({
      FilterState,
      GridState,
    }: {
      FilterState?: string;
      GridState?: string;
    }): ViewFormFields | undefined =>
      UserView
        ? {
            ViewID: UserView.ID,
            EntityID: UserView.EntityID,
            Name: UserView.Name,
            Description: UserView.Description ?? '',
            FilterState: FilterState ?? JSON.stringify(dataState.filter ?? emptyFilter),
            GridState: GridState ?? JSON.stringify({ dataState, columnSettings }),
          }
        : undefined,
    [dataState, columnSettings, UserView]
  );

  const [localView, setLocalView] = useState<ViewFormFields | undefined>(updatedUserView({}));
  const debouncedLocalView = useDebounce(localView, AUTOSAVE_DEBOUNCE_MS);
  useEffect(() => {
    debouncedLocalView && saveView(debouncedLocalView);
  }, [debouncedLocalView, saveView]);

  useEffect(() => {
    setLocalView(
      ifDifferent<ViewFormFields | undefined>(
        updatedUserView({
          FilterState: JSON.stringify(dataState.filter ?? emptyFilter),
          GridState: JSON.stringify({ dataState, columnSettings }),
        })
      )
    );
  }, [columnSettings, dataState, updatedUserView]);

  const onDataStateChange = useCallback(({ dataState: newState }: { dataState: State }) => {
    setDataState(ifDifferent(newState));
  }, []);

  const onColumnResize = useCallback(
    ({ columns, end }: GridColumnResizeEvent) => {
      if (end) {
        const updatedColumns = keyBy(columns, 'id');
        const newColumnSettings: Array<ColumnSettings> = columnSettings.map((c) =>
          updatedColumns[c.ID]
            ? {
                ...c,
                width: Math.floor(Number(updatedColumns[c.ID].width)),
              }
            : c
        );
        setColumnSettings(ifDifferent(newColumnSettings));
      }
    },
    [columnSettings]
  );

  const onColumnReorder = useCallback(
    ({ columns }: GridColumnReorderEvent) => {
      const updatedColumns = keyBy(columns, 'id');
      const newColumnSettings: Array<ColumnSettings> = columnSettings.map((c) =>
        updatedColumns[c.ID] ? { ...c, orderIndex: updatedColumns[c.ID].orderIndex } : c
      );
      setColumnSettings(ifDifferent(newColumnSettings));
    },
    [columnSettings]
  );

  const [search, setSearch] = useState<string>('');

  return (
    <TableWithHeader
      title={entity.Name}
      titleToolbar={
        <div>
          <ViewDropDown
            EntityID={entity.ID}
            ViewID={ViewID}
            onChange={(id) => navigateToView(id)}
          />
        </div>
      }
      header={
        <StackLayout orientation="vertical" gap={8}>
          {AllowUserSearchAPI && (
            <TextBox
              placeholder={`Search ${entity.Name}`}
              onChange={(e) => setSearch(String(e.target.value))}
              value={search}
              prefix={() => (
                <InputPrefix>
                  <Icon name="search" />
                </InputPrefix>
              )}
            />
          )}
          <Expander
            title={UserView?.Name ?? 'View Settings'}
            expanded={settingsExpanded}
            onToggle={toggleSettings}
          >
            <ViewEditor
              {...{
                ViewID,
                Name: UserView?.Name ?? '',
                Description: UserView?.Description ?? '',
                columnSettings,
                dataState,
                entity,
                onSaveView,
                onDeleteView,
              }}
            />
          </Expander>
        </StackLayout>
      }
      table={
        <EntityTable
          {...{ entity, dataState, columnSettings, search, ViewID }}
          onColumnReorder={onColumnReorder}
          onColumnResize={onColumnResize}
          onDataStateChange={onDataStateChange}
          onViewGridState={onViewGridState}
        />
      }
    />
  );
};
