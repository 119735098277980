import { useSingle_990LatestQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { _990Latest } from '../api';
  
  export const _990LatestPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="990 Latest "
        queryHook={useSingle_990LatestQuery}
        Details={<_990LatestDetails />}
      />
    );
  };

  export const _990LatestDetails = () => {
    const [_990latest] = useSingleEntity<_990Latest>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="ID" />} value={_990latest?.ID} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="EIN" />} value={_990latest?.EIN} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="Name" />} value={_990latest?.Name} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="ICO" />} value={_990latest?.ICO} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="Street" />} value={_990latest?.Street} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="City" />} value={_990latest?.City} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="State" />} value={_990latest?.State} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="Zip" />} value={_990latest?.Zip} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="Group" />} value={_990latest?.Group} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="Subsection" />} value={_990latest?.Subsection} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="Affiliation" />} value={_990latest?.Affiliation} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="Classification" />} value={_990latest?.Classification} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="Ruling" />} value={_990latest?.Ruling} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="Deductibility" />} value={_990latest?.Deductibility} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="Foundation" />} value={_990latest?.Foundation} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="Activity" />} value={_990latest?.Activity} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="Organization" />} value={_990latest?.Organization} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="Status" />} value={_990latest?.Status} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="TaxPeriod" />} value={_990latest?.TaxPeriod} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="TaxYear" />} value={_990latest?.TaxYear} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="TaxMonth" />} value={_990latest?.TaxMonth} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="AssetCode" />} value={_990latest?.AssetCode} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="IncomeCode" />} value={_990latest?.IncomeCode} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="FilingRequirementCode" />} value={_990latest?.FilingRequirementCode} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="SortName" />} value={_990latest?.SortName} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="PoliticalActivities" />} value={_990latest?.PoliticalActivities} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="LobbyingActivities" />} value={_990latest?.LobbyingActivities} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="FundraisingActivities" />} value={_990latest?.FundraisingActivities} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="IsHospital" />} value={_990latest?.IsHospital} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="GrantsToOrgs" />} value={_990latest?.GrantsToOrgs} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="GrantsToIndividuals" />} value={_990latest?.GrantsToIndividuals} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="HasInvestmentIncome" />} value={_990latest?.HasInvestmentIncome} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="TotalInvestmentIncome" />} value={_990latest?.TotalInvestmentIncome !== undefined
              ? formatCurrency(Math.floor(_990latest.TotalInvestmentIncome), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="NumberEmployees" />} value={_990latest?.NumberEmployees} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="HasForeignBankAcct" />} value={_990latest?.HasForeignBankAcct} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="TotalGrossReceipts" />} value={_990latest?.TotalGrossReceipts !== undefined
              ? formatCurrency(Math.floor(_990latest.TotalGrossReceipts), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="TotalMemberIncome" />} value={_990latest?.TotalMemberIncome !== undefined
              ? formatCurrency(Math.floor(_990latest.TotalMemberIncome), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="TotalProgramServiceRevenue" />} value={_990latest?.TotalProgramServiceRevenue !== undefined
              ? formatCurrency(Math.floor(_990latest.TotalProgramServiceRevenue), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="ProgramServiceRevenueCodeA" />} value={_990latest?.ProgramServiceRevenueCodeA} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="ProgramServiceRevenueA" />} value={_990latest?.ProgramServiceRevenueA !== undefined
              ? formatCurrency(Math.floor(_990latest.ProgramServiceRevenueA), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="ProgramServiceRevenueCodeB" />} value={_990latest?.ProgramServiceRevenueCodeB} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="ProgramServiceRevenueB" />} value={_990latest?.ProgramServiceRevenueB !== undefined
              ? formatCurrency(Math.floor(_990latest.ProgramServiceRevenueB), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="ProgramServiceRevenueCodeC" />} value={_990latest?.ProgramServiceRevenueCodeC} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="ProgramServiceRevenueC" />} value={_990latest?.ProgramServiceRevenueC !== undefined
              ? formatCurrency(Math.floor(_990latest.ProgramServiceRevenueC), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="ProgramServiceRevenueCodeD" />} value={_990latest?.ProgramServiceRevenueCodeD} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="ProgramServiceRevenueD" />} value={_990latest?.ProgramServiceRevenueD !== undefined
              ? formatCurrency(Math.floor(_990latest.ProgramServiceRevenueD), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="ProgramServiceRevenueCodeE" />} value={_990latest?.ProgramServiceRevenueCodeE} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="ProgramServiceRevenueE" />} value={_990latest?.ProgramServiceRevenueE !== undefined
              ? formatCurrency(Math.floor(_990latest.ProgramServiceRevenueE), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="GrossRentsFromRealEstate" />} value={_990latest?.GrossRentsFromRealEstate !== undefined
              ? formatCurrency(Math.floor(_990latest.GrossRentsFromRealEstate), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="RentalExpenseRealEstate" />} value={_990latest?.RentalExpenseRealEstate !== undefined
              ? formatCurrency(Math.floor(_990latest.RentalExpenseRealEstate), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="NetRentRealEstate" />} value={_990latest?.NetRentRealEstate !== undefined
              ? formatCurrency(Math.floor(_990latest.NetRentRealEstate), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="GrossSalesSecurities" />} value={_990latest?.GrossSalesSecurities !== undefined
              ? formatCurrency(Math.floor(_990latest.GrossSalesSecurities), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="CostBasisSecurities" />} value={_990latest?.CostBasisSecurities !== undefined
              ? formatCurrency(Math.floor(_990latest.CostBasisSecurities), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="NetGainSecurities" />} value={_990latest?.NetGainSecurities !== undefined
              ? formatCurrency(Math.floor(_990latest.NetGainSecurities), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="GrossFundraisingReceipts" />} value={_990latest?.GrossFundraisingReceipts !== undefined
              ? formatCurrency(Math.floor(_990latest.GrossFundraisingReceipts), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="FundraisingExpenses" />} value={_990latest?.FundraisingExpenses !== undefined
              ? formatCurrency(Math.floor(_990latest.FundraisingExpenses), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="FundraisingNetIncome" />} value={_990latest?.FundraisingNetIncome !== undefined
              ? formatCurrency(Math.floor(_990latest.FundraisingNetIncome), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="TotalRevenue" />} value={_990latest?.TotalRevenue !== undefined
              ? formatCurrency(Math.floor(_990latest.TotalRevenue), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="OfficerCompensation" />} value={_990latest?.OfficerCompensation !== undefined
              ? formatCurrency(Math.floor(_990latest.OfficerCompensation), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="DisqualifiedPersonCompensation" />} value={_990latest?.DisqualifiedPersonCompensation !== undefined
              ? formatCurrency(Math.floor(_990latest.DisqualifiedPersonCompensation), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="OtherSalaries" />} value={_990latest?.OtherSalaries !== undefined
              ? formatCurrency(Math.floor(_990latest.OtherSalaries), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="PensionContributions" />} value={_990latest?.PensionContributions !== undefined
              ? formatCurrency(Math.floor(_990latest.PensionContributions), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="OtherEmployeeBenefits" />} value={_990latest?.OtherEmployeeBenefits !== undefined
              ? formatCurrency(Math.floor(_990latest.OtherEmployeeBenefits), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="PayrollTaxes" />} value={_990latest?.PayrollTaxes !== undefined
              ? formatCurrency(Math.floor(_990latest.PayrollTaxes), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="ManagementFees" />} value={_990latest?.ManagementFees !== undefined
              ? formatCurrency(Math.floor(_990latest.ManagementFees), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="LegalFees" />} value={_990latest?.LegalFees !== undefined
              ? formatCurrency(Math.floor(_990latest.LegalFees), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="AccountingFees" />} value={_990latest?.AccountingFees !== undefined
              ? formatCurrency(Math.floor(_990latest.AccountingFees), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="LobbyingFees" />} value={_990latest?.LobbyingFees !== undefined
              ? formatCurrency(Math.floor(_990latest.LobbyingFees), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="ProfessionalFundraisingFees" />} value={_990latest?.ProfessionalFundraisingFees !== undefined
              ? formatCurrency(Math.floor(_990latest.ProfessionalFundraisingFees), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="InvestmentManagementFees" />} value={_990latest?.InvestmentManagementFees !== undefined
              ? formatCurrency(Math.floor(_990latest.InvestmentManagementFees), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="OtherFees" />} value={_990latest?.OtherFees !== undefined
              ? formatCurrency(Math.floor(_990latest.OtherFees), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="AdvertisingExpense" />} value={_990latest?.AdvertisingExpense !== undefined
              ? formatCurrency(Math.floor(_990latest.AdvertisingExpense), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="OfficeExpense" />} value={_990latest?.OfficeExpense !== undefined
              ? formatCurrency(Math.floor(_990latest.OfficeExpense), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="InformationTechnologyExpense" />} value={_990latest?.InformationTechnologyExpense !== undefined
              ? formatCurrency(Math.floor(_990latest.InformationTechnologyExpense), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="RoyaltyExpense" />} value={_990latest?.RoyaltyExpense !== undefined
              ? formatCurrency(Math.floor(_990latest.RoyaltyExpense), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="OccupancyExpense" />} value={_990latest?.OccupancyExpense !== undefined
              ? formatCurrency(Math.floor(_990latest.OccupancyExpense), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="TravelExpense" />} value={_990latest?.TravelExpense !== undefined
              ? formatCurrency(Math.floor(_990latest.TravelExpense), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="TravelExpensePublicOfficials" />} value={_990latest?.TravelExpensePublicOfficials !== undefined
              ? formatCurrency(Math.floor(_990latest.TravelExpensePublicOfficials), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="ConferenceExpense" />} value={_990latest?.ConferenceExpense !== undefined
              ? formatCurrency(Math.floor(_990latest.ConferenceExpense), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="InterestExpense" />} value={_990latest?.InterestExpense !== undefined
              ? formatCurrency(Math.floor(_990latest.InterestExpense), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="AffiliatePaymentExpense" />} value={_990latest?.AffiliatePaymentExpense !== undefined
              ? formatCurrency(Math.floor(_990latest.AffiliatePaymentExpense), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="DepreciationExpense" />} value={_990latest?.DepreciationExpense !== undefined
              ? formatCurrency(Math.floor(_990latest.DepreciationExpense), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="InsuranceExpense" />} value={_990latest?.InsuranceExpense !== undefined
              ? formatCurrency(Math.floor(_990latest.InsuranceExpense), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="Cash" />} value={_990latest?.Cash !== undefined
              ? formatCurrency(Math.floor(_990latest.Cash), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="Savings" />} value={_990latest?.Savings !== undefined
              ? formatCurrency(Math.floor(_990latest.Savings), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="PledgesGrantsReceivable" />} value={_990latest?.PledgesGrantsReceivable !== undefined
              ? formatCurrency(Math.floor(_990latest.PledgesGrantsReceivable), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="AccountsReceivable" />} value={_990latest?.AccountsReceivable !== undefined
              ? formatCurrency(Math.floor(_990latest.AccountsReceivable), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="ReceivablesFromOfficers" />} value={_990latest?.ReceivablesFromOfficers !== undefined
              ? formatCurrency(Math.floor(_990latest.ReceivablesFromOfficers), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="ReceivablesFromDisqualifiedPersons" />} value={_990latest?.ReceivablesFromDisqualifiedPersons !== undefined
              ? formatCurrency(Math.floor(_990latest.ReceivablesFromDisqualifiedPersons), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="NotesReceivable" />} value={_990latest?.NotesReceivable !== undefined
              ? formatCurrency(Math.floor(_990latest.NotesReceivable), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="InventoryAssetValue" />} value={_990latest?.InventoryAssetValue !== undefined
              ? formatCurrency(Math.floor(_990latest.InventoryAssetValue), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="PrepaidExpenseAssetValue" />} value={_990latest?.PrepaidExpenseAssetValue !== undefined
              ? formatCurrency(Math.floor(_990latest.PrepaidExpenseAssetValue), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="RealEstateAssetValue" />} value={_990latest?.RealEstateAssetValue !== undefined
              ? formatCurrency(Math.floor(_990latest.RealEstateAssetValue), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="PublicSecuritiesAssetValue" />} value={_990latest?.PublicSecuritiesAssetValue !== undefined
              ? formatCurrency(Math.floor(_990latest.PublicSecuritiesAssetValue), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="OtherSecuritiesAssetValue" />} value={_990latest?.OtherSecuritiesAssetValue !== undefined
              ? formatCurrency(Math.floor(_990latest.OtherSecuritiesAssetValue), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="ProgramInvestmentsAssetValue" />} value={_990latest?.ProgramInvestmentsAssetValue !== undefined
              ? formatCurrency(Math.floor(_990latest.ProgramInvestmentsAssetValue), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="IntangibleAssetValue" />} value={_990latest?.IntangibleAssetValue !== undefined
              ? formatCurrency(Math.floor(_990latest.IntangibleAssetValue), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="OtherAssets" />} value={_990latest?.OtherAssets !== undefined
              ? formatCurrency(Math.floor(_990latest.OtherAssets), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="TotalAssets" />} value={_990latest?.TotalAssets !== undefined
              ? formatCurrency(Math.floor(_990latest.TotalAssets), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="AccountsPayable" />} value={_990latest?.AccountsPayable !== undefined
              ? formatCurrency(Math.floor(_990latest.AccountsPayable), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="GrantsPayable" />} value={_990latest?.GrantsPayable !== undefined
              ? formatCurrency(Math.floor(_990latest.GrantsPayable), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="DeferredRevenue" />} value={_990latest?.DeferredRevenue !== undefined
              ? formatCurrency(Math.floor(_990latest.DeferredRevenue), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="TaxExemptBondLiability" />} value={_990latest?.TaxExemptBondLiability !== undefined
              ? formatCurrency(Math.floor(_990latest.TaxExemptBondLiability), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="EscrowAccountLiability" />} value={_990latest?.EscrowAccountLiability !== undefined
              ? formatCurrency(Math.floor(_990latest.EscrowAccountLiability), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="PayablesToOfficers" />} value={_990latest?.PayablesToOfficers !== undefined
              ? formatCurrency(Math.floor(_990latest.PayablesToOfficers), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="MortgagesNotesPayable" />} value={_990latest?.MortgagesNotesPayable !== undefined
              ? formatCurrency(Math.floor(_990latest.MortgagesNotesPayable), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="UnsecuredNotesPayable" />} value={_990latest?.UnsecuredNotesPayable !== undefined
              ? formatCurrency(Math.floor(_990latest.UnsecuredNotesPayable), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="OtherLiabilities" />} value={_990latest?.OtherLiabilities !== undefined
              ? formatCurrency(Math.floor(_990latest.OtherLiabilities), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="TotalLiabilities" />} value={_990latest?.TotalLiabilities !== undefined
              ? formatCurrency(Math.floor(_990latest.TotalLiabilities), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="UnrestrictedNetAssets" />} value={_990latest?.UnrestrictedNetAssets !== undefined
              ? formatCurrency(Math.floor(_990latest.UnrestrictedNetAssets), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="TemporarilyRestrictedNetAssets" />} value={_990latest?.TemporarilyRestrictedNetAssets !== undefined
              ? formatCurrency(Math.floor(_990latest.TemporarilyRestrictedNetAssets), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="PermanentlyRestrictedNetAssets" />} value={_990latest?.PermanentlyRestrictedNetAssets !== undefined
              ? formatCurrency(Math.floor(_990latest.PermanentlyRestrictedNetAssets), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="CapitalStockPrincipal" />} value={_990latest?.CapitalStockPrincipal !== undefined
              ? formatCurrency(Math.floor(_990latest.CapitalStockPrincipal), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="PaidInCapitalSurplus" />} value={_990latest?.PaidInCapitalSurplus !== undefined
              ? formatCurrency(Math.floor(_990latest.PaidInCapitalSurplus), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="RetainedEarnings" />} value={_990latest?.RetainedEarnings !== undefined
              ? formatCurrency(Math.floor(_990latest.RetainedEarnings), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="TotalNetAssets" />} value={_990latest?.TotalNetAssets !== undefined
              ? formatCurrency(Math.floor(_990latest.TotalNetAssets), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="TotalNetAssetsPlusLiabilities" />} value={_990latest?.TotalNetAssetsPlusLiabilities !== undefined
              ? formatCurrency(Math.floor(_990latest.TotalNetAssetsPlusLiabilities), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="ProgramServiceRevenueNameA" />} value={_990latest?.ProgramServiceRevenueNameA} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="ProgramServiceRevenueNameB" />} value={_990latest?.ProgramServiceRevenueNameB} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="ProgramServiceRevenueNameC" />} value={_990latest?.ProgramServiceRevenueNameC} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="ProgramServiceRevenueNameD" />} value={_990latest?.ProgramServiceRevenueNameD} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="ProgramServiceRevenueNameE" />} value={_990latest?.ProgramServiceRevenueNameE} />
        <Field label={<EntityFieldDisplayName Entity="990 Latest " EntityField="AccountID" />} value={_990latest?.AccountID} />
      </div>
    );
  };
