import { Dictionary, find, kebabCase, keyBy, NumericDictionary } from 'lodash';
import { createContext, ReactNode, useContext } from 'react';
import { EntitiesProviderQuery, useEntitiesProviderQuery } from '../api';
import { useAuth } from '../auth';
import { FullScreenSpinner } from '../components';
import { emptyArray, emptyObject, ensureArray } from '../system/util';

export type Entity = EntitiesProviderQuery['AllEntities'][number];

type Entities = {
  AllApplications: EntitiesProviderQuery['AllApplications'];
  AllEntitiesByID: NumericDictionary<EntitiesProviderQuery['AllEntities'][number]>;
  AllEntitiesByName: Dictionary<EntitiesProviderQuery['AllEntities'][number]>;
  AllEntitiesBySlug: Dictionary<EntitiesProviderQuery['AllEntities'][number]>;
  getNameAttribute: <T extends Record<string, unknown>>(
    entity: Pick<Entity, 'EntityFields'>
  ) => keyof T;
};

const emptyEntities: Entities = {
  AllApplications: emptyArray,
  AllEntitiesByID: emptyObject,
  AllEntitiesByName: emptyObject,
  AllEntitiesBySlug: emptyObject,
  getNameAttribute: () => '',
};

const EntitiesContext = createContext(emptyEntities);

export const useEntities = () => useContext(EntitiesContext);

export const EntitiesProvider = ({ children }: { children: ReactNode }) => {
  const { isAuthenticated } = useAuth();
  const { data, loading } = useEntitiesProviderQuery({ skip: !isAuthenticated });

  const entities = {
    AllApplications: ensureArray(data?.AllApplications),
    AllEntitiesByID: keyBy(data?.AllEntities, 'ID'),
    AllEntitiesByName: keyBy(data?.AllEntities, 'Name'),
    AllEntitiesBySlug: keyBy(data?.AllEntities, ({ Name }) => kebabCase(Name)),
    getNameAttribute: <T extends Record<string, unknown>>({
      EntityFields,
    }: Pick<Entity, 'EntityFields'>) =>
      (find(EntityFields, 'IsNameField')?.Name ?? 'Name') as keyof T,
  };

  return loading ? (
    <FullScreenSpinner />
  ) : (
    <EntitiesContext.Provider value={entities}>{children}</EntitiesContext.Provider>
  );
};
