import { useSingleRowLevelSecurityFilterQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { RowLevelSecurityFilter } from '../api';
  
  export const RowLevelSecurityFilterPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Row Level Security Filters"
        queryHook={useSingleRowLevelSecurityFilterQuery}
        Details={<RowLevelSecurityFilterDetails />}
      />
    );
  };

  export const RowLevelSecurityFilterDetails = () => {
    const [rowlevelsecurityfilter] = useSingleEntity<RowLevelSecurityFilter>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Row Level Security Filters" EntityField="ID" />} value={rowlevelsecurityfilter?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Row Level Security Filters" EntityField="Name" />} value={rowlevelsecurityfilter?.Name} />
        <Field label={<EntityFieldDisplayName Entity="Row Level Security Filters" EntityField="Description" />} value={<BodyText>{rowlevelsecurityfilter?.Description}</BodyText>} />
        <Field label={<EntityFieldDisplayName Entity="Row Level Security Filters" EntityField="FilterText" />} value={<BodyText>{rowlevelsecurityfilter?.FilterText}</BodyText>} />
        <Field label={<EntityFieldDisplayName Entity="Row Level Security Filters" EntityField="CreatedAt" />} value={rowlevelsecurityfilter?.CreatedAt &&
            DateTime.fromMillis(rowlevelsecurityfilter.CreatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Row Level Security Filters" EntityField="UpdatedAt" />} value={rowlevelsecurityfilter?.UpdatedAt &&
            DateTime.fromMillis(rowlevelsecurityfilter.UpdatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
      </div>
    );
  };
