import { useSingleAccountTypeQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { AccountType } from '../api';
  
  export const AccountTypePage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Account Types"
        queryHook={useSingleAccountTypeQuery}
        Details={<AccountTypeDetails />}
      />
    );
  };

  export const AccountTypeDetails = () => {
    const [accounttype] = useSingleEntity<AccountType>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Account Types" EntityField="ID" />} value={accounttype?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Account Types" EntityField="Name" />} value={accounttype?.Name} />
        <Field label={<EntityFieldDisplayName Entity="Account Types" EntityField="Description" />} value={accounttype?.Description} />
        <Field label={<EntityFieldDisplayName Entity="Account Types" EntityField="CreatedAt" />} value={accounttype?.CreatedAt &&
            DateTime.fromMillis(accounttype.CreatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Account Types" EntityField="UpdatedAt" />} value={accounttype?.UpdatedAt &&
            DateTime.fromMillis(accounttype.UpdatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
      </div>
    );
  };
