import { useSingleContactTopicQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { ContactTopic } from '../api';
  
  export const ContactTopicPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Contact Topics"
        queryHook={useSingleContactTopicQuery}
        Details={<ContactTopicDetails />}
      />
    );
  };

  export const ContactTopicDetails = () => {
    const [contacttopic] = useSingleEntity<ContactTopic>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Contact Topics" EntityField="ID" />} value={contacttopic?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Contact Topics" EntityField="ContactID" />} value={<EntityLink Entity="Contact Topics" EntityField="ContactID">{contacttopic?.ContactID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Contact Topics" EntityField="Topic" />} value={contacttopic?.Topic} />
        <Field label={<EntityFieldDisplayName Entity="Contact Topics" EntityField="TotalCount" />} value={contacttopic?.TotalCount} />
        <Field label={<EntityFieldDisplayName Entity="Contact Topics" EntityField="EarliestDate" />} value={contacttopic?.EarliestDate &&
            DateTime.fromMillis(contacttopic.EarliestDate).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Contact Topics" EntityField="LatestDate" />} value={contacttopic?.LatestDate &&
            DateTime.fromMillis(contacttopic.LatestDate).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Contact Topics" EntityField="Contact" />} value={contacttopic?.Contact} />
        <Field label={<EntityFieldDisplayName Entity="Contact Topics" EntityField="Account" />} value={contacttopic?.Account} />
        <Field label={<EntityFieldDisplayName Entity="Contact Topics" EntityField="Domain" />} value={contacttopic?.Domain} />
        <Field label={<EntityFieldDisplayName Entity="Contact Topics" EntityField="Website" />} value={contacttopic?.Website} />
      </div>
    );
  };
