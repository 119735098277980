import { useSingleAIModelTypeQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { AIModelType } from '../api';
  
  export const AIModelTypePage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="AI Model Types"
        queryHook={useSingleAIModelTypeQuery}
        Details={<AIModelTypeDetails />}
      />
    );
  };

  export const AIModelTypeDetails = () => {
    const [aimodeltype] = useSingleEntity<AIModelType>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="AI Model Types" EntityField="ID" />} value={aimodeltype?.ID} />
        <Field label={<EntityFieldDisplayName Entity="AI Model Types" EntityField="Name" />} value={aimodeltype?.Name} />
        <Field label={<EntityFieldDisplayName Entity="AI Model Types" EntityField="Description" />} value={<BodyText>{aimodeltype?.Description}</BodyText>} />
      </div>
    );
  };
