export const GRAPHQL_URI = process.env.REACT_APP_GRAPHQL_URI || 'http://localhost:4000/graphql';
export const REDIRECT_URI = process.env.REACT_APP_REDIRECT_URI || 'http://localhost:3000/';
export const CLIENT_ID = process.env.REACT_APP_CLIENT_ID || '7e6e6ecf-66ff-4733-9c60-1e6def949897';
export const TENANT_ID = process.env.REACT_APP_TENANT_ID || 'ff10ade7-5d03-40a9-be28-cb7ab99670b1';
export const CLIENT_AUTHORITY =
  process.env.REACT_APP_CLIENT_AUTHORITY ||
  'https://login.microsoftonline.com/ff10ade7-5d03-40a9-be28-cb7ab99670b1';
export const NODE_ENV = process.env.NODE_ENV || 'development';
export const AUTOSAVE_DEBOUNCE_MS = Number(process.env.REACT_APP_AUTOSAVE_DEBOUNCE_MS) || 1200;
export const SEARCH_DEBOUNCE_MS = Number(process.env.REACT_APP_SEARCH_DEBOUNCE_MS) || 800;
export const MIN_SEARCH_LENGTH = Number(process.env.MIN_SEARCH_LENGTH) || 3;
