import { useSingleProjectBillingTypeQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { ProjectBillingType } from '../api';
  
  export const ProjectBillingTypePage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Project Billing Types"
        queryHook={useSingleProjectBillingTypeQuery}
        Details={<ProjectBillingTypeDetails />}
      />
    );
  };

  export const ProjectBillingTypeDetails = () => {
    const [projectbillingtype] = useSingleEntity<ProjectBillingType>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Project Billing Types" EntityField="ID" />} value={projectbillingtype?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Project Billing Types" EntityField="Name" />} value={projectbillingtype?.Name} />
        <Field label={<EntityFieldDisplayName Entity="Project Billing Types" EntityField="Code" />} value={projectbillingtype?.Code} />
        <Field label={<EntityFieldDisplayName Entity="Project Billing Types" EntityField="Description" />} value={projectbillingtype?.Description} />
        <Field label={<EntityFieldDisplayName Entity="Project Billing Types" EntityField="CreatedAt" />} value={projectbillingtype?.CreatedAt &&
            DateTime.fromMillis(projectbillingtype.CreatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Project Billing Types" EntityField="UpdatedAt" />} value={projectbillingtype?.UpdatedAt &&
            DateTime.fromMillis(projectbillingtype.UpdatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
      </div>
    );
  };
