import { useSingleQueueQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { Queue } from '../api';
  
  export const QueuePage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Queues"
        queryHook={useSingleQueueQuery}
        Details={<QueueDetails />}
      />
    );
  };

  export const QueueDetails = () => {
    const [queue] = useSingleEntity<Queue>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Queues" EntityField="ID" />} value={queue?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Queues" EntityField="Name" />} value={queue?.Name} />
        <Field label={<EntityFieldDisplayName Entity="Queues" EntityField="Description" />} value={<BodyText>{queue?.Description}</BodyText>} />
        <Field label={<EntityFieldDisplayName Entity="Queues" EntityField="QueueTypeID" />} value={<EntityLink Entity="Queues" EntityField="QueueTypeID">{queue?.QueueTypeID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Queues" EntityField="IsActive" />} value={queue?.IsActive} />
        <Field label={<EntityFieldDisplayName Entity="Queues" EntityField="QueueType" />} value={queue?.QueueType} />
      </div>
    );
  };
