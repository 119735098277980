import { useSingleEmployeeQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { Employee } from '../api';
  
  export const EmployeePage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Employees"
        queryHook={useSingleEmployeeQuery}
        Details={<EmployeeDetails />}
      />
    );
  };

  export const EmployeeDetails = () => {
    const [employee] = useSingleEntity<Employee>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Employees" EntityField="ID" />} value={employee?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Employees" EntityField="BCMID" />} value={employee?.BCMID} />
        <Field label={<EntityFieldDisplayName Entity="Employees" EntityField="FirstName" />} value={employee?.FirstName} />
        <Field label={<EntityFieldDisplayName Entity="Employees" EntityField="LastName" />} value={employee?.LastName} />
        <Field label={<EntityFieldDisplayName Entity="Employees" EntityField="Title" />} value={employee?.Title} />
        <Field label={<EntityFieldDisplayName Entity="Employees" EntityField="Email" />} value={<Email>{employee?.Email}</Email>} />
        <Field label={<EntityFieldDisplayName Entity="Employees" EntityField="Phone" />} value={employee?.Phone} />
        <Field label={<EntityFieldDisplayName Entity="Employees" EntityField="Active" />} value={employee?.Active} />
        <Field label={<EntityFieldDisplayName Entity="Employees" EntityField="CompanyID" />} value={<EntityLink Entity="Employees" EntityField="CompanyID">{employee?.CompanyID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Employees" EntityField="SupervisorID" />} value={<EntityLink Entity="Employees" EntityField="SupervisorID">{employee?.SupervisorID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Employees" EntityField="CreatedAt" />} value={employee?.CreatedAt &&
            DateTime.fromMillis(employee.CreatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Employees" EntityField="UpdatedAt" />} value={employee?.UpdatedAt &&
            DateTime.fromMillis(employee.UpdatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Employees" EntityField="FirstLast" />} value={employee?.FirstLast} />
        <Field label={<EntityFieldDisplayName Entity="Employees" EntityField="Supervisor" />} value={employee?.Supervisor} />
        <Field label={<EntityFieldDisplayName Entity="Employees" EntityField="SupervisorFirstName" />} value={employee?.SupervisorFirstName} />
        <Field label={<EntityFieldDisplayName Entity="Employees" EntityField="SupervisorLastName" />} value={employee?.SupervisorLastName} />
        <Field label={<EntityFieldDisplayName Entity="Employees" EntityField="SupervisorEmail" />} value={<Email>{employee?.SupervisorEmail}</Email>} />
      </div>
    );
  };
