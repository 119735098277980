import { useSingleApplicationPermissionQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { ApplicationPermission } from '../api';
  
  export const ApplicationPermissionPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Application Permissions"
        queryHook={useSingleApplicationPermissionQuery}
        Details={<ApplicationPermissionDetails />}
      />
    );
  };

  export const ApplicationPermissionDetails = () => {
    const [applicationpermission] = useSingleEntity<ApplicationPermission>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Application Permissions" EntityField="ID" />} value={applicationpermission?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Application Permissions" EntityField="ApplicationID" />} value={<EntityLink Entity="Application Permissions" EntityField="ApplicationID">{applicationpermission?.ApplicationID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Application Permissions" EntityField="RoleID" />} value={<EntityLink Entity="Application Permissions" EntityField="RoleID">{applicationpermission?.RoleID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Application Permissions" EntityField="CreatedAt" />} value={applicationpermission?.CreatedAt &&
            DateTime.fromMillis(applicationpermission.CreatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Application Permissions" EntityField="UpdatedAt" />} value={applicationpermission?.UpdatedAt &&
            DateTime.fromMillis(applicationpermission.UpdatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Application Permissions" EntityField="Application" />} value={applicationpermission?.Application} />
        <Field label={<EntityFieldDisplayName Entity="Application Permissions" EntityField="Role" />} value={applicationpermission?.Role} />
      </div>
    );
  };
