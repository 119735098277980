import { Button as _Button } from '@progress/kendo-react-buttons';
import { Tooltip } from '@progress/kendo-react-tooltip';
import { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { useNotification } from '../../hooks/useNotification';
import { nodeToText } from '../../system/util';
import { Stack } from './Stack';

type CopyProps = PropsWithChildren<{ notificationMessage?: string }>;

const Button = styled(_Button)`
  padding: 0;
`;

export const Copy = ({ children, notificationMessage }: CopyProps) => {
  const { sendNotification } = useNotification();

  const textToCopy = nodeToText(children);
  const copyTextToClipboard = async () => {
    navigator.clipboard.writeText(textToCopy);
    sendNotification(notificationMessage ?? 'Copied to clipboard', 'success');
  };

  return textToCopy ? (
    <Stack direction="row" spacing={0.5} center>
      {children}
      <Tooltip content={() => 'Copy to clipboard'}>
        <Button
          size="small"
          fillMode="flat"
          title="Copy to clipboard"
          onClick={copyTextToClipboard}
          icon="copy"
        />
      </Tooltip>
    </Stack>
  ) : (
    <>{children}</>
  );
};
