import { useSingleCompanyQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { Company } from '../api';
  
  export const CompanyPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Companies"
        queryHook={useSingleCompanyQuery}
        Details={<CompanyDetails />}
      />
    );
  };

  export const CompanyDetails = () => {
    const [company] = useSingleEntity<Company>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Companies" EntityField="ID" />} value={company?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Companies" EntityField="Name" />} value={company?.Name} />
        <Field label={<EntityFieldDisplayName Entity="Companies" EntityField="Description" />} value={company?.Description} />
        <Field label={<EntityFieldDisplayName Entity="Companies" EntityField="Website" />} value={<ExternalUrl>{company?.Website}</ExternalUrl>} />
        <Field label={<EntityFieldDisplayName Entity="Companies" EntityField="LogoURL" />} value={<ExternalUrl>{company?.LogoURL}</ExternalUrl>} />
        <Field label={<EntityFieldDisplayName Entity="Companies" EntityField="CreatedAt" />} value={company?.CreatedAt &&
            DateTime.fromMillis(company.CreatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Companies" EntityField="UpdatedAt" />} value={company?.UpdatedAt &&
            DateTime.fromMillis(company.UpdatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Companies" EntityField="Domain" />} value={company?.Domain} />
      </div>
    );
  };
