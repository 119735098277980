import { QueryHookOptions, QueryResult } from '@apollo/client';
import { Button } from '@progress/kendo-react-buttons';
import { Icon } from '@progress/kendo-react-common';
import { TabStripTab } from '@progress/kendo-react-layout';
import { some } from 'lodash';
import { createContext, ReactNode, useCallback, useContext } from 'react';
import { DetailColumn, EntityTable, FavoriteTitle, TabbedDetail, Tabs } from '..';
import { Exact } from '../../api';
import { Entity as EntityType, useEntities } from '../../context/EntitiesProvider';
import { LoadingContext } from '../../context/LoadingContext';
import { useUser } from '../../context/UserProvider';
import { emptyObject } from '../../system/util';

export type SingleEntityPageProps<TQuery> = {
  RecordID: number;
  Details?: ReactNode;
  EntityName: string;
  queryHook: (
    baseOptions: QueryHookOptions<TQuery, Exact<{ ID: number }>>
  ) => QueryResult<TQuery, Exact<{ ID: number }>>;
};

type SingleEntityPageContextType = {
  RecordID: number;
  Entity: EntityType;
  EntityData: Record<string, unknown> | undefined;
  loading?: boolean;
};
const SingleEntityContext = createContext<SingleEntityPageContextType>(
  emptyObject as SingleEntityPageContextType
);

export const useSingleEntity = <T extends Record<string, unknown>>() => {
  const { EntityData, ...context } = useContext(SingleEntityContext);
  return [EntityData, context] as [T | undefined, Omit<SingleEntityPageContextType, 'EntityData'>];
};

export const SingleEntityPage = <TQuery = unknown,>({
  RecordID,
  Details,
  EntityName,
  queryHook,
}: SingleEntityPageProps<TQuery>) => {
  const { AllEntitiesByName, getNameAttribute } = useEntities();

  const { loading, data, refetch } = queryHook({ variables: { ID: RecordID } });
  const Entity = AllEntitiesByName[EntityName];
  const EntityData = data?.[Entity.ClassName as keyof NonNullable<typeof data>] as
    | Record<string, unknown>
    | undefined;

  const EntityID = Entity.ID;
  const { FavoritesByEntityID, createFavorite, deleteFavorite } = useUser();
  const isFavorite = some(FavoritesByEntityID[EntityID], { RecordID });

  const toggleFavorite = useCallback(async () => {
    const toggle = isFavorite ? deleteFavorite : createFavorite;
    await toggle({ EntityID, RecordID });
  }, [EntityID, RecordID, createFavorite, isFavorite, deleteFavorite]);

  return (
    <LoadingContext loading={loading}>
      <TabbedDetail
        title={
          <FavoriteTitle {...{ isFavorite, toggleFavorite }}>
            {String(EntityData?.[getNameAttribute(Entity)] ?? `${Entity.Name}: ${RecordID}`)}
          </FavoriteTitle>
        }
        toolbar={
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <Button title="Refresh data" onClick={() => refetch()}>
              <Icon name="refresh" />
            </Button>
          </div>
        }
        tabs={
          <Tabs>
            <TabStripTab title="detail">
              <SingleEntityContext.Provider value={{ RecordID, Entity, EntityData, loading }}>
                {Details ?? <DetailColumn {...{ ...Entity, EntityData }} />}
              </SingleEntityContext.Provider>
            </TabStripTab>
            {Entity.EntityRelationships.map((relationship) => (
              <TabStripTab
                key={relationship.RelatedEntity}
                title={
                  relationship.DisplayName ?? AllEntitiesByName[relationship.RelatedEntity].Name
                }
              >
                <EntityTable
                  entity={AllEntitiesByName[relationship.RelatedEntity]}
                  ViewID={relationship.DisplayUserViewID ?? undefined}
                  ExtraFilter={
                    relationship.Type.toLowerCase().includes('one to many')
                      ? `${relationship.RelatedEntityJoinField}=${RecordID}`
                      : relationship.Type.toLowerCase().includes('many to many')
                      ? `${relationship.RelatedEntityJoinField} IN (
                        SELECT ${relationship.JoinEntityInverseJoinField} 
                        FROM ${relationship.JoinView} 
                        WHERE ${relationship.JoinEntityJoinField}=${RecordID})`
                      : undefined
                  }
                />
              </TabStripTab>
            ))}
          </Tabs>
        }
      />
    </LoadingContext>
  );
};
