import { GridLayoutColumnProps } from '@progress/kendo-react-layout';
import { useMemo } from 'react';
import { useBreakpoint } from 'use-breakpoint';
import { breakpoints } from '../system/util';

interface UseResponsiveCols {
  (cols: GridLayoutColumnProps[]): GridLayoutColumnProps[];
  (cols: number): GridLayoutColumnProps[];
}

export const useResponsiveCols: UseResponsiveCols = <T extends GridLayoutColumnProps[] | number>(
  cols: T
) => {
  const { breakpoint } = useBreakpoint(breakpoints);

  const responsiveCols = useMemo(() => {
    const returnCols: GridLayoutColumnProps[] =
      typeof cols === 'number'
        ? Array<GridLayoutColumnProps>(cols).fill({ width: `${(100 / cols).toFixed(2)}%` })
        : cols;

    return breakpoint === 'mobile'
      ? [
          {
            ...returnCols[0],
            width: '100%',
          },
        ]
      : returnCols;
  }, [breakpoint, cols]);

  return responsiveCols;
};
