import { useSingleTaxReturnCompensationQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { TaxReturnCompensation } from '../api';
  
  export const TaxReturnCompensationPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Tax Return Compensation"
        queryHook={useSingleTaxReturnCompensationQuery}
        Details={<TaxReturnCompensationDetails />}
      />
    );
  };

  export const TaxReturnCompensationDetails = () => {
    const [taxreturncompensation] = useSingleEntity<TaxReturnCompensation>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Tax Return Compensation" EntityField="ID" />} value={taxreturncompensation?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Tax Return Compensation" EntityField="TaxReturnID" />} value={<EntityLink Entity="Tax Return Compensation" EntityField="TaxReturnID">{taxreturncompensation?.TaxReturnID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Tax Return Compensation" EntityField="PersonName" />} value={taxreturncompensation?.PersonName} />
        <Field label={<EntityFieldDisplayName Entity="Tax Return Compensation" EntityField="Title" />} value={taxreturncompensation?.Title} />
        <Field label={<EntityFieldDisplayName Entity="Tax Return Compensation" EntityField="AverageHoursPerWeek" />} value={taxreturncompensation?.AverageHoursPerWeek} />
        <Field label={<EntityFieldDisplayName Entity="Tax Return Compensation" EntityField="IsOfficer" />} value={taxreturncompensation?.IsOfficer} />
        <Field label={<EntityFieldDisplayName Entity="Tax Return Compensation" EntityField="Compensation" />} value={taxreturncompensation?.Compensation !== undefined
              ? formatCurrency(Math.floor(taxreturncompensation.Compensation), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Return Compensation" EntityField="RelatedOrgCompensation" />} value={taxreturncompensation?.RelatedOrgCompensation !== undefined
              ? formatCurrency(Math.floor(taxreturncompensation.RelatedOrgCompensation), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Return Compensation" EntityField="OtherCompensation" />} value={taxreturncompensation?.OtherCompensation !== undefined
              ? formatCurrency(Math.floor(taxreturncompensation.OtherCompensation), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Return Compensation" EntityField="TotalCompensation" />} value={taxreturncompensation?.TotalCompensation !== undefined
              ? formatCurrency(Math.floor(taxreturncompensation.TotalCompensation), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Return Compensation" EntityField="PartialYear" />} value={taxreturncompensation?.PartialYear} />
        <Field label={<EntityFieldDisplayName Entity="Tax Return Compensation" EntityField="StartDate" />} value={taxreturncompensation?.StartDate &&
            DateTime.fromMillis(taxreturncompensation.StartDate).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Tax Return Compensation" EntityField="EndDate" />} value={taxreturncompensation?.EndDate} />
        <Field label={<EntityFieldDisplayName Entity="Tax Return Compensation" EntityField="AnnualizedTotalCompensation" />} value={taxreturncompensation?.AnnualizedTotalCompensation !== undefined
              ? formatCurrency(Math.floor(taxreturncompensation.AnnualizedTotalCompensation), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Return Compensation" EntityField="ContactID" />} value={<EntityLink Entity="Tax Return Compensation" EntityField="ContactID">{taxreturncompensation?.ContactID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Tax Return Compensation" EntityField="Contact" />} value={taxreturncompensation?.Contact} />
      </div>
    );
  };
