import { FieldRenderProps, FieldWrapper } from '@progress/kendo-react-form';
import { TextBox } from '@progress/kendo-react-inputs';
import { Error, FloatingLabel } from '@progress/kendo-react-labels';

export const FormTextBox = ({
  validationMessage,
  touched,
  label,
  id,
  className,
  ...rest
}: Partial<FieldRenderProps> & { className?: string }) => {
  return (
    <FieldWrapper>
      <FloatingLabel
        className={className}
        label={label}
        editorId={id}
        editorValue={rest.value}
        editorValid={rest.valid}
      >
        <TextBox id={id} {...rest} />
        {touched && !!validationMessage && <Error>{validationMessage}</Error>}
      </FloatingLabel>
    </FieldWrapper>
  );
};
