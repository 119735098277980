import {
  TabStrip as _TabStrip,
  TabStripProps,
  TabStripSelectEventArguments,
  TabStripTabProps,
} from '@progress/kendo-react-layout';
import { Children, isValidElement, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useLoadingContext } from '../../context/LoadingContext';
import { usePersistentState } from '../../hooks/usePersistentState';
import { ensureArray } from '../../system/util';

const TabStrip = styled(_TabStrip)`
  & .k-content > div {
    max-width: 100%;
    min-width: 100%;
  }
`;

const ENABLE_SAVED_TAB = false;

export const Tabs = ({ children, onSelect, ...props }: TabStripProps) => {
  const [hashes, setHashes] = usePersistentState<Record<string, string>>('hashes', {});
  const { hash, pathname } = useLocation();
  const navigate = useNavigate();
  const loading = useLoadingContext();

  const titles = ensureArray(
    Children.map(children, (child, index) =>
      isValidElement<TabStripTabProps>(child) ? child.props.title?.toString() ?? `${index}` : ''
    )
  );

  const savedTitle = ENABLE_SAVED_TAB ? hashes : {};

  const initalTitle = savedTitle[pathname] || hash?.substring(1) || titles[0];
  const [selected, setSelected] = useState(Math.max(0, titles.indexOf(initalTitle)));

  useEffect(() => {
    setSelected(Math.max(0, titles.indexOf(initalTitle)));
  }, [initalTitle, loading, titles]);

  const handleSelect = (event: TabStripSelectEventArguments) => {
    const newTab = titles[event.selected];
    const hashTab = newTab === titles[0] ? undefined : newTab;

    setSelected(event.selected);
    navigate({ hash: hashTab }, { replace: true });
    setHashes((prev) => (prev[pathname] === newTab ? prev : { ...prev, [pathname]: newTab }));

    onSelect?.(event);
  };

  return (
    <TabStrip selected={selected} onSelect={handleSelect} {...props}>
      {children}
    </TabStrip>
  );
};
