import { useCallback, useState } from 'react';

/**
 * @returns [handleShow, handleHide, open, focus]
 */
export const useModalControl = <T = unknown>(defaultFocus?: T) => {
  const [state, setState] = useState({
    open: false,
    focus: undefined as T | undefined,
  });
  const handleHide = useCallback(() => setState((prev) => ({ ...prev, open: false })), []);

  const handleShow = useCallback(
    (focus?: T): void =>
      setState({
        open: true,
        focus: focus ?? defaultFocus,
      }),
    [defaultFocus]
  );

  return [handleShow, handleHide, state.open, state.focus] as const;
};
