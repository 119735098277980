import { PublicClientApplication } from '@azure/msal-browser';
import { CLIENT_AUTHORITY, CLIENT_ID, REDIRECT_URI, TENANT_ID } from '../system/config';

export const msalInstance = new PublicClientApplication({
  auth: {
    clientId: CLIENT_ID,
    authority: CLIENT_AUTHORITY,
    redirectUri: REDIRECT_URI,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
});

export const loginRequest = {
  scopes: ['openid', 'profile', 'User.Read', 'email', 'offline_access'],
  domainHint: TENANT_ID,
};
