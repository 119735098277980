import { InteractionType } from '@azure/msal-browser';
import { MsalAuthenticationTemplate } from '@azure/msal-react';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import {
  Drawer,
  DrawerContent,
  DrawerItemProps,
  DrawerSelectEvent,
} from '@progress/kendo-react-layout';
import { kebabCase } from 'lodash';
import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import useBreakpoint from 'use-breakpoint';
import { loginRequest } from '../../auth';
import { useEntities } from '../../context/EntitiesProvider';
import { useHideChrome } from '../../hooks/useHideChrome';
import { breakpoints, ensureArray, ifDifferent } from '../../system/util';
import { AppNavbar } from '../organisms';

const ContentBox = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
`;

export const AppLayout = () => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const location = useLocation();
  const { appName } = useParams();
  const { AllApplications } = useEntities();
  const [currentApp, setCurrentApp] = useState(AllApplications?.[0]);
  const { breakpoint } = useBreakpoint(breakpoints);
  const isMobile = breakpoint === 'mobile';
  const hideChrome = useHideChrome();

  const [items, setItems] = useState<DrawerItemProps[]>([{ text: 'Loading...', disabled: true }]);

  useEffect(() => {
    if (!currentApp) {
      const newCurrentApp = appName
        ? AllApplications.find(({ Name }) => kebabCase(Name) === appName)
        : AllApplications[0];
      newCurrentApp && setCurrentApp(newCurrentApp);
    }
  }, [currentApp, AllApplications, appName]);

  useEffect(() => {
    if (currentApp) {
      const newItems: DrawerItemProps[] = currentApp.ApplicationEntities.map((entity) => ({
        text: entity.Entity,
        id: entity.EntityID,
      }));
      setItems(ifDifferent(newItems));
    }
  }, [currentApp]);

  const handleSelect = ({ itemTarget }: DrawerSelectEvent) => {
    navigate(`${kebabCase(currentApp?.Name)}/${kebabCase(itemTarget.props.text)}`);
  };

  useEffect(() => {
    setExpanded(false);
  }, [location, isMobile]);

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={loginRequest}
    >
      {hideChrome ? (
        <Outlet />
      ) : (
        <>
          <AppNavbar
            leftSection={
              <DropDownList
                dataItemKey="ID"
                textField="Name"
                data={ensureArray(AllApplications)}
                value={currentApp}
                onChange={(e) => setCurrentApp(e.target.value)}
              />
            }
            showSidebarMenu={isMobile}
            onSidebarOpen={() => setExpanded((prev) => !prev)}
          />
          <Drawer
            expanded={!isMobile || expanded}
            position="start"
            mode="push"
            items={items}
            onSelect={handleSelect}
          >
            <DrawerContent style={{ overflowX: 'clip' }}>
              <ContentBox>
                <Outlet />
              </ContentBox>
            </DrawerContent>
          </Drawer>
        </>
      )}
    </MsalAuthenticationTemplate>
  );
};
