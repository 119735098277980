import { useSingleSkillQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { Skill } from '../api';
  
  export const SkillPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Skills"
        queryHook={useSingleSkillQuery}
        Details={<SkillDetails />}
      />
    );
  };

  export const SkillDetails = () => {
    const [skill] = useSingleEntity<Skill>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Skills" EntityField="ID" />} value={skill?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Skills" EntityField="Name" />} value={skill?.Name} />
        <Field label={<EntityFieldDisplayName Entity="Skills" EntityField="ParentID" />} value={skill?.ParentID} />
        <Field label={<EntityFieldDisplayName Entity="Skills" EntityField="CreatedAt" />} value={skill?.CreatedAt &&
            DateTime.fromMillis(skill.CreatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Skills" EntityField="UpdatedAt" />} value={skill?.UpdatedAt &&
            DateTime.fromMillis(skill.UpdatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
      </div>
    );
  };
