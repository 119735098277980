import { FieldRenderProps, FieldWrapper } from '@progress/kendo-react-form';
import { TextArea } from '@progress/kendo-react-inputs';
import { Error, FloatingLabel } from '@progress/kendo-react-labels';

export const FormTextArea = ({
  validationMessage,
  touched,
  label,
  id,
  className,
  ...rest
}: FieldRenderProps & { className?: string }) => {
  return (
    <FieldWrapper>
      <FloatingLabel
        className={className}
        label={label}
        editorId={id}
        editorValue={rest.value}
        editorValid={rest.valid}
      >
        <TextArea id={id} {...rest} />
        {touched && !!validationMessage && <Error>{validationMessage}</Error>}
      </FloatingLabel>
    </FieldWrapper>
  );
};
