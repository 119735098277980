import { ReactNode } from 'react';
import { DefaultTheme, ThemeProvider } from 'styled-components';

declare module 'styled-components' {
  export interface DefaultTheme {
    colors: {
      primary: string;
      secondary: string;
      success: string;
      divider: string;
      paper: string;
      white: string;
    };
    spacing: (...factors: number[]) => string;
  }
}

export const StyledTheme = ({ children }: { children?: ReactNode }) => {
  const myTheme: DefaultTheme = {
    colors: {
      primary: '#323356',
      secondary: '#4f874a',
      success: '#4f874a',
      divider: 'rgba(0, 0, 0, 0.12)',
      paper: 'rgba(0, 0, 0, 0.03)',
      white: '#fff',
    },
    spacing: (...factors: number[]) => factors.map((factor) => `${4 * factor}px`).join(' '),
  };

  return <ThemeProvider theme={myTheme}>{children}</ThemeProvider>;
};
