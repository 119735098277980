import { useSingleListQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { List } from '../api';
  
  export const ListPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Lists"
        queryHook={useSingleListQuery}
        Details={<ListDetails />}
      />
    );
  };

  export const ListDetails = () => {
    const [list] = useSingleEntity<List>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Lists" EntityField="ID" />} value={list?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Lists" EntityField="Name" />} value={list?.Name} />
        <Field label={<EntityFieldDisplayName Entity="Lists" EntityField="Description" />} value={<BodyText>{list?.Description}</BodyText>} />
        <Field label={<EntityFieldDisplayName Entity="Lists" EntityField="EntityID" />} value={<EntityLink Entity="Lists" EntityField="EntityID">{list?.EntityID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Lists" EntityField="UserID" />} value={<EntityLink Entity="Lists" EntityField="UserID">{list?.UserID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Lists" EntityField="CreatedAt" />} value={list?.CreatedAt &&
            DateTime.fromMillis(list.CreatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Lists" EntityField="UpdatedAt" />} value={list?.UpdatedAt &&
            DateTime.fromMillis(list.UpdatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Lists" EntityField="Entity" />} value={list?.Entity} />
        <Field label={<EntityFieldDisplayName Entity="Lists" EntityField="User" />} value={list?.User} />
      </div>
    );
  };
