import { Button as _Button } from '@progress/kendo-react-buttons';
import { Typography } from '@progress/kendo-react-common';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Box = styled.main`
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-direction: column;
  min-height: 100%;
`;

const Container = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 600px) {
    padding-left: ${({ theme }) => theme.spacing(6)};
    padding-right: ${({ theme }) => theme.spacing(6)};
  }
  @media (min-width: 900px) {
    max-width: 900px;
  }
`;

const Message = styled(Typography.h3)`
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  margin: ${({ theme }) => theme.spacing(4, 0)};
`;

const Button = styled(_Button)`
  margin-top: ${({ theme }) => theme.spacing(4)};
`;

export const NotFound = () => {
  const navigate = useNavigate();
  return (
    <Box>
      <Container>
        <Box>
          <Message>The url requested was not found</Message>

          <Button onClick={() => navigate('/')} icon="arrow-left" color="primary">
            Go back to root
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default NotFound;
