import { Grid, GridProps } from '@progress/kendo-react-grid';
import { forwardRef } from 'react';
import { LoadingPanel } from '../atoms';

export type LoadingGridProps = GridProps & {
  loading?: boolean;
};

export const LoadingGrid = forwardRef<Grid, LoadingGridProps>(({ loading, ...props }, ref) => (
  <>
    <LoadingPanel loading={loading} containerSelector=".k-grid-content" />
    <Grid ref={ref} {...props} />
  </>
));
