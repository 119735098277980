import { useSingleUserViewRunQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { UserViewRun } from '../api';
  
  export const UserViewRunPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="User View Runs"
        queryHook={useSingleUserViewRunQuery}
        Details={<UserViewRunDetails />}
      />
    );
  };

  export const UserViewRunDetails = () => {
    const [userviewrun] = useSingleEntity<UserViewRun>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="User View Runs" EntityField="ID" />} value={userviewrun?.ID} />
        <Field label={<EntityFieldDisplayName Entity="User View Runs" EntityField="UserViewID" />} value={<EntityLink Entity="User View Runs" EntityField="UserViewID">{userviewrun?.UserViewID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="User View Runs" EntityField="RunAt" />} value={userviewrun?.RunAt &&
            DateTime.fromMillis(userviewrun.RunAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="User View Runs" EntityField="RunByUserID" />} value={<EntityLink Entity="User View Runs" EntityField="RunByUserID">{userviewrun?.RunByUserID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="User View Runs" EntityField="UserView" />} value={userviewrun?.UserView} />
        <Field label={<EntityFieldDisplayName Entity="User View Runs" EntityField="RunByUser" />} value={userviewrun?.RunByUser} />
      </div>
    );
  };
