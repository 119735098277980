import { useSingleCompanyIntegrationQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { CompanyIntegration } from '../api';
  
  export const CompanyIntegrationPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Company Integrations"
        queryHook={useSingleCompanyIntegrationQuery}
        Details={<CompanyIntegrationDetails />}
      />
    );
  };

  export const CompanyIntegrationDetails = () => {
    const [companyintegration] = useSingleEntity<CompanyIntegration>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Company Integrations" EntityField="ID" />} value={companyintegration?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Company Integrations" EntityField="CompanyID" />} value={<EntityLink Entity="Company Integrations" EntityField="CompanyID">{companyintegration?.CompanyID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Company Integrations" EntityField="IntegrationID" />} value={<EntityLink Entity="Company Integrations" EntityField="IntegrationID">{companyintegration?.IntegrationID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Company Integrations" EntityField="IsActive" />} value={companyintegration?.IsActive} />
        <Field label={<EntityFieldDisplayName Entity="Company Integrations" EntityField="AccessToken" />} value={companyintegration?.AccessToken} />
        <Field label={<EntityFieldDisplayName Entity="Company Integrations" EntityField="RefreshToken" />} value={companyintegration?.RefreshToken} />
        <Field label={<EntityFieldDisplayName Entity="Company Integrations" EntityField="TokenExpirationDate" />} value={companyintegration?.TokenExpirationDate &&
            DateTime.fromMillis(companyintegration.TokenExpirationDate).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Company Integrations" EntityField="APIKey" />} value={companyintegration?.APIKey} />
        <Field label={<EntityFieldDisplayName Entity="Company Integrations" EntityField="CreatedAt" />} value={companyintegration?.CreatedAt &&
            DateTime.fromMillis(companyintegration.CreatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Company Integrations" EntityField="UpdatedAt" />} value={companyintegration?.UpdatedAt &&
            DateTime.fromMillis(companyintegration.UpdatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Company Integrations" EntityField="ExternalSystemID" />} value={companyintegration?.ExternalSystemID} />
        <Field label={<EntityFieldDisplayName Entity="Company Integrations" EntityField="IsExternalSystemReadOnly" />} value={companyintegration?.IsExternalSystemReadOnly} />
        <Field label={<EntityFieldDisplayName Entity="Company Integrations" EntityField="ClientID" />} value={companyintegration?.ClientID} />
        <Field label={<EntityFieldDisplayName Entity="Company Integrations" EntityField="ClientSecret" />} value={companyintegration?.ClientSecret} />
        <Field label={<EntityFieldDisplayName Entity="Company Integrations" EntityField="CustomAttribute1" />} value={companyintegration?.CustomAttribute1} />
        <Field label={<EntityFieldDisplayName Entity="Company Integrations" EntityField="Company" />} value={companyintegration?.Company} />
        <Field label={<EntityFieldDisplayName Entity="Company Integrations" EntityField="Integration" />} value={companyintegration?.Integration} />
        <Field label={<EntityFieldDisplayName Entity="Company Integrations" EntityField="DriverClassName" />} value={companyintegration?.DriverClassName} />
        <Field label={<EntityFieldDisplayName Entity="Company Integrations" EntityField="DriverImportPath" />} value={companyintegration?.DriverImportPath} />
        <Field label={<EntityFieldDisplayName Entity="Company Integrations" EntityField="LastRunID" />} value={companyintegration?.LastRunID} />
        <Field label={<EntityFieldDisplayName Entity="Company Integrations" EntityField="LastRunStartedAt" />} value={companyintegration?.LastRunStartedAt &&
            DateTime.fromMillis(companyintegration.LastRunStartedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Company Integrations" EntityField="LastRunEndedAt" />} value={companyintegration?.LastRunEndedAt &&
            DateTime.fromMillis(companyintegration.LastRunEndedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
      </div>
    );
  };
