import { useSingleApplicationQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { Application } from '../api';
  
  export const ApplicationPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Applications"
        queryHook={useSingleApplicationQuery}
        Details={<ApplicationDetails />}
      />
    );
  };

  export const ApplicationDetails = () => {
    const [application] = useSingleEntity<Application>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Applications" EntityField="ID" />} value={application?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Applications" EntityField="Name" />} value={application?.Name} />
        <Field label={<EntityFieldDisplayName Entity="Applications" EntityField="Description" />} value={application?.Description} />
        <Field label={<EntityFieldDisplayName Entity="Applications" EntityField="CreatedAt" />} value={application?.CreatedAt &&
            DateTime.fromMillis(application.CreatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Applications" EntityField="UpdatedAt" />} value={application?.UpdatedAt &&
            DateTime.fromMillis(application.UpdatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
      </div>
    );
  };
