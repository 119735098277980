import { useSingleProjectStatusTypeQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { ProjectStatusType } from '../api';
  
  export const ProjectStatusTypePage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Project Status Types"
        queryHook={useSingleProjectStatusTypeQuery}
        Details={<ProjectStatusTypeDetails />}
      />
    );
  };

  export const ProjectStatusTypeDetails = () => {
    const [projectstatustype] = useSingleEntity<ProjectStatusType>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Project Status Types" EntityField="ID" />} value={projectstatustype?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Project Status Types" EntityField="Name" />} value={projectstatustype?.Name} />
        <Field label={<EntityFieldDisplayName Entity="Project Status Types" EntityField="Code" />} value={projectstatustype?.Code} />
        <Field label={<EntityFieldDisplayName Entity="Project Status Types" EntityField="Description" />} value={projectstatustype?.Description} />
        <Field label={<EntityFieldDisplayName Entity="Project Status Types" EntityField="CreatedAt" />} value={projectstatustype?.CreatedAt &&
            DateTime.fromMillis(projectstatustype.CreatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Project Status Types" EntityField="UpdatedAt" />} value={projectstatustype?.UpdatedAt &&
            DateTime.fromMillis(projectstatustype.UpdatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Project Status Types" EntityField="IsClosed" />} value={projectstatustype?.IsClosed} />
      </div>
    );
  };
