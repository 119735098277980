import { Copy } from './Copy';

type EmailProps = {
  children?: string;
};

export const Email = ({ children }: EmailProps) => {
  return children ? (
    <Copy>
      <a href={`mailto:${children}`}>{children}</a>
    </Copy>
  ) : null;
};
