import { useSingleEntityFieldQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { EntityField } from '../api';
  
  export const EntityFieldPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Entity Fields"
        queryHook={useSingleEntityFieldQuery}
        Details={<EntityFieldDetails />}
      />
    );
  };

  export const EntityFieldDetails = () => {
    const [entityfield] = useSingleEntity<EntityField>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Entity Fields" EntityField="ID" />} value={entityfield?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Entity Fields" EntityField="EntityID" />} value={<EntityLink Entity="Entity Fields" EntityField="EntityID">{entityfield?.EntityID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Entity Fields" EntityField="Sequence" />} value={entityfield?.Sequence} />
        <Field label={<EntityFieldDisplayName Entity="Entity Fields" EntityField="Name" />} value={entityfield?.Name} />
        <Field label={<EntityFieldDisplayName Entity="Entity Fields" EntityField="DisplayName" />} value={entityfield?.DisplayName} />
        <Field label={<EntityFieldDisplayName Entity="Entity Fields" EntityField="Description" />} value={entityfield?.Description} />
        <Field label={<EntityFieldDisplayName Entity="Entity Fields" EntityField="Category" />} value={entityfield?.Category} />
        <Field label={<EntityFieldDisplayName Entity="Entity Fields" EntityField="Type" />} value={entityfield?.Type} />
        <Field label={<EntityFieldDisplayName Entity="Entity Fields" EntityField="Length" />} value={entityfield?.Length} />
        <Field label={<EntityFieldDisplayName Entity="Entity Fields" EntityField="Precision" />} value={entityfield?.Precision} />
        <Field label={<EntityFieldDisplayName Entity="Entity Fields" EntityField="Scale" />} value={entityfield?.Scale} />
        <Field label={<EntityFieldDisplayName Entity="Entity Fields" EntityField="AllowsNull" />} value={entityfield?.AllowsNull} />
        <Field label={<EntityFieldDisplayName Entity="Entity Fields" EntityField="DefaultValue" />} value={entityfield?.DefaultValue} />
        <Field label={<EntityFieldDisplayName Entity="Entity Fields" EntityField="AutoIncrement" />} value={entityfield?.AutoIncrement} />
        <Field label={<EntityFieldDisplayName Entity="Entity Fields" EntityField="ValueListType" />} value={entityfield?.ValueListType} />
        <Field label={<EntityFieldDisplayName Entity="Entity Fields" EntityField="ExtendedType" />} value={entityfield?.ExtendedType} />
        <Field label={<EntityFieldDisplayName Entity="Entity Fields" EntityField="DefaultInView" />} value={entityfield?.DefaultInView} />
        <Field label={<EntityFieldDisplayName Entity="Entity Fields" EntityField="ViewCellTemplate" />} value={<BodyText>{entityfield?.ViewCellTemplate}</BodyText>} />
        <Field label={<EntityFieldDisplayName Entity="Entity Fields" EntityField="DefaultColumnWidth" />} value={entityfield?.DefaultColumnWidth} />
        <Field label={<EntityFieldDisplayName Entity="Entity Fields" EntityField="AllowUpdateAPI" />} value={entityfield?.AllowUpdateAPI} />
        <Field label={<EntityFieldDisplayName Entity="Entity Fields" EntityField="AllowUpdateInView" />} value={entityfield?.AllowUpdateInView} />
        <Field label={<EntityFieldDisplayName Entity="Entity Fields" EntityField="IncludeInUserSearchAPI" />} value={entityfield?.IncludeInUserSearchAPI} />
        <Field label={<EntityFieldDisplayName Entity="Entity Fields" EntityField="UserSearchParamFormatAPI" />} value={entityfield?.UserSearchParamFormatAPI} />
        <Field label={<EntityFieldDisplayName Entity="Entity Fields" EntityField="IncludeInGeneratedForm" />} value={entityfield?.IncludeInGeneratedForm} />
        <Field label={<EntityFieldDisplayName Entity="Entity Fields" EntityField="GeneratedFormSection" />} value={entityfield?.GeneratedFormSection} />
        <Field label={<EntityFieldDisplayName Entity="Entity Fields" EntityField="IsVirtual" />} value={entityfield?.IsVirtual} />
        <Field label={<EntityFieldDisplayName Entity="Entity Fields" EntityField="IsNameField" />} value={entityfield?.IsNameField} />
        <Field label={<EntityFieldDisplayName Entity="Entity Fields" EntityField="RelatedEntityID" />} value={<EntityLink Entity="Entity Fields" EntityField="RelatedEntityID">{entityfield?.RelatedEntityID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Entity Fields" EntityField="RelatedEntityFieldName" />} value={entityfield?.RelatedEntityFieldName} />
        <Field label={<EntityFieldDisplayName Entity="Entity Fields" EntityField="IncludeRelatedEntityNameFieldInBaseView" />} value={entityfield?.IncludeRelatedEntityNameFieldInBaseView} />
        <Field label={<EntityFieldDisplayName Entity="Entity Fields" EntityField="RelatedEntityNameFieldMap" />} value={entityfield?.RelatedEntityNameFieldMap} />
        <Field label={<EntityFieldDisplayName Entity="Entity Fields" EntityField="CreatedAt" />} value={entityfield?.CreatedAt &&
            DateTime.fromMillis(entityfield.CreatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Entity Fields" EntityField="UpdatedAt" />} value={entityfield?.UpdatedAt &&
            DateTime.fromMillis(entityfield.UpdatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Entity Fields" EntityField="Entity" />} value={entityfield?.Entity} />
        <Field label={<EntityFieldDisplayName Entity="Entity Fields" EntityField="SchemaName" />} value={entityfield?.SchemaName} />
        <Field label={<EntityFieldDisplayName Entity="Entity Fields" EntityField="BaseTable" />} value={entityfield?.BaseTable} />
        <Field label={<EntityFieldDisplayName Entity="Entity Fields" EntityField="BaseView" />} value={entityfield?.BaseView} />
        <Field label={<EntityFieldDisplayName Entity="Entity Fields" EntityField="EntityCodeName" />} value={entityfield?.EntityCodeName} />
        <Field label={<EntityFieldDisplayName Entity="Entity Fields" EntityField="EntityClassName" />} value={entityfield?.EntityClassName} />
        <Field label={<EntityFieldDisplayName Entity="Entity Fields" EntityField="RelatedEntity" />} value={entityfield?.RelatedEntity} />
        <Field label={<EntityFieldDisplayName Entity="Entity Fields" EntityField="RelatedEntitySchemaName" />} value={entityfield?.RelatedEntitySchemaName} />
        <Field label={<EntityFieldDisplayName Entity="Entity Fields" EntityField="RelatedEntityBaseTable" />} value={entityfield?.RelatedEntityBaseTable} />
        <Field label={<EntityFieldDisplayName Entity="Entity Fields" EntityField="RelatedEntityBaseView" />} value={entityfield?.RelatedEntityBaseView} />
        <Field label={<EntityFieldDisplayName Entity="Entity Fields" EntityField="RelatedEntityCodeName" />} value={entityfield?.RelatedEntityCodeName} />
        <Field label={<EntityFieldDisplayName Entity="Entity Fields" EntityField="RelatedEntityClassName" />} value={entityfield?.RelatedEntityClassName} />
      </div>
    );
  };
