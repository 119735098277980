import { FC } from 'react';
import { AccountPage } from './AccountPage';
import { ContactPage } from './ContactPage';
import { DealPage } from './DealPage';
import { ActivityPage } from './ActivityPage';
import { CompanyPage } from './CompanyPage';
import { EmployeePage } from './EmployeePage';
import { UserFavoritePage } from './UserFavoritePage';
import { AccountCompanyIntegrationPage } from './AccountCompanyIntegrationPage';
import { ContactCompanyIntegrationPage } from './ContactCompanyIntegrationPage';
import { ActivityAttachmentPage } from './ActivityAttachmentPage';
import { ProjectPage } from './ProjectPage';
import { RolePage } from './RolePage';
import { SkillPage } from './SkillPage';
import { TaskPage } from './TaskPage';
import { TaskTypePage } from './TaskTypePage';
import { IntegrationURLFormatPage } from './IntegrationURLFormatPage';
import { IntegrationPage } from './IntegrationPage';
import { CompanyIntegrationPage } from './CompanyIntegrationPage';
import { EntityFieldPage } from './EntityFieldPage';
import { EntityPage } from './EntityPage';
import { UserPage } from './UserPage';
import { EntityRelationshipPage } from './EntityRelationshipPage';
import { UserRecordLogPage } from './UserRecordLogPage';
import { UserViewPage } from './UserViewPage';
import { ContactTopicPage } from './ContactTopicPage';
import { PaymentPage } from './PaymentPage';
import { InvoicePage } from './InvoicePage';
import { EmailServiceProvidersPage } from './EmailServiceProvidersPage';
import { _990FullPage } from './_990FullPage';
import { _990LatestPage } from './_990LatestPage';
import { ContactEmailPage } from './ContactEmailPage';
import { ContactAccountPage } from './ContactAccountPage';
import { LifecycleStagePage } from './LifecycleStagePage';
import { DealStagePage } from './DealStagePage';
import { AccountTypePage } from './AccountTypePage';
import { TimeEntryPage } from './TimeEntryPage';
import { DealTypePage } from './DealTypePage';
import { ApplicationPage } from './ApplicationPage';
import { ApplicationEntityPage } from './ApplicationEntityPage';
import { EntityPermissionPage } from './EntityPermissionPage';
import { ApplicationPermissionPage } from './ApplicationPermissionPage';
import { UserApplicationEntityPage } from './UserApplicationEntityPage';
import { UserApplicationPage } from './UserApplicationPage';
import { ProjectStatusTypePage } from './ProjectStatusTypePage';
import { ProjectRateTypePage } from './ProjectRateTypePage';
import { ProjectBillingTypePage } from './ProjectBillingTypePage';
import { ListPage } from './ListPage';
import { ListDetailPage } from './ListDetailPage';
import { UserViewRunPage } from './UserViewRunPage';
import { UserViewRunDetailPage } from './UserViewRunDetailPage';
import { WorkflowRunPage } from './WorkflowRunPage';
import { WorkflowPage } from './WorkflowPage';
import { CampaignPage } from './CampaignPage';
import { CampaignStepPage } from './CampaignStepPage';
import { WorkflowEnginePage } from './WorkflowEnginePage';
import { RecordChangePage } from './RecordChangePage';
import { TeamPage } from './TeamPage';
import { UserRolePage } from './UserRolePage';
import { RowLevelSecurityFilterPage } from './RowLevelSecurityFilterPage';
import { AuditLogPage } from './AuditLogPage';
import { AuthorizationPage } from './AuthorizationPage';
import { AuthorizationRolePage } from './AuthorizationRolePage';
import { AuditLogTypePage } from './AuditLogTypePage';
import { EntityFieldValuePage } from './EntityFieldValuePage';
import { TaxReturnPage } from './TaxReturnPage';
import { TaxReturnDetailPage } from './TaxReturnDetailPage';
import { TaxReturnCompensationPage } from './TaxReturnCompensationPage';
import { TaxReturnContractorPage } from './TaxReturnContractorPage';
import { AIModelPage } from './AIModelPage';
import { AIActionPage } from './AIActionPage';
import { AIModelActionPage } from './AIModelActionPage';
import { EntityAIActionPage } from './EntityAIActionPage';
import { AIModelTypePage } from './AIModelTypePage';
import { ContactRolePage } from './ContactRolePage';
import { ContactLevelPage } from './ContactLevelPage';
import { QueueTypePage } from './QueueTypePage';
import { QueuePage } from './QueuePage';
import { QueueTaskPage } from './QueueTaskPage';

export const EntityPages: Record<string, FC<{ RecordID: number }>> = {
  Accounts: AccountPage,
  Contacts: ContactPage,
  Deals: DealPage,
  Activities: ActivityPage,
  Companies: CompanyPage,
  Employees: EmployeePage,
  UserFavorites: UserFavoritePage,
  AccountCompanyIntegrations: AccountCompanyIntegrationPage,
  ContactCompanyIntegrations: ContactCompanyIntegrationPage,
  ActivityAttachments: ActivityAttachmentPage,
  Projects: ProjectPage,
  Roles: RolePage,
  Skills: SkillPage,
  Tasks: TaskPage,
  TaskTypes: TaskTypePage,
  IntegrationURLFormats: IntegrationURLFormatPage,
  Integrations: IntegrationPage,
  CompanyIntegrations: CompanyIntegrationPage,
  EntityFields: EntityFieldPage,
  Entities: EntityPage,
  Users: UserPage,
  EntityRelationships: EntityRelationshipPage,
  UserRecordLogs: UserRecordLogPage,
  UserViews: UserViewPage,
  ContactTopics: ContactTopicPage,
  Payments: PaymentPage,
  Invoices: InvoicePage,
  EmailServiceProviders: EmailServiceProvidersPage,
  _990Full: _990FullPage,
  _990Latest: _990LatestPage,
  ContactEmails: ContactEmailPage,
  ContactAccounts: ContactAccountPage,
  LifecycleStages: LifecycleStagePage,
  DealStages: DealStagePage,
  AccountTypes: AccountTypePage,
  TimeEntries: TimeEntryPage,
  DealTypes: DealTypePage,
  Applications: ApplicationPage,
  ApplicationEntities: ApplicationEntityPage,
  EntityPermissions: EntityPermissionPage,
  ApplicationPermissions: ApplicationPermissionPage,
  UserApplicationEntities: UserApplicationEntityPage,
  UserApplications: UserApplicationPage,
  ProjectStatusTypes: ProjectStatusTypePage,
  ProjectRateTypes: ProjectRateTypePage,
  ProjectBillingTypes: ProjectBillingTypePage,
  Lists: ListPage,
  ListDetails: ListDetailPage,
  UserViewRuns: UserViewRunPage,
  UserViewRunDetails: UserViewRunDetailPage,
  WorkflowRuns: WorkflowRunPage,
  Workflows: WorkflowPage,
  Campaigns: CampaignPage,
  CampaignSteps: CampaignStepPage,
  WorkflowEngines: WorkflowEnginePage,
  RecordChanges: RecordChangePage,
  LatestMetadataUpdates: TeamPage,
  UserRoles: UserRolePage,
  RowLevelSecurityFilters: RowLevelSecurityFilterPage,
  AuditLogs: AuditLogPage,
  Authorizations: AuthorizationPage,
  AuthorizationRoles: AuthorizationRolePage,
  AuditLogTypes: AuditLogTypePage,
  EntityFieldValues: EntityFieldValuePage,
  TaxReturns: TaxReturnPage,
  TaxReturnDetails: TaxReturnDetailPage,
  TaxReturnCompensation: TaxReturnCompensationPage,
  TaxReturnContractors: TaxReturnContractorPage,
  AIModels: AIModelPage,
  AIActions: AIActionPage,
  AIModelActions: AIModelActionPage,
  EntityAIActions: EntityAIActionPage,
  AIModelTypes: AIModelTypePage,
  ContactRoles: ContactRolePage,
  ContactLevels: ContactLevelPage,
  QueueTypes: QueueTypePage,
  Queues: QueuePage,
  QueueTasks: QueueTaskPage
};
