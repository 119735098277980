import { useSingleUserRoleQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { UserRole } from '../api';
  
  export const UserRolePage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="User Roles"
        queryHook={useSingleUserRoleQuery}
        Details={<UserRoleDetails />}
      />
    );
  };

  export const UserRoleDetails = () => {
    const [userrole] = useSingleEntity<UserRole>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="User Roles" EntityField="ID" />} value={userrole?.ID} />
        <Field label={<EntityFieldDisplayName Entity="User Roles" EntityField="UserID" />} value={<EntityLink Entity="User Roles" EntityField="UserID">{userrole?.UserID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="User Roles" EntityField="RoleID" />} value={<EntityLink Entity="User Roles" EntityField="RoleID">{userrole?.RoleID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="User Roles" EntityField="CreatedAt" />} value={userrole?.CreatedAt &&
            DateTime.fromMillis(userrole.CreatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="User Roles" EntityField="UpdatedAt" />} value={userrole?.UpdatedAt &&
            DateTime.fromMillis(userrole.UpdatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="User Roles" EntityField="User" />} value={userrole?.User} />
        <Field label={<EntityFieldDisplayName Entity="User Roles" EntityField="Role" />} value={userrole?.Role} />
      </div>
    );
  };
