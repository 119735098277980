import { useSingleEntityAIActionQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { EntityAIAction } from '../api';
  
  export const EntityAIActionPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Entity AI Actions"
        queryHook={useSingleEntityAIActionQuery}
        Details={<EntityAIActionDetails />}
      />
    );
  };

  export const EntityAIActionDetails = () => {
    const [entityaiaction] = useSingleEntity<EntityAIAction>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Entity AI Actions" EntityField="ID" />} value={entityaiaction?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Entity AI Actions" EntityField="EntityID" />} value={<EntityLink Entity="Entity AI Actions" EntityField="EntityID">{entityaiaction?.EntityID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Entity AI Actions" EntityField="AIActionID" />} value={<EntityLink Entity="Entity AI Actions" EntityField="AIActionID">{entityaiaction?.AIActionID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Entity AI Actions" EntityField="AIModelID" />} value={<EntityLink Entity="Entity AI Actions" EntityField="AIModelID">{entityaiaction?.AIModelID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Entity AI Actions" EntityField="Prompt" />} value={<BodyText>{entityaiaction?.Prompt}</BodyText>} />
        <Field label={<EntityFieldDisplayName Entity="Entity AI Actions" EntityField="TriggerEvent" />} value={entityaiaction?.TriggerEvent} />
        <Field label={<EntityFieldDisplayName Entity="Entity AI Actions" EntityField="UserMessage" />} value={<BodyText>{entityaiaction?.UserMessage}</BodyText>} />
        <Field label={<EntityFieldDisplayName Entity="Entity AI Actions" EntityField="OutputType" />} value={entityaiaction?.OutputType} />
        <Field label={<EntityFieldDisplayName Entity="Entity AI Actions" EntityField="OutputField" />} value={entityaiaction?.OutputField} />
        <Field label={<EntityFieldDisplayName Entity="Entity AI Actions" EntityField="SkipIfOutputFieldNotEmpty" />} value={entityaiaction?.SkipIfOutputFieldNotEmpty} />
        <Field label={<EntityFieldDisplayName Entity="Entity AI Actions" EntityField="OutputEntityID" />} value={<EntityLink Entity="Entity AI Actions" EntityField="OutputEntityID">{entityaiaction?.OutputEntityID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Entity AI Actions" EntityField="Comments" />} value={<BodyText>{entityaiaction?.Comments}</BodyText>} />
        <Field label={<EntityFieldDisplayName Entity="Entity AI Actions" EntityField="Entity" />} value={entityaiaction?.Entity} />
        <Field label={<EntityFieldDisplayName Entity="Entity AI Actions" EntityField="AIAction" />} value={entityaiaction?.AIAction} />
        <Field label={<EntityFieldDisplayName Entity="Entity AI Actions" EntityField="AIModel" />} value={entityaiaction?.AIModel} />
        <Field label={<EntityFieldDisplayName Entity="Entity AI Actions" EntityField="OutputEntity" />} value={entityaiaction?.OutputEntity} />
      </div>
    );
  };
