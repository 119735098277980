import { useSingleDealStageQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { DealStage } from '../api';
  
  export const DealStagePage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Deal Stages"
        queryHook={useSingleDealStageQuery}
        Details={<DealStageDetails />}
      />
    );
  };

  export const DealStageDetails = () => {
    const [dealstage] = useSingleEntity<DealStage>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Deal Stages" EntityField="ID" />} value={dealstage?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Deal Stages" EntityField="Name" />} value={dealstage?.Name} />
        <Field label={<EntityFieldDisplayName Entity="Deal Stages" EntityField="Description" />} value={dealstage?.Description} />
        <Field label={<EntityFieldDisplayName Entity="Deal Stages" EntityField="CreatedAt" />} value={dealstage?.CreatedAt &&
            DateTime.fromMillis(dealstage.CreatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Deal Stages" EntityField="UpdatedAt" />} value={dealstage?.UpdatedAt &&
            DateTime.fromMillis(dealstage.UpdatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
      </div>
    );
  };
