import { useEffect, useState } from 'react';
import { ifDifferent } from '../system/util';

export const useDebounce = <TValue = unknown>(value: TValue, delay?: number) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(ifDifferent(value));
    }, delay ?? 300);

    return () => {
      clearTimeout(handler);
    };
  }, [delay, value]);

  return debouncedValue;
};
