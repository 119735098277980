import { useSingleDealQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { Deal } from '../api';
  
  export const DealPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Deals"
        queryHook={useSingleDealQuery}
        Details={<DealDetails />}
      />
    );
  };

  export const DealDetails = () => {
    const [deal] = useSingleEntity<Deal>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Deals" EntityField="ID" />} value={deal?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Deals" EntityField="BCMID" />} value={deal?.BCMID} />
        <Field label={<EntityFieldDisplayName Entity="Deals" EntityField="ExternalSystemRecordID" />} value={deal?.ExternalSystemRecordID} />
        <Field label={<EntityFieldDisplayName Entity="Deals" EntityField="CompanyIntegrationID" />} value={<EntityLink Entity="Deals" EntityField="CompanyIntegrationID">{deal?.CompanyIntegrationID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Deals" EntityField="AccountID" />} value={<EntityLink Entity="Deals" EntityField="AccountID">{deal?.AccountID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Deals" EntityField="ContactID" />} value={<EntityLink Entity="Deals" EntityField="ContactID">{deal?.ContactID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Deals" EntityField="Title" />} value={deal?.Title} />
        <Field label={<EntityFieldDisplayName Entity="Deals" EntityField="Description" />} value={<BodyText>{deal?.Description}</BodyText>} />
        <Field label={<EntityFieldDisplayName Entity="Deals" EntityField="Value" />} value={deal?.Value !== undefined
              ? formatCurrency(Math.floor(deal.Value), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Deals" EntityField="IncludeInForecast" />} value={deal?.IncludeInForecast} />
        <Field label={<EntityFieldDisplayName Entity="Deals" EntityField="Probability" />} value={deal?.Probability} />
        <Field label={<EntityFieldDisplayName Entity="Deals" EntityField="CloseDate" />} value={deal?.CloseDate &&
            DateTime.fromMillis(deal.CloseDate).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Deals" EntityField="EmployeeID" />} value={<EntityLink Entity="Deals" EntityField="EmployeeID">{deal?.EmployeeID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Deals" EntityField="Pipeline" />} value={deal?.Pipeline} />
        <Field label={<EntityFieldDisplayName Entity="Deals" EntityField="LeadSource" />} value={deal?.LeadSource} />
        <Field label={<EntityFieldDisplayName Entity="Deals" EntityField="LeadSourceDetail" />} value={<BodyText>{deal?.LeadSourceDetail}</BodyText>} />
        <Field label={<EntityFieldDisplayName Entity="Deals" EntityField="ExternalSystemCreatedAt" />} value={deal?.ExternalSystemCreatedAt &&
            DateTime.fromMillis(deal.ExternalSystemCreatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Deals" EntityField="ExternalSystemUpdatedAt" />} value={deal?.ExternalSystemUpdatedAt &&
            DateTime.fromMillis(deal.ExternalSystemUpdatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Deals" EntityField="DealTypeID" />} value={<EntityLink Entity="Deals" EntityField="DealTypeID">{deal?.DealTypeID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Deals" EntityField="DealStageID" />} value={<EntityLink Entity="Deals" EntityField="DealStageID">{deal?.DealStageID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Deals" EntityField="CreatedAt" />} value={deal?.CreatedAt &&
            DateTime.fromMillis(deal.CreatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Deals" EntityField="UpdatedAt" />} value={deal?.UpdatedAt &&
            DateTime.fromMillis(deal.UpdatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Deals" EntityField="DealForecastCategoryID" />} value={<EntityLink Entity="Deals" EntityField="DealForecastCategoryID">{deal?.DealForecastCategoryID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Deals" EntityField="MRR" />} value={deal?.MRR !== undefined
              ? formatCurrency(Math.floor(deal.MRR), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Deals" EntityField="OneTimeFees" />} value={deal?.OneTimeFees !== undefined
              ? formatCurrency(Math.floor(deal.OneTimeFees), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Deals" EntityField="ContractTermMonths" />} value={deal?.ContractTermMonths} />
        <Field label={<EntityFieldDisplayName Entity="Deals" EntityField="ForecastNotes" />} value={<BodyText>{deal?.ForecastNotes}</BodyText>} />
        <Field label={<EntityFieldDisplayName Entity="Deals" EntityField="CompanyID" />} value={deal?.CompanyID} />
        <Field label={<EntityFieldDisplayName Entity="Deals" EntityField="Company" />} value={deal?.Company} />
        <Field label={<EntityFieldDisplayName Entity="Deals" EntityField="CompanyLogoURL" />} value={deal?.CompanyLogoURL} />
        <Field label={<EntityFieldDisplayName Entity="Deals" EntityField="Account" />} value={deal?.Account} />
        <Field label={<EntityFieldDisplayName Entity="Deals" EntityField="ContactFirstName" />} value={deal?.ContactFirstName} />
        <Field label={<EntityFieldDisplayName Entity="Deals" EntityField="ContactLastName" />} value={deal?.ContactLastName} />
        <Field label={<EntityFieldDisplayName Entity="Deals" EntityField="ContactTitle" />} value={deal?.ContactTitle} />
        <Field label={<EntityFieldDisplayName Entity="Deals" EntityField="ContactEmail1" />} value={<Email>{deal?.ContactEmail1}</Email>} />
        <Field label={<EntityFieldDisplayName Entity="Deals" EntityField="ContactPhoneNumber" />} value={deal?.ContactPhoneNumber} />
        <Field label={<EntityFieldDisplayName Entity="Deals" EntityField="EmployeeEmail" />} value={<Email>{deal?.EmployeeEmail}</Email>} />
        <Field label={<EntityFieldDisplayName Entity="Deals" EntityField="EmployeeFirstName" />} value={deal?.EmployeeFirstName} />
        <Field label={<EntityFieldDisplayName Entity="Deals" EntityField="EmployeeLastName" />} value={deal?.EmployeeLastName} />
        <Field label={<EntityFieldDisplayName Entity="Deals" EntityField="EmployeeTitle" />} value={deal?.EmployeeTitle} />
        <Field label={<EntityFieldDisplayName Entity="Deals" EntityField="EmployeeSupervisorEmail" />} value={<Email>{deal?.EmployeeSupervisorEmail}</Email>} />
        <Field label={<EntityFieldDisplayName Entity="Deals" EntityField="EmployeeSupervisor" />} value={deal?.EmployeeSupervisor} />
      </div>
    );
  };
