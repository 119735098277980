import { useSingleIntegrationURLFormatQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { IntegrationURLFormat } from '../api';
  
  export const IntegrationURLFormatPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Integration URL Formats"
        queryHook={useSingleIntegrationURLFormatQuery}
        Details={<IntegrationURLFormatDetails />}
      />
    );
  };

  export const IntegrationURLFormatDetails = () => {
    const [integrationurlformat] = useSingleEntity<IntegrationURLFormat>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Integration URL Formats" EntityField="ID" />} value={integrationurlformat?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Integration URL Formats" EntityField="IntegrationID" />} value={<EntityLink Entity="Integration URL Formats" EntityField="IntegrationID">{integrationurlformat?.IntegrationID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Integration URL Formats" EntityField="EntityID" />} value={<EntityLink Entity="Integration URL Formats" EntityField="EntityID">{integrationurlformat?.EntityID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Integration URL Formats" EntityField="URLFormat" />} value={<ExternalUrl>{integrationurlformat?.URLFormat}</ExternalUrl>} />
        <Field label={<EntityFieldDisplayName Entity="Integration URL Formats" EntityField="NavigationBaseURL" />} value={integrationurlformat?.NavigationBaseURL} />
        <Field label={<EntityFieldDisplayName Entity="Integration URL Formats" EntityField="FullURLFormat" />} value={integrationurlformat?.FullURLFormat} />
      </div>
    );
  };
