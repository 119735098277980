import { Typography } from '@progress/kendo-react-common';
import { Skeleton } from '@progress/kendo-react-indicators';
import { PropsWithChildren } from 'react';
import { useLoadingContext } from '../../context/LoadingContext';

export type TitleProps = PropsWithChildren<{
  loading?: boolean;
}>;

export const Title = ({ children, loading }: TitleProps) => {
  const loadingContext = useLoadingContext();
  return (
    <Typography.h4>{loading || loadingContext ? <Skeleton /> : <>{children}</>}</Typography.h4>
  );
};
