import { useSingleTaskTypeQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { TaskType } from '../api';
  
  export const TaskTypePage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Task Types"
        queryHook={useSingleTaskTypeQuery}
        Details={<TaskTypeDetails />}
      />
    );
  };

  export const TaskTypeDetails = () => {
    const [tasktype] = useSingleEntity<TaskType>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Task Types" EntityField="ID" />} value={tasktype?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Task Types" EntityField="Name" />} value={tasktype?.Name} />
        <Field label={<EntityFieldDisplayName Entity="Task Types" EntityField="ParentID" />} value={tasktype?.ParentID} />
        <Field label={<EntityFieldDisplayName Entity="Task Types" EntityField="CreatedAt" />} value={tasktype?.CreatedAt &&
            DateTime.fromMillis(tasktype.CreatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Task Types" EntityField="UpdatedAt" />} value={tasktype?.UpdatedAt &&
            DateTime.fromMillis(tasktype.UpdatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Task Types" EntityField="IsActive" />} value={tasktype?.IsActive} />
      </div>
    );
  };
