import { useSingleWorkflowEngineQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { WorkflowEngine } from '../api';
  
  export const WorkflowEnginePage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Workflow Engines"
        queryHook={useSingleWorkflowEngineQuery}
        Details={<WorkflowEngineDetails />}
      />
    );
  };

  export const WorkflowEngineDetails = () => {
    const [workflowengine] = useSingleEntity<WorkflowEngine>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Workflow Engines" EntityField="ID" />} value={workflowengine?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Workflow Engines" EntityField="Name" />} value={workflowengine?.Name} />
        <Field label={<EntityFieldDisplayName Entity="Workflow Engines" EntityField="Description" />} value={<BodyText>{workflowengine?.Description}</BodyText>} />
        <Field label={<EntityFieldDisplayName Entity="Workflow Engines" EntityField="DriverPath" />} value={workflowengine?.DriverPath} />
        <Field label={<EntityFieldDisplayName Entity="Workflow Engines" EntityField="DriverClass" />} value={workflowengine?.DriverClass} />
        <Field label={<EntityFieldDisplayName Entity="Workflow Engines" EntityField="CreatedAt" />} value={workflowengine?.CreatedAt &&
            DateTime.fromMillis(workflowengine.CreatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Workflow Engines" EntityField="UpdatedAt" />} value={workflowengine?.UpdatedAt &&
            DateTime.fromMillis(workflowengine.UpdatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
      </div>
    );
  };
