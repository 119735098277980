import { onError } from '@apollo/client/link/error';
import { compact, filter, partition } from 'lodash';

export const errorLink = ({
  onErrorNotification,
  onRefreshError,
}: {
  onErrorNotification?: (error: string) => void;
  onRefreshError?: () => void;
}) =>
  onError(({ graphQLErrors = [], networkError }) => {
    const [refreshErrors, otherErrors] = partition(graphQLErrors, ({ message }) =>
      message.includes('expired')
    );
    const [unauthorized] = filter(graphQLErrors, ({ message }) =>
      message.includes('Access denied')
    );

    if (refreshErrors[0]) {
      onRefreshError?.();
      return;
    }

    onErrorNotification &&
      compact([...otherErrors, networkError]).map(({ message }) => onErrorNotification(message));

    unauthorized &&
      !window.location.pathname.includes('not-authorized') &&
      window.location.replace('/not-authorized');
  });
