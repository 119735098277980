import { Button as _Button } from '@progress/kendo-react-buttons';
import styled from 'styled-components';
import { Title, TitleProps } from '../atoms/Title';

type FavoriteTitleProps = TitleProps & {
  isFavorite: boolean;
  toggleFavorite: VoidFunction;
};

const Button = styled(_Button)`
  color: ${({ theme }) => theme.colors.secondary};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
`;

const Box = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  & *:not(:first-child) {
    margin-left: ${({ theme }) => theme.spacing(1)};
  }
`;

export const FavoriteTitle = ({
  isFavorite,
  toggleFavorite,
  ...titleProps
}: FavoriteTitleProps) => {
  return (
    <Box>
      <Title {...titleProps} />
      <Button
        fillMode="flat"
        icon={isFavorite ? 'star' : 'star-outline'}
        onClick={() => toggleFavorite()}
        title={isFavorite ? 'Remove from favorites' : 'Add favorite'}
      />
    </Box>
  );
};
