import { useSingleContactLevelQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { ContactLevel } from '../api';
  
  export const ContactLevelPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Contact Levels"
        queryHook={useSingleContactLevelQuery}
        Details={<ContactLevelDetails />}
      />
    );
  };

  export const ContactLevelDetails = () => {
    const [contactlevel] = useSingleEntity<ContactLevel>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Contact Levels" EntityField="ID" />} value={contactlevel?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Contact Levels" EntityField="Name" />} value={contactlevel?.Name} />
        <Field label={<EntityFieldDisplayName Entity="Contact Levels" EntityField="Description" />} value={<BodyText>{contactlevel?.Description}</BodyText>} />
        <Field label={<EntityFieldDisplayName Entity="Contact Levels" EntityField="Rank" />} value={contactlevel?.Rank} />
        <Field label={<EntityFieldDisplayName Entity="Contact Levels" EntityField="Keywords" />} value={<BodyText>{contactlevel?.Keywords}</BodyText>} />
        <Field label={<EntityFieldDisplayName Entity="Contact Levels" EntityField="ExcludeKeywords" />} value={<BodyText>{contactlevel?.ExcludeKeywords}</BodyText>} />
      </div>
    );
  };
