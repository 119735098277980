import { useSnackbar, VariantType } from 'notistack';
import { useCallback } from 'react';

export const useNotification = () => {
  const { enqueueSnackbar } = useSnackbar();

  const sendNotification = useCallback(
    (message: string, type?: VariantType) => {
      enqueueSnackbar((message as unknown as Error).message || message, {
        variant: type ?? 'default',
        autoHideDuration: 5000,
      });
    },
    [enqueueSnackbar]
  );

  return {
    sendNotification,
  };
};
