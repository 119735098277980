import { useSingleTaxReturnQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { TaxReturn } from '../api';
  
  export const TaxReturnPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Tax Returns"
        queryHook={useSingleTaxReturnQuery}
        Details={<TaxReturnDetails />}
      />
    );
  };

  export const TaxReturnDetails = () => {
    const [taxreturn] = useSingleEntity<TaxReturn>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="ID" />} value={taxreturn?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="TaxID" />} value={taxreturn?.TaxID} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="Name" />} value={taxreturn?.Name} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="ReturnType" />} value={taxreturn?.ReturnType} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="TaxPeriodStart" />} value={taxreturn?.TaxPeriodStart &&
            DateTime.fromMillis(taxreturn.TaxPeriodStart).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="TaxPeriodEnd" />} value={taxreturn?.TaxPeriodEnd &&
            DateTime.fromMillis(taxreturn.TaxPeriodEnd).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="TaxYear" />} value={taxreturn?.TaxYear} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="TaxMonth" />} value={taxreturn?.TaxMonth} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="AddressLine1" />} value={taxreturn?.AddressLine1} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="AddressLine2" />} value={taxreturn?.AddressLine2} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="City" />} value={taxreturn?.City} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="StateProvince" />} value={taxreturn?.StateProvince} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="PostalCode" />} value={taxreturn?.PostalCode} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="Website" />} value={taxreturn?.Website} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="Subsection" />} value={taxreturn?.Subsection} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="Affiliation" />} value={taxreturn?.Affiliation} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="YearFormed" />} value={taxreturn?.YearFormed} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="ActivityDescription" />} value={<BodyText>{taxreturn?.ActivityDescription}</BodyText>} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="MissionDescription" />} value={<BodyText>{taxreturn?.MissionDescription}</BodyText>} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="BODVotingMembers" />} value={taxreturn?.BODVotingMembers} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="PreparerFirm" />} value={taxreturn?.PreparerFirm} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="PreparerFirmTaxID" />} value={taxreturn?.PreparerFirmTaxID} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="PreparerFirmAddressLine1" />} value={taxreturn?.PreparerFirmAddressLine1} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="PreparerFirmAddressLine2" />} value={taxreturn?.PreparerFirmAddressLine2} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="PreparerFirmCity" />} value={taxreturn?.PreparerFirmCity} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="PreparerFirmStateProvince" />} value={taxreturn?.PreparerFirmStateProvince} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="PreparerFirmPostalCode" />} value={taxreturn?.PreparerFirmPostalCode} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="PreparerPerson" />} value={taxreturn?.PreparerPerson} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="PreparerPersonTaxID" />} value={taxreturn?.PreparerPersonTaxID} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="PreparerPersonPhone" />} value={taxreturn?.PreparerPersonPhone} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="IsHospital" />} value={taxreturn?.IsHospital} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="GrantsToOrgs" />} value={taxreturn?.GrantsToOrgs} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="GrantsToIndividuals" />} value={taxreturn?.GrantsToIndividuals} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="HasInvestmentIncome" />} value={taxreturn?.HasInvestmentIncome} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="TotalInvestmentIncome" />} value={taxreturn?.TotalInvestmentIncome !== undefined
              ? formatCurrency(Math.floor(taxreturn.TotalInvestmentIncome), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="NumberEmployees" />} value={taxreturn?.NumberEmployees} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="NumberVolunteers" />} value={taxreturn?.NumberVolunteers} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="HasForeignBankAcct" />} value={taxreturn?.HasForeignBankAcct} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="TotalGrossReceipts" />} value={taxreturn?.TotalGrossReceipts !== undefined
              ? formatCurrency(Math.floor(taxreturn.TotalGrossReceipts), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="TotalMemberIncome" />} value={taxreturn?.TotalMemberIncome !== undefined
              ? formatCurrency(Math.floor(taxreturn.TotalMemberIncome), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="TotalProgramServiceRevenue" />} value={taxreturn?.TotalProgramServiceRevenue !== undefined
              ? formatCurrency(Math.floor(taxreturn.TotalProgramServiceRevenue), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="GrossRentsFromRealEstate" />} value={taxreturn?.GrossRentsFromRealEstate !== undefined
              ? formatCurrency(Math.floor(taxreturn.GrossRentsFromRealEstate), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="RentalExpenseRealEstate" />} value={taxreturn?.RentalExpenseRealEstate !== undefined
              ? formatCurrency(Math.floor(taxreturn.RentalExpenseRealEstate), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="NetRentRealEstate" />} value={taxreturn?.NetRentRealEstate !== undefined
              ? formatCurrency(Math.floor(taxreturn.NetRentRealEstate), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="GrossSalesSecurities" />} value={taxreturn?.GrossSalesSecurities !== undefined
              ? formatCurrency(Math.floor(taxreturn.GrossSalesSecurities), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="CostBasisSecurities" />} value={taxreturn?.CostBasisSecurities !== undefined
              ? formatCurrency(Math.floor(taxreturn.CostBasisSecurities), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="NetGainSecurities" />} value={taxreturn?.NetGainSecurities !== undefined
              ? formatCurrency(Math.floor(taxreturn.NetGainSecurities), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="GrossFundraisingReceipts" />} value={taxreturn?.GrossFundraisingReceipts !== undefined
              ? formatCurrency(Math.floor(taxreturn.GrossFundraisingReceipts), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="FundraisingExpenses" />} value={taxreturn?.FundraisingExpenses !== undefined
              ? formatCurrency(Math.floor(taxreturn.FundraisingExpenses), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="FundraisingNetIncome" />} value={taxreturn?.FundraisingNetIncome !== undefined
              ? formatCurrency(Math.floor(taxreturn.FundraisingNetIncome), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="TotalRevenue" />} value={taxreturn?.TotalRevenue !== undefined
              ? formatCurrency(Math.floor(taxreturn.TotalRevenue), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="OfficerCompensation" />} value={taxreturn?.OfficerCompensation !== undefined
              ? formatCurrency(Math.floor(taxreturn.OfficerCompensation), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="DisqualifiedPersonCompensation" />} value={taxreturn?.DisqualifiedPersonCompensation !== undefined
              ? formatCurrency(Math.floor(taxreturn.DisqualifiedPersonCompensation), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="OtherSalaries" />} value={taxreturn?.OtherSalaries !== undefined
              ? formatCurrency(Math.floor(taxreturn.OtherSalaries), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="PensionContributions" />} value={taxreturn?.PensionContributions !== undefined
              ? formatCurrency(Math.floor(taxreturn.PensionContributions), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="OtherEmployeeBenefits" />} value={taxreturn?.OtherEmployeeBenefits !== undefined
              ? formatCurrency(Math.floor(taxreturn.OtherEmployeeBenefits), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="PayrollTaxes" />} value={taxreturn?.PayrollTaxes !== undefined
              ? formatCurrency(Math.floor(taxreturn.PayrollTaxes), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="ManagementFees" />} value={taxreturn?.ManagementFees !== undefined
              ? formatCurrency(Math.floor(taxreturn.ManagementFees), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="LegalFees" />} value={taxreturn?.LegalFees !== undefined
              ? formatCurrency(Math.floor(taxreturn.LegalFees), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="AccountingFees" />} value={taxreturn?.AccountingFees !== undefined
              ? formatCurrency(Math.floor(taxreturn.AccountingFees), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="LobbyingFees" />} value={taxreturn?.LobbyingFees !== undefined
              ? formatCurrency(Math.floor(taxreturn.LobbyingFees), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="ProfessionalFundraisingFees" />} value={taxreturn?.ProfessionalFundraisingFees !== undefined
              ? formatCurrency(Math.floor(taxreturn.ProfessionalFundraisingFees), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="InvestmentManagementFees" />} value={taxreturn?.InvestmentManagementFees !== undefined
              ? formatCurrency(Math.floor(taxreturn.InvestmentManagementFees), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="OtherFees" />} value={taxreturn?.OtherFees !== undefined
              ? formatCurrency(Math.floor(taxreturn.OtherFees), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="AdvertisingExpense" />} value={taxreturn?.AdvertisingExpense !== undefined
              ? formatCurrency(Math.floor(taxreturn.AdvertisingExpense), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="OfficeExpense" />} value={taxreturn?.OfficeExpense !== undefined
              ? formatCurrency(Math.floor(taxreturn.OfficeExpense), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="InformationTechnologyExpense" />} value={taxreturn?.InformationTechnologyExpense !== undefined
              ? formatCurrency(Math.floor(taxreturn.InformationTechnologyExpense), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="RoyaltyExpense" />} value={taxreturn?.RoyaltyExpense !== undefined
              ? formatCurrency(Math.floor(taxreturn.RoyaltyExpense), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="OccupancyExpense" />} value={taxreturn?.OccupancyExpense !== undefined
              ? formatCurrency(Math.floor(taxreturn.OccupancyExpense), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="TravelExpense" />} value={taxreturn?.TravelExpense !== undefined
              ? formatCurrency(Math.floor(taxreturn.TravelExpense), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="TravelExpensePublicOfficials" />} value={taxreturn?.TravelExpensePublicOfficials !== undefined
              ? formatCurrency(Math.floor(taxreturn.TravelExpensePublicOfficials), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="ConferenceExpense" />} value={taxreturn?.ConferenceExpense !== undefined
              ? formatCurrency(Math.floor(taxreturn.ConferenceExpense), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="InterestExpense" />} value={taxreturn?.InterestExpense !== undefined
              ? formatCurrency(Math.floor(taxreturn.InterestExpense), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="AffiliatePaymentExpense" />} value={taxreturn?.AffiliatePaymentExpense !== undefined
              ? formatCurrency(Math.floor(taxreturn.AffiliatePaymentExpense), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="DepreciationExpense" />} value={taxreturn?.DepreciationExpense !== undefined
              ? formatCurrency(Math.floor(taxreturn.DepreciationExpense), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="InsuranceExpense" />} value={taxreturn?.InsuranceExpense !== undefined
              ? formatCurrency(Math.floor(taxreturn.InsuranceExpense), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="Cash" />} value={taxreturn?.Cash !== undefined
              ? formatCurrency(Math.floor(taxreturn.Cash), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="Savings" />} value={taxreturn?.Savings !== undefined
              ? formatCurrency(Math.floor(taxreturn.Savings), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="PledgesGrantsReceivable" />} value={taxreturn?.PledgesGrantsReceivable !== undefined
              ? formatCurrency(Math.floor(taxreturn.PledgesGrantsReceivable), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="AccountsReceivable" />} value={taxreturn?.AccountsReceivable !== undefined
              ? formatCurrency(Math.floor(taxreturn.AccountsReceivable), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="ReceivablesFromOfficers" />} value={taxreturn?.ReceivablesFromOfficers !== undefined
              ? formatCurrency(Math.floor(taxreturn.ReceivablesFromOfficers), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="ReceivablesFromDisqualifiedPersons" />} value={taxreturn?.ReceivablesFromDisqualifiedPersons !== undefined
              ? formatCurrency(Math.floor(taxreturn.ReceivablesFromDisqualifiedPersons), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="NotesReceivable" />} value={taxreturn?.NotesReceivable !== undefined
              ? formatCurrency(Math.floor(taxreturn.NotesReceivable), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="InventoryAssetValue" />} value={taxreturn?.InventoryAssetValue !== undefined
              ? formatCurrency(Math.floor(taxreturn.InventoryAssetValue), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="PrepaidExpenseAssetValue" />} value={taxreturn?.PrepaidExpenseAssetValue !== undefined
              ? formatCurrency(Math.floor(taxreturn.PrepaidExpenseAssetValue), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="RealEstateAssetValue" />} value={taxreturn?.RealEstateAssetValue !== undefined
              ? formatCurrency(Math.floor(taxreturn.RealEstateAssetValue), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="PublicSecuritiesAssetValue" />} value={taxreturn?.PublicSecuritiesAssetValue !== undefined
              ? formatCurrency(Math.floor(taxreturn.PublicSecuritiesAssetValue), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="OtherSecuritiesAssetValue" />} value={taxreturn?.OtherSecuritiesAssetValue !== undefined
              ? formatCurrency(Math.floor(taxreturn.OtherSecuritiesAssetValue), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="ProgramInvestmentsAssetValue" />} value={taxreturn?.ProgramInvestmentsAssetValue !== undefined
              ? formatCurrency(Math.floor(taxreturn.ProgramInvestmentsAssetValue), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="IntangibleAssetValue" />} value={taxreturn?.IntangibleAssetValue !== undefined
              ? formatCurrency(Math.floor(taxreturn.IntangibleAssetValue), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="OtherAssets" />} value={taxreturn?.OtherAssets !== undefined
              ? formatCurrency(Math.floor(taxreturn.OtherAssets), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="TotalAssets" />} value={taxreturn?.TotalAssets !== undefined
              ? formatCurrency(Math.floor(taxreturn.TotalAssets), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="AccountsPayable" />} value={taxreturn?.AccountsPayable !== undefined
              ? formatCurrency(Math.floor(taxreturn.AccountsPayable), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="GrantsPayable" />} value={taxreturn?.GrantsPayable !== undefined
              ? formatCurrency(Math.floor(taxreturn.GrantsPayable), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="DeferredRevenue" />} value={taxreturn?.DeferredRevenue !== undefined
              ? formatCurrency(Math.floor(taxreturn.DeferredRevenue), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="TaxExemptBondLiability" />} value={taxreturn?.TaxExemptBondLiability !== undefined
              ? formatCurrency(Math.floor(taxreturn.TaxExemptBondLiability), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="EscrowAccountLiability" />} value={taxreturn?.EscrowAccountLiability !== undefined
              ? formatCurrency(Math.floor(taxreturn.EscrowAccountLiability), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="PayablesToOfficers" />} value={taxreturn?.PayablesToOfficers !== undefined
              ? formatCurrency(Math.floor(taxreturn.PayablesToOfficers), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="MortgagesNotesPayable" />} value={taxreturn?.MortgagesNotesPayable !== undefined
              ? formatCurrency(Math.floor(taxreturn.MortgagesNotesPayable), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="UnsecuredNotesPayable" />} value={taxreturn?.UnsecuredNotesPayable !== undefined
              ? formatCurrency(Math.floor(taxreturn.UnsecuredNotesPayable), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="OtherLiabilities" />} value={taxreturn?.OtherLiabilities !== undefined
              ? formatCurrency(Math.floor(taxreturn.OtherLiabilities), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="TotalLiabilities" />} value={taxreturn?.TotalLiabilities !== undefined
              ? formatCurrency(Math.floor(taxreturn.TotalLiabilities), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="UnrestrictedNetAssets" />} value={taxreturn?.UnrestrictedNetAssets !== undefined
              ? formatCurrency(Math.floor(taxreturn.UnrestrictedNetAssets), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="TemporarilyRestrictedNetAssets" />} value={taxreturn?.TemporarilyRestrictedNetAssets !== undefined
              ? formatCurrency(Math.floor(taxreturn.TemporarilyRestrictedNetAssets), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="PermanentlyRestrictedNetAssets" />} value={taxreturn?.PermanentlyRestrictedNetAssets !== undefined
              ? formatCurrency(Math.floor(taxreturn.PermanentlyRestrictedNetAssets), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="CapitalStockPrincipal" />} value={taxreturn?.CapitalStockPrincipal !== undefined
              ? formatCurrency(Math.floor(taxreturn.CapitalStockPrincipal), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="PaidInCapitalSurplus" />} value={taxreturn?.PaidInCapitalSurplus !== undefined
              ? formatCurrency(Math.floor(taxreturn.PaidInCapitalSurplus), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="RetainedEarnings" />} value={taxreturn?.RetainedEarnings !== undefined
              ? formatCurrency(Math.floor(taxreturn.RetainedEarnings), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="TotalNetAssets" />} value={taxreturn?.TotalNetAssets !== undefined
              ? formatCurrency(Math.floor(taxreturn.TotalNetAssets), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="TotalNetAssetsPlusLiabilities" />} value={taxreturn?.TotalNetAssetsPlusLiabilities !== undefined
              ? formatCurrency(Math.floor(taxreturn.TotalNetAssetsPlusLiabilities), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="AccountID" />} value={<EntityLink Entity="Tax Returns" EntityField="AccountID">{taxreturn?.AccountID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="SourceJSON" />} value={<BodyText>{taxreturn?.SourceJSON}</BodyText>} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="SummaryText" />} value={<BodyText>{taxreturn?.SummaryText}</BodyText>} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="FinancialStrength" />} value={taxreturn?.FinancialStrength} />
        <Field label={<EntityFieldDisplayName Entity="Tax Returns" EntityField="Account" />} value={taxreturn?.Account} />
      </div>
    );
  };
