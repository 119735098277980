import { useSingleEntityRelationshipQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { EntityRelationship } from '../api';
  
  export const EntityRelationshipPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Entity Relationships"
        queryHook={useSingleEntityRelationshipQuery}
        Details={<EntityRelationshipDetails />}
      />
    );
  };

  export const EntityRelationshipDetails = () => {
    const [entityrelationship] = useSingleEntity<EntityRelationship>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Entity Relationships" EntityField="ID" />} value={entityrelationship?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Entity Relationships" EntityField="EntityID" />} value={<EntityLink Entity="Entity Relationships" EntityField="EntityID">{entityrelationship?.EntityID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Entity Relationships" EntityField="RelatedEntityID" />} value={<EntityLink Entity="Entity Relationships" EntityField="RelatedEntityID">{entityrelationship?.RelatedEntityID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Entity Relationships" EntityField="BundleInAPI" />} value={entityrelationship?.BundleInAPI} />
        <Field label={<EntityFieldDisplayName Entity="Entity Relationships" EntityField="IncludeInParentAllQuery" />} value={entityrelationship?.IncludeInParentAllQuery} />
        <Field label={<EntityFieldDisplayName Entity="Entity Relationships" EntityField="Type" />} value={entityrelationship?.Type} />
        <Field label={<EntityFieldDisplayName Entity="Entity Relationships" EntityField="EntityKeyField" />} value={entityrelationship?.EntityKeyField} />
        <Field label={<EntityFieldDisplayName Entity="Entity Relationships" EntityField="RelatedEntityJoinField" />} value={entityrelationship?.RelatedEntityJoinField} />
        <Field label={<EntityFieldDisplayName Entity="Entity Relationships" EntityField="JoinView" />} value={entityrelationship?.JoinView} />
        <Field label={<EntityFieldDisplayName Entity="Entity Relationships" EntityField="JoinEntityJoinField" />} value={entityrelationship?.JoinEntityJoinField} />
        <Field label={<EntityFieldDisplayName Entity="Entity Relationships" EntityField="JoinEntityInverseJoinField" />} value={entityrelationship?.JoinEntityInverseJoinField} />
        <Field label={<EntityFieldDisplayName Entity="Entity Relationships" EntityField="DisplayInForm" />} value={entityrelationship?.DisplayInForm} />
        <Field label={<EntityFieldDisplayName Entity="Entity Relationships" EntityField="DisplayName" />} value={entityrelationship?.DisplayName} />
        <Field label={<EntityFieldDisplayName Entity="Entity Relationships" EntityField="DisplayUserViewID" />} value={<EntityLink Entity="Entity Relationships" EntityField="DisplayUserViewID">{entityrelationship?.DisplayUserViewID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Entity Relationships" EntityField="CreatedAt" />} value={entityrelationship?.CreatedAt &&
            DateTime.fromMillis(entityrelationship.CreatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Entity Relationships" EntityField="UpdatedAt" />} value={entityrelationship?.UpdatedAt &&
            DateTime.fromMillis(entityrelationship.UpdatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Entity Relationships" EntityField="Entity" />} value={entityrelationship?.Entity} />
        <Field label={<EntityFieldDisplayName Entity="Entity Relationships" EntityField="EntityBaseTable" />} value={entityrelationship?.EntityBaseTable} />
        <Field label={<EntityFieldDisplayName Entity="Entity Relationships" EntityField="EntityBaseView" />} value={entityrelationship?.EntityBaseView} />
        <Field label={<EntityFieldDisplayName Entity="Entity Relationships" EntityField="RelatedEntity" />} value={entityrelationship?.RelatedEntity} />
        <Field label={<EntityFieldDisplayName Entity="Entity Relationships" EntityField="RelatedEntityBaseTable" />} value={entityrelationship?.RelatedEntityBaseTable} />
        <Field label={<EntityFieldDisplayName Entity="Entity Relationships" EntityField="RelatedEntityBaseView" />} value={entityrelationship?.RelatedEntityBaseView} />
        <Field label={<EntityFieldDisplayName Entity="Entity Relationships" EntityField="RelatedEntityClassName" />} value={entityrelationship?.RelatedEntityClassName} />
        <Field label={<EntityFieldDisplayName Entity="Entity Relationships" EntityField="RelatedEntityCodeName" />} value={entityrelationship?.RelatedEntityCodeName} />
        <Field label={<EntityFieldDisplayName Entity="Entity Relationships" EntityField="RelatedEntityBaseTableCodeName" />} value={entityrelationship?.RelatedEntityBaseTableCodeName} />
      </div>
    );
  };
