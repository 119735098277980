import { useSingleListDetailQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { ListDetail } from '../api';
  
  export const ListDetailPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="List Details"
        queryHook={useSingleListDetailQuery}
        Details={<ListDetailDetails />}
      />
    );
  };

  export const ListDetailDetails = () => {
    const [listdetail] = useSingleEntity<ListDetail>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="List Details" EntityField="ID" />} value={listdetail?.ID} />
        <Field label={<EntityFieldDisplayName Entity="List Details" EntityField="ListID" />} value={<EntityLink Entity="List Details" EntityField="ListID">{listdetail?.ListID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="List Details" EntityField="RecordID" />} value={listdetail?.RecordID} />
        <Field label={<EntityFieldDisplayName Entity="List Details" EntityField="Sequence" />} value={listdetail?.Sequence} />
      </div>
    );
  };
