import { useSingleEntityFieldValueQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { EntityFieldValue } from '../api';
  
  export const EntityFieldValuePage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Entity Field Values"
        queryHook={useSingleEntityFieldValueQuery}
        Details={<EntityFieldValueDetails />}
      />
    );
  };

  export const EntityFieldValueDetails = () => {
    const [entityfieldvalue] = useSingleEntity<EntityFieldValue>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Entity Field Values" EntityField="ID" />} value={entityfieldvalue?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Entity Field Values" EntityField="EntityFieldID" />} value={<EntityLink Entity="Entity Field Values" EntityField="EntityFieldID">{entityfieldvalue?.EntityFieldID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Entity Field Values" EntityField="Sequence" />} value={entityfieldvalue?.Sequence} />
        <Field label={<EntityFieldDisplayName Entity="Entity Field Values" EntityField="Value" />} value={entityfieldvalue?.Value} />
        <Field label={<EntityFieldDisplayName Entity="Entity Field Values" EntityField="Code" />} value={entityfieldvalue?.Code} />
        <Field label={<EntityFieldDisplayName Entity="Entity Field Values" EntityField="Description" />} value={<BodyText>{entityfieldvalue?.Description}</BodyText>} />
        <Field label={<EntityFieldDisplayName Entity="Entity Field Values" EntityField="CreatedAt" />} value={entityfieldvalue?.CreatedAt &&
            DateTime.fromMillis(entityfieldvalue.CreatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Entity Field Values" EntityField="UpdatedAt" />} value={entityfieldvalue?.UpdatedAt &&
            DateTime.fromMillis(entityfieldvalue.UpdatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Entity Field Values" EntityField="EntityField" />} value={entityfieldvalue?.EntityField} />
      </div>
    );
  };
