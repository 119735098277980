import { useSingleRecordChangeQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { RecordChange } from '../api';
  
  export const RecordChangePage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Record Changes"
        queryHook={useSingleRecordChangeQuery}
        Details={<RecordChangeDetails />}
      />
    );
  };

  export const RecordChangeDetails = () => {
    const [recordchange] = useSingleEntity<RecordChange>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Record Changes" EntityField="ID" />} value={recordchange?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Record Changes" EntityField="EntityID" />} value={<EntityLink Entity="Record Changes" EntityField="EntityID">{recordchange?.EntityID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Record Changes" EntityField="RecordID" />} value={recordchange?.RecordID} />
        <Field label={<EntityFieldDisplayName Entity="Record Changes" EntityField="UserID" />} value={<EntityLink Entity="Record Changes" EntityField="UserID">{recordchange?.UserID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Record Changes" EntityField="ChangedAt" />} value={recordchange?.ChangedAt &&
            DateTime.fromMillis(recordchange.ChangedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Record Changes" EntityField="ChangesJSON" />} value={<BodyText>{recordchange?.ChangesJSON}</BodyText>} />
        <Field label={<EntityFieldDisplayName Entity="Record Changes" EntityField="ChangesDescription" />} value={<BodyText>{recordchange?.ChangesDescription}</BodyText>} />
        <Field label={<EntityFieldDisplayName Entity="Record Changes" EntityField="FullRecordJSON" />} value={<BodyText>{recordchange?.FullRecordJSON}</BodyText>} />
        <Field label={<EntityFieldDisplayName Entity="Record Changes" EntityField="Status" />} value={recordchange?.Status} />
        <Field label={<EntityFieldDisplayName Entity="Record Changes" EntityField="Comments" />} value={<BodyText>{recordchange?.Comments}</BodyText>} />
        <Field label={<EntityFieldDisplayName Entity="Record Changes" EntityField="Entity" />} value={recordchange?.Entity} />
        <Field label={<EntityFieldDisplayName Entity="Record Changes" EntityField="User" />} value={recordchange?.User} />
      </div>
    );
  };
