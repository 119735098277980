import { useSingleApplicationEntityQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { ApplicationEntity } from '../api';
  
  export const ApplicationEntityPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Application Entities"
        queryHook={useSingleApplicationEntityQuery}
        Details={<ApplicationEntityDetails />}
      />
    );
  };

  export const ApplicationEntityDetails = () => {
    const [applicationentity] = useSingleEntity<ApplicationEntity>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Application Entities" EntityField="ID" />} value={applicationentity?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Application Entities" EntityField="ApplicationID" />} value={<EntityLink Entity="Application Entities" EntityField="ApplicationID">{applicationentity?.ApplicationID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Application Entities" EntityField="EntityID" />} value={<EntityLink Entity="Application Entities" EntityField="EntityID">{applicationentity?.EntityID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Application Entities" EntityField="Sequence" />} value={applicationentity?.Sequence} />
        <Field label={<EntityFieldDisplayName Entity="Application Entities" EntityField="DefaultForNewUser" />} value={applicationentity?.DefaultForNewUser} />
        <Field label={<EntityFieldDisplayName Entity="Application Entities" EntityField="CreatedAt" />} value={applicationentity?.CreatedAt &&
            DateTime.fromMillis(applicationentity.CreatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Application Entities" EntityField="UpdatedAt" />} value={applicationentity?.UpdatedAt &&
            DateTime.fromMillis(applicationentity.UpdatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Application Entities" EntityField="Application" />} value={applicationentity?.Application} />
        <Field label={<EntityFieldDisplayName Entity="Application Entities" EntityField="Entity" />} value={applicationentity?.Entity} />
        <Field label={<EntityFieldDisplayName Entity="Application Entities" EntityField="EntityBaseTable" />} value={applicationentity?.EntityBaseTable} />
        <Field label={<EntityFieldDisplayName Entity="Application Entities" EntityField="EntityCodeName" />} value={applicationentity?.EntityCodeName} />
        <Field label={<EntityFieldDisplayName Entity="Application Entities" EntityField="EntityClassName" />} value={applicationentity?.EntityClassName} />
        <Field label={<EntityFieldDisplayName Entity="Application Entities" EntityField="EntityBaseTableCodeName" />} value={applicationentity?.EntityBaseTableCodeName} />
      </div>
    );
  };
