import { DropDownList as _DropDownList } from '@progress/kendo-react-dropdowns';
import { find } from 'lodash';
import styled from 'styled-components';
import { useUser } from '../../context/UserProvider';
import { ensureArray } from '../../system/util';

type ViewDropDownProps = {
  EntityID: number;
  onChange?: (ViewID?: number) => void;
  ViewID?: number;
};

const DropDownList = styled(_DropDownList)`
  min-width: 200px;
`;

const unsavedView = { ID: -1, Name: 'Unsaved View' };

export const ViewDropDown = ({ EntityID, onChange, ViewID }: ViewDropDownProps) => {
  const { ViewsByEntityID } = useUser();
  const views = [unsavedView, ...ensureArray(ViewsByEntityID[EntityID])];

  return (
    <DropDownList
      dataItemKey="ID"
      textField="Name"
      data={ensureArray(views)}
      value={find(views, { ID: ViewID }) ?? unsavedView}
      onChange={(e) =>
        onChange?.(e.target.value.ID === unsavedView.ID ? undefined : e.target.value.ID)
      }
    />
  );
};
