import { useApolloClient } from '@apollo/client';
import {
  Button,
  DropDownButton,
  DropDownButtonItemClickEvent,
} from '@progress/kendo-react-buttons';
import {
  AppBar,
  AppBarProps,
  AppBarSection,
  AppBarSpacer,
  Avatar as _Avatar,
} from '@progress/kendo-react-layout';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { useAuth } from '../../auth';
import { avatarInitials } from '../../system/util';

enum AccountMenuItem {
  Login = 'login',
  RefreshCache = 'refresh-cache',
}

const Divider = styled.span`
  border-top: 1px solid #e0e0e0;
  width: 100%;
`;

const Avatar = styled(_Avatar)`
  & .k-avatar-text {
    margin-top: 2px;
  }
`;

export const AppNavbar = ({
  showSidebarMenu,
  onSidebarOpen,
  leftSection,
  ...props
}: AppBarProps & {
  leftSection?: ReactNode;
  showSidebarMenu: boolean;
  onSidebarOpen: VoidFunction;
}) => {
  const client = useApolloClient();
  const { user, logout } = useAuth();

  const handleItemClick = ({ item }: DropDownButtonItemClickEvent) =>
    item.id === AccountMenuItem.Login
      ? logout()
      : item.id === AccountMenuItem.RefreshCache
      ? client.resetStore()
      : undefined;

  return (
    <>
      <AppBar {...props}>
        {showSidebarMenu && (
          <AppBarSection>
            <Button fillMode="flat" onClick={() => onSidebarOpen()}>
              <span className="k-icon k-i-menu" />
            </Button>
          </AppBarSection>
        )}

        {leftSection && <AppBarSection>{leftSection}</AppBarSection>}

        <AppBarSpacer />

        <AppBarSection>
          <DropDownButton
            fillMode="flat"
            rounded="full"
            popupSettings={{ animate: false }}
            items={
              user.name
                ? [
                    { text: user.name, disabled: true },
                    { text: user.email, disabled: true },
                    <Divider />,
                    { id: AccountMenuItem.RefreshCache, text: 'Refresh cache' },
                    <Divider />,
                    { id: AccountMenuItem.Login, text: 'Sign out' },
                  ]
                : []
            }
            onItemClick={handleItemClick}
            title={user.name ? user.name : 'Account'}
            text={
              <Avatar type="text" rounded="full">
                {user.name ? avatarInitials(user.name) : <span className="k-icon k-i-user" />}
              </Avatar>
            }
          ></DropDownButton>
        </AppBarSection>
      </AppBar>
    </>
  );
};
