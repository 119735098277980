import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { useModalControl } from '../../hooks/useModalControl';
import { LoadingButton, LoadingButtonProps } from '../atoms';

type ConfirmButontProps = LoadingButtonProps & {
  confirmTitle?: string;
  confirmMessage?: string;
};

export const ConfirmButton = ({
  confirmTitle,
  confirmMessage,
  onClick,
  ...props
}: ConfirmButontProps) => {
  const [showConfirm, hideConfim, confirmOpen] = useModalControl();
  const handleConfirm: LoadingButtonProps['onClick'] = (...args) => {
    hideConfim();
    onClick?.(...args);
  };

  return (
    <>
      <LoadingButton onClick={showConfirm} {...props} />
      {confirmOpen && (
        <Dialog title={confirmTitle ?? 'Confirmation'} onClose={hideConfim}>
          {confirmMessage ?? 'Are you sure you want to continue?'}
          <DialogActionsBar>
            <Button onClick={hideConfim}>Cancel</Button>
            <Button onClick={handleConfirm}>Confirm</Button>
          </DialogActionsBar>
        </Dialog>
      )}
    </>
  );
};
