import { DateTime } from 'luxon';
import { FC } from 'react';
import { EntityField } from '../../api';
import { formatCurrency } from '../../system/util';

type EntityFieldValueProps = {
  EntityField: Pick<EntityField, 'Type'>;
  value?: unknown;
};

const PLACEHOLDER_STRING = '-';

enum EntityType {
  MONEY = 'money',
  INT = 'int',
  DECIMAL = 'decimal',
  DATETIMEOFFSET = 'datetimeoffset',
  SMALLINT = 'smallint',
  UNIQUEIDENTIFIER = 'uniqueidentifier',
  TINYINT = 'tinyint',
  NCHAR = 'nchar',
  CHAR = 'char',
  BIGINT = 'bigint',
  NVARCHAR = 'nvarchar',
  BIT = 'bit',
}

type FieldComponent = FC<{ value: NonNullable<unknown> }>;

const DatetimeoffsetValue: FieldComponent = ({ value }: { value: unknown }) => {
  const datetimeValue = DateTime.fromMillis(Number(value));
  return (
    <>
      {!value || !datetimeValue.isValid
        ? PLACEHOLDER_STRING
        : DateTime.fromMillis(value as number).toLocaleString(DateTime.DATETIME_SHORT)}
    </>
  );
};

const MoneyValue: FieldComponent = ({ value }: { value: unknown }) => (
  <>{formatCurrency(Number(value))}</>
);

const StringValue: FieldComponent = ({ value }: { value: unknown }) => <>{String(value)}</>;

export const EntityFieldValue = (props: EntityFieldValueProps) => {
  const { Type } = props.EntityField;

  const Component: FieldComponent =
    Type === EntityType.DATETIMEOFFSET
      ? DatetimeoffsetValue
      : Type === EntityType.MONEY
      ? MoneyValue
      : StringValue;

  return props.value === null || props.value === undefined ? (
    <>{PLACEHOLDER_STRING}</>
  ) : (
    <Component value={props.value} />
  );
};
