import { useSingleWorkflowRunQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { WorkflowRun } from '../api';
  
  export const WorkflowRunPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Workflow Runs"
        queryHook={useSingleWorkflowRunQuery}
        Details={<WorkflowRunDetails />}
      />
    );
  };

  export const WorkflowRunDetails = () => {
    const [workflowrun] = useSingleEntity<WorkflowRun>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Workflow Runs" EntityField="ID" />} value={workflowrun?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Workflow Runs" EntityField="WorkflowID" />} value={<EntityLink Entity="Workflow Runs" EntityField="WorkflowID">{workflowrun?.WorkflowID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Workflow Runs" EntityField="ExternalSystemRecordID" />} value={workflowrun?.ExternalSystemRecordID} />
        <Field label={<EntityFieldDisplayName Entity="Workflow Runs" EntityField="StartedAt" />} value={workflowrun?.StartedAt &&
            DateTime.fromMillis(workflowrun.StartedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Workflow Runs" EntityField="EndedAt" />} value={workflowrun?.EndedAt &&
            DateTime.fromMillis(workflowrun.EndedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Workflow Runs" EntityField="Status" />} value={workflowrun?.Status} />
        <Field label={<EntityFieldDisplayName Entity="Workflow Runs" EntityField="Results" />} value={<BodyText>{workflowrun?.Results}</BodyText>} />
        <Field label={<EntityFieldDisplayName Entity="Workflow Runs" EntityField="Workflow" />} value={workflowrun?.Workflow} />
        <Field label={<EntityFieldDisplayName Entity="Workflow Runs" EntityField="WorkflowEngine" />} value={workflowrun?.WorkflowEngine} />
      </div>
    );
  };
