import { useSingleUserApplicationEntityQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { UserApplicationEntity } from '../api';
  
  export const UserApplicationEntityPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="User Application Entities"
        queryHook={useSingleUserApplicationEntityQuery}
        Details={<UserApplicationEntityDetails />}
      />
    );
  };

  export const UserApplicationEntityDetails = () => {
    const [userapplicationentity] = useSingleEntity<UserApplicationEntity>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="User Application Entities" EntityField="ID" />} value={userapplicationentity?.ID} />
        <Field label={<EntityFieldDisplayName Entity="User Application Entities" EntityField="UserApplicationID" />} value={<EntityLink Entity="User Application Entities" EntityField="UserApplicationID">{userapplicationentity?.UserApplicationID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="User Application Entities" EntityField="EntityID" />} value={<EntityLink Entity="User Application Entities" EntityField="EntityID">{userapplicationentity?.EntityID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="User Application Entities" EntityField="Sequence" />} value={userapplicationentity?.Sequence} />
        <Field label={<EntityFieldDisplayName Entity="User Application Entities" EntityField="Application" />} value={userapplicationentity?.Application} />
        <Field label={<EntityFieldDisplayName Entity="User Application Entities" EntityField="User" />} value={userapplicationentity?.User} />
        <Field label={<EntityFieldDisplayName Entity="User Application Entities" EntityField="Entity" />} value={userapplicationentity?.Entity} />
      </div>
    );
  };
