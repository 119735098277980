import { Children, PropsWithChildren } from 'react';
import styled from 'styled-components';

type StackProps = PropsWithChildren<{
  spacing?: number;
  direction?: 'row' | 'column';
  center?: boolean;
}>;

const StackItem = styled.div<{
  first: boolean;
  spacing: number;
}>`
  margin-top: ${({ first, spacing, theme }) => (first ? '0' : theme.spacing(spacing))};
`;

const StackContainer = styled.div<{ direction: 'row' | 'column'; center: boolean }>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
  align-items: ${({ center }) => (center ? 'center' : 'flex-start')};
`;

const StackBase = ({ children, spacing = 1, direction = 'column', center = false }: StackProps) => (
  <StackContainer {...{ direction, center }}>
    {Children.map(children, (child, index) => (
      <StackItem first={index === 0} spacing={spacing}>
        {child}
      </StackItem>
    ))}
  </StackContainer>
);

export const Stack = styled(StackBase)`
  display: flex;
`;
