import { useSingleAIActionQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { AIAction } from '../api';
  
  export const AIActionPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="AI Actions"
        queryHook={useSingleAIActionQuery}
        Details={<AIActionDetails />}
      />
    );
  };

  export const AIActionDetails = () => {
    const [aiaction] = useSingleEntity<AIAction>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="AI Actions" EntityField="ID" />} value={aiaction?.ID} />
        <Field label={<EntityFieldDisplayName Entity="AI Actions" EntityField="Name" />} value={aiaction?.Name} />
        <Field label={<EntityFieldDisplayName Entity="AI Actions" EntityField="Description" />} value={<BodyText>{aiaction?.Description}</BodyText>} />
        <Field label={<EntityFieldDisplayName Entity="AI Actions" EntityField="DefaultModelID" />} value={<EntityLink Entity="AI Actions" EntityField="DefaultModelID">{aiaction?.DefaultModelID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="AI Actions" EntityField="DefaultPrompt" />} value={<BodyText>{aiaction?.DefaultPrompt}</BodyText>} />
        <Field label={<EntityFieldDisplayName Entity="AI Actions" EntityField="IsActive" />} value={aiaction?.IsActive} />
        <Field label={<EntityFieldDisplayName Entity="AI Actions" EntityField="CreatedAt" />} value={aiaction?.CreatedAt &&
            DateTime.fromMillis(aiaction.CreatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="AI Actions" EntityField="UpdatedAt" />} value={aiaction?.UpdatedAt &&
            DateTime.fromMillis(aiaction.UpdatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="AI Actions" EntityField="DefaultModel" />} value={aiaction?.DefaultModel} />
      </div>
    );
  };
