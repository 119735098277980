import { useSingleUserApplicationQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { UserApplication } from '../api';
  
  export const UserApplicationPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="User Applications"
        queryHook={useSingleUserApplicationQuery}
        Details={<UserApplicationDetails />}
      />
    );
  };

  export const UserApplicationDetails = () => {
    const [userapplication] = useSingleEntity<UserApplication>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="User Applications" EntityField="ID" />} value={userapplication?.ID} />
        <Field label={<EntityFieldDisplayName Entity="User Applications" EntityField="UserID" />} value={<EntityLink Entity="User Applications" EntityField="UserID">{userapplication?.UserID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="User Applications" EntityField="ApplicationID" />} value={<EntityLink Entity="User Applications" EntityField="ApplicationID">{userapplication?.ApplicationID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="User Applications" EntityField="Sequence" />} value={userapplication?.Sequence} />
        <Field label={<EntityFieldDisplayName Entity="User Applications" EntityField="IsActive" />} value={userapplication?.IsActive} />
        <Field label={<EntityFieldDisplayName Entity="User Applications" EntityField="User" />} value={userapplication?.User} />
        <Field label={<EntityFieldDisplayName Entity="User Applications" EntityField="Application" />} value={userapplication?.Application} />
      </div>
    );
  };
