import { Card as _Card } from '@progress/kendo-react-layout';
import { max, sortBy } from 'lodash';
import styled from 'styled-components';
import { Entity } from '../../context/EntitiesProvider';
import { FieldValuePair, LoadingPanel } from '../atoms';
import { EntityFieldValue } from '../molecules';

const Column = styled.div`
  background-color: ${({ theme }) => theme.colors.paper};

  padding: ${({ theme }) => theme.spacing(2)};
`;

const Card = styled(_Card)`
  padding: ${({ theme }) => theme.spacing(1)};

  & .k-card-title {
    font-size: 1em;
  }

  margin-bottom: ${({ theme }) => theme.spacing(1)};

  :last-of-type {
    margin-bottom: 0;
  }
`;

type DetailColumnProps = Pick<Entity, 'EntityFields' | 'Name'> & {
  EntityData?: Record<string, unknown>;
};

const HEADER_WIDTH = 250;
const DEFAULT_COLUMN_WIDTH = 250;
export const DetailColumn = ({ EntityFields, EntityData = {} }: DetailColumnProps) => {
  const width =
    max(EntityFields.map(({ DefaultColumnWidth }) => DefaultColumnWidth ?? DEFAULT_COLUMN_WIDTH)) ??
    DEFAULT_COLUMN_WIDTH + HEADER_WIDTH;
  const fields = EntityFields.map((entityField) => ({
    key: entityField.Name,
    order: entityField.Sequence,
    header: `${entityField.DisplayName ?? entityField.Name}`,
    body: <EntityFieldValue EntityField={entityField} value={EntityData?.[entityField.Name]} />,
  }));

  return (
    <Column>
      <LoadingPanel />
      {sortBy(fields, 'order').map(({ key, header, body }) => (
        <Card key={key} style={{ minWidth: '80%', width }}>
          <FieldValuePair label={header} value={body} direction="row" />
        </Card>
      ))}
    </Column>
  );
};
