import { useSingleContactCompanyIntegrationQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { ContactCompanyIntegration } from '../api';
  
  export const ContactCompanyIntegrationPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Contact Company Integrations"
        queryHook={useSingleContactCompanyIntegrationQuery}
        Details={<ContactCompanyIntegrationDetails />}
      />
    );
  };

  export const ContactCompanyIntegrationDetails = () => {
    const [contactcompanyintegration] = useSingleEntity<ContactCompanyIntegration>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Contact Company Integrations" EntityField="ID" />} value={contactcompanyintegration?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Contact Company Integrations" EntityField="ContactID" />} value={<EntityLink Entity="Contact Company Integrations" EntityField="ContactID">{contactcompanyintegration?.ContactID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Contact Company Integrations" EntityField="CompanyIntegrationID" />} value={<EntityLink Entity="Contact Company Integrations" EntityField="CompanyIntegrationID">{contactcompanyintegration?.CompanyIntegrationID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Contact Company Integrations" EntityField="ExternalSystemRecordID" />} value={contactcompanyintegration?.ExternalSystemRecordID} />
        <Field label={<EntityFieldDisplayName Entity="Contact Company Integrations" EntityField="CreatedAt" />} value={contactcompanyintegration?.CreatedAt &&
            DateTime.fromMillis(contactcompanyintegration.CreatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Contact Company Integrations" EntityField="UpdatedAt" />} value={contactcompanyintegration?.UpdatedAt &&
            DateTime.fromMillis(contactcompanyintegration.UpdatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Contact Company Integrations" EntityField="CompanyID" />} value={contactcompanyintegration?.CompanyID} />
        <Field label={<EntityFieldDisplayName Entity="Contact Company Integrations" EntityField="CompanyName" />} value={contactcompanyintegration?.CompanyName} />
      </div>
    );
  };
