import { ApolloClient, ApolloProvider, NormalizedCacheObject } from '@apollo/client';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { useAuth } from '../auth';
import { useNotification } from '../hooks/useNotification';
import { createClient } from './createClient';

export const Provider = ({ children }: { children: ReactNode }) => {
  const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();

  const { sendNotification } = useNotification();
  const onErrorNotification = useCallback(
    (error: string) => sendNotification(error, 'error'),
    [sendNotification]
  );

  const { token, refreshToken } = useAuth();
  useEffect(() => {
    const initClient = async (newToken: string) => {
      const newClient = await createClient({
        onErrorNotification,
        token: newToken,
        onRefreshError: refreshToken,
      });
      setClient(newClient);

      // **************************************************************/
      // AN - 5 MAR 2023 - TEMP CODE - remove this....
      // using this for a quick graphql direct calling test - remove this
      // // COMMENTED OUT FOR NOW setupGraphQLClient(newClient);
      // **************************************************************/
    };

    initClient(token);
  }, [onErrorNotification, refreshToken, token]);

  useEffect(() => {
    if (!token) {
      client?.clearStore();
    }
  }, [client, token]);

  return client ? <ApolloProvider client={client}>{children}</ApolloProvider> : <></>;
};
