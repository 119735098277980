import { Button } from '@progress/kendo-react-buttons';
import { Typography } from '@progress/kendo-react-common';
import { Card as _Card, CardBody, GridLayout, GridLayoutItem } from '@progress/kendo-react-layout';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { extractDomainFromString } from '../../helpers/strings';
import { ExternalUrlTag } from '../atoms/ExternalUrlTag';

type SocialCardProps = {
  Domain?: string;
  Website?: string;
  LeadershipPageURL?: string;
  LinkedIn?: string;
  Facebook?: string;
};

const Card = styled(_Card)`
  padding: ${({ theme }) => theme.spacing(2)};
  min-height: 230px;
`;

const DomainTypography = styled(Typography.h6)`
  margin-bottom: ${({ theme }) => theme.spacing(1)};
  text-transform: uppercase;
`;

export const SocialCard = ({
  Domain,
  LinkedIn,
  Facebook,
  Website,
  LeadershipPageURL,
}: SocialCardProps) => {
  const navigate = useNavigate();
  return (
    <Card>
      <CardBody>
        <GridLayout cols={[{ width: '50%' }, { width: '50%' }]} gap={{ cols: 2, rows: 5 }}>
          <GridLayoutItem colSpan={2}>
            <DomainTypography>{Domain}</DomainTypography>
          </GridLayoutItem>

          {Website && extractDomainFromString(Website) !== Domain && (
            <GridLayoutItem colSpan={2}>
              <ExternalUrlTag url={Website} />
            </GridLayoutItem>
          )}

          {LeadershipPageURL && (
            <GridLayoutItem colSpan={2}>
              <ExternalUrlTag url={Website} />
            </GridLayoutItem>
          )}

          <GridLayoutItem>
            {LinkedIn && <Button icon="linkedin-box" onClick={() => navigate(LinkedIn)} />}
            {Facebook && <Button icon="facebook-box" onClick={() => navigate(Facebook)} />}
          </GridLayoutItem>
        </GridLayout>
      </CardBody>
    </Card>
  );
};
