import { defaultDataIdFromObject, InMemoryCache } from '@apollo/client';
import { LocalStorageWrapper, persistCache } from 'apollo3-cache-persist';
import { StrictTypedTypePolicies } from '../api';

const typePolicies: StrictTypedTypePolicies = {
  Query: {
    fields: {
      UserFavoritesByUserID: { keyArgs: false },
      UserViewsByUserID: { keyArgs: false },
    },
  },
};

let cache: InMemoryCache;

export const createCache = async () => {
  if (cache) return cache;

  cache = new InMemoryCache({
    dataIdFromObject: (responseObject) =>
      responseObject.__typename && responseObject.ID
        ? `${responseObject.__typename}:${responseObject.ID}`
        : defaultDataIdFromObject(responseObject),
    typePolicies,
  });

  await persistCache({
    cache,
    storage: new LocalStorageWrapper(window.localStorage),
  });

  return cache;
};
