import { useSingleAIModelActionQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { AIModelAction } from '../api';
  
  export const AIModelActionPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="AI Model Actions"
        queryHook={useSingleAIModelActionQuery}
        Details={<AIModelActionDetails />}
      />
    );
  };

  export const AIModelActionDetails = () => {
    const [aimodelaction] = useSingleEntity<AIModelAction>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="AI Model Actions" EntityField="ID" />} value={aimodelaction?.ID} />
        <Field label={<EntityFieldDisplayName Entity="AI Model Actions" EntityField="AIModelID" />} value={<EntityLink Entity="AI Model Actions" EntityField="AIModelID">{aimodelaction?.AIModelID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="AI Model Actions" EntityField="AIActionID" />} value={<EntityLink Entity="AI Model Actions" EntityField="AIActionID">{aimodelaction?.AIActionID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="AI Model Actions" EntityField="IsActive" />} value={aimodelaction?.IsActive} />
        <Field label={<EntityFieldDisplayName Entity="AI Model Actions" EntityField="CreatedAt" />} value={aimodelaction?.CreatedAt &&
            DateTime.fromMillis(aimodelaction.CreatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="AI Model Actions" EntityField="UpdatedAt" />} value={aimodelaction?.UpdatedAt &&
            DateTime.fromMillis(aimodelaction.UpdatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="AI Model Actions" EntityField="AIModel" />} value={aimodelaction?.AIModel} />
        <Field label={<EntityFieldDisplayName Entity="AI Model Actions" EntityField="AIAction" />} value={aimodelaction?.AIAction} />
      </div>
    );
  };
