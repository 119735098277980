import { useSingleRoleQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { Role } from '../api';
  
  export const RolePage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Roles"
        queryHook={useSingleRoleQuery}
        Details={<RoleDetails />}
      />
    );
  };

  export const RoleDetails = () => {
    const [role] = useSingleEntity<Role>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Roles" EntityField="ID" />} value={role?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Roles" EntityField="Name" />} value={role?.Name} />
        <Field label={<EntityFieldDisplayName Entity="Roles" EntityField="Description" />} value={role?.Description} />
        <Field label={<EntityFieldDisplayName Entity="Roles" EntityField="AzureID" />} value={role?.AzureID} />
        <Field label={<EntityFieldDisplayName Entity="Roles" EntityField="SQLName" />} value={role?.SQLName} />
        <Field label={<EntityFieldDisplayName Entity="Roles" EntityField="CreatedAt" />} value={role?.CreatedAt &&
            DateTime.fromMillis(role.CreatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Roles" EntityField="UpdatedAt" />} value={role?.UpdatedAt &&
            DateTime.fromMillis(role.UpdatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
      </div>
    );
  };
