import { useSingleContactAccountQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { ContactAccount } from '../api';
  
  export const ContactAccountPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Contact Accounts"
        queryHook={useSingleContactAccountQuery}
        Details={<ContactAccountDetails />}
      />
    );
  };

  export const ContactAccountDetails = () => {
    const [contactaccount] = useSingleEntity<ContactAccount>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Contact Accounts" EntityField="ID" />} value={contactaccount?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Contact Accounts" EntityField="ContactID" />} value={<EntityLink Entity="Contact Accounts" EntityField="ContactID">{contactaccount?.ContactID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Contact Accounts" EntityField="AccountID" />} value={<EntityLink Entity="Contact Accounts" EntityField="AccountID">{contactaccount?.AccountID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Contact Accounts" EntityField="Sequence" />} value={contactaccount?.Sequence} />
        <Field label={<EntityFieldDisplayName Entity="Contact Accounts" EntityField="StartedAt" />} value={contactaccount?.StartedAt &&
            DateTime.fromMillis(contactaccount.StartedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Contact Accounts" EntityField="EndedAt" />} value={contactaccount?.EndedAt &&
            DateTime.fromMillis(contactaccount.EndedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Contact Accounts" EntityField="IsPrimary" />} value={contactaccount?.IsPrimary} />
        <Field label={<EntityFieldDisplayName Entity="Contact Accounts" EntityField="Contact" />} value={contactaccount?.Contact} />
        <Field label={<EntityFieldDisplayName Entity="Contact Accounts" EntityField="Account" />} value={contactaccount?.Account} />
      </div>
    );
  };
