import { State } from '@progress/kendo-data-query';
import { useEffect, useMemo } from 'react';
import { useSingleUserViewQuery } from '../api';
import { ColumnSettings } from '../components';
import { parseJSON } from '../system/util';

export type ViewGridState = {
  dataState: State;
  columnSettings: Array<ColumnSettings>;
};

export const useView = ({
  ViewID,
  onViewGridState,
}: {
  ViewID?: number;
  onViewGridState?: (view: ViewGridState) => void;
}) => {
  const usingView = ViewID !== undefined;

  const { data: viewData } = useSingleUserViewQuery({
    variables: { ID: ViewID ?? NaN },
    skip: !isFinite(ViewID ?? NaN),
  });
  const viewGridState = useMemo<ViewGridState | undefined>(
    () => (viewData?.UserView?.GridState ? parseJSON(viewData?.UserView?.GridState) : undefined),
    [viewData]
  );

  const missingViewData = usingView && !viewData?.UserView;

  useEffect(() => {
    viewGridState && onViewGridState?.(viewGridState);
  }, [onViewGridState, viewGridState]);

  return { usingView, UserView: viewData?.UserView, missingViewData };
};
