import { DocumentNode } from '@apollo/client/core';
import {
  BooleanFilter,
  FieldSettings,
  NumericFilter,
  Operators,
  TextFilter,
} from '@progress/kendo-react-data-tools';
import { ExcelExportColumnProps } from '@progress/kendo-react-excel-export';
import { GridColumnProps } from '@progress/kendo-react-grid';
import { Kind } from 'graphql';
import { isEqual } from 'lodash';
import { ComponentType, isValidElement, lazy, ReactNode } from 'react';
import { EntityField } from '../api';
import { ResolverMap, WithProps } from './types';

export const breakpoints = { mobile: 0, tablet: 768, desktop: 1280 };
export const emptyArray: [] = [];
export const emptyObject = {};
export const emptyFilter = { logic: 'and' as const, filters: [] };

const retry = (
  fn: () => Promise<{ default: ComponentType<unknown> }>,
  retriesLeft = 2,
  interval = 1500
) => {
  return new Promise<{ default: ComponentType<unknown> }>((resolve, reject) => {
    fn()
      .then((all) => resolve(all))
      .catch((error) => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            reject(error);
            return;
          }
          return retry(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
};

export const lazyload = (fn: () => Promise<{ default: ComponentType<unknown> }>) =>
  lazy(() => retry(fn));

export const skipProps = (...props: string[]) => ({
  shouldForwardProp: (prop: string) => !props.includes(prop),
});

export const ensureArray = <T = unknown[] | null | undefined>(arr?: T) =>
  Array.isArray(arr) && arr.length > 0 ? arr : (emptyArray as unknown as NonNullable<T>);

export const safeRound = (arg: number, digits = 2) =>
  Math.round(arg * Math.pow(10, digits)) / Math.pow(10, digits);

export const formatPercent = (decimal: number) => `${decimal * 10}%`;

export const formatCurrency = (
  rawAmount?: number,
  options?: {
    showDollarSign?: boolean;
    hideZeroCents?: boolean;
    negativeStyle?: 'default' | 'cr' | 'parens';
    hideZeroAmount?: boolean;
  }
): string => {
  const {
    showDollarSign = true,
    hideZeroCents = false,
    negativeStyle = 'default',
    hideZeroAmount = false,
  } = options ?? {};
  const amount = rawAmount ? (safeRound(rawAmount) === 0 ? 0 : safeRound(rawAmount)) : 0;

  const prefix = negativeStyle === 'cr' && amount < 0 ? 'CR' : '';
  const diplayedAmount = prefix && amount < 0 ? -amount : amount;

  const formattedAmount = Intl.NumberFormat('en-CA', {
    ...(showDollarSign ? { style: 'currency', currency: 'CAD' } : {}),
    ...(negativeStyle === 'parens' ? { currencySign: 'accounting' } : {}),
    ...(hideZeroCents && Math.floor(diplayedAmount) === diplayedAmount
      ? { maximumFractionDigits: 0 }
      : {}),
    ...(!showDollarSign ? { maximumFractionDigits: 2, minimumFractionDigits: 2 } : {}),
  }).format(diplayedAmount);

  const display = amount === 0 && hideZeroAmount ? '' : `${prefix}${formattedAmount}`;

  return display;
};

export const avatarInitials = (name = '') =>
  name
    .replace(/[^\w\s]/gi, '')
    .split(/[ ]+/)
    .filter((_w, i, arr) => i === 0 || i === arr.length - 1)
    .map((word) => word[0])
    .join('')
    .toUpperCase();

export const ifDifferent =
  <T = unknown>(newValue: T) =>
  (prev: T) =>
    isEqual(prev, newValue) ? prev : newValue;

export const pascalCaseToSpaced = (str: string) => str.replace(/([A-Z])/g, ' $1').trimStart();

export const toKendoFieldType = (entityFieldType: string) =>
  ['int', 'money', 'decimal', 'smallint', 'bigint'].includes(entityFieldType)
    ? 'numeric'
    : ['nchar', 'char', 'nvarchar'].includes(entityFieldType)
    ? 'text'
    : ['tinyint', 'bit'].includes(entityFieldType)
    ? 'boolean'
    : ['datetimeoffset'].includes(entityFieldType)
    ? 'date'
    : undefined;

export const getFilterTypeAndOperators = (kendoFieldType = '') => {
  return kendoFieldType === 'numeric'
    ? { filter: NumericFilter, operators: Operators.numeric }
    : kendoFieldType === 'text'
    ? { filter: TextFilter, operators: Operators.text }
    : kendoFieldType === 'boolean'
    ? { filter: BooleanFilter, operators: Operators.boolean }
    : kendoFieldType === 'date'
    ? // TODO: [CA] This should use the DateFilter component, but since our dates are millis, it chokes
      { filter: NumericFilter, operators: Operators.numeric }
    : { filter: TextFilter, operators: Operators.boolean };
};

export const toKendoColumn = (
  {
    DefaultColumnWidth,
    width,
    DisplayName,
    ID,
    Name,
    Type,
    orderIndex,
  }: Pick<EntityField, 'DefaultColumnWidth' | 'DisplayName' | 'ID' | 'Name' | 'Type'> & {
    width?: string | number;
    orderIndex?: number;
  },
  i: number
): GridColumnProps => ({
  id: String(ID),
  title: DisplayName ?? Name,
  filter: toKendoFieldType(Type),
  field: Name,
  width: width ?? DefaultColumnWidth,
  orderIndex: orderIndex ?? i,
});

export const toExcelColumn = ({
  DefaultColumnWidth,
  DisplayName,
  Name,
}: Pick<EntityField, 'DefaultColumnWidth' | 'DisplayName' | 'Name'>): ExcelExportColumnProps => ({
  width: DefaultColumnWidth,
  title: DisplayName ?? Name,
  field: Name,
});

export const toKendoFilterField = ({
  DisplayName,
  Name,
  Type,
}: Pick<EntityField, 'DisplayName' | 'Name' | 'Type'>): FieldSettings => ({
  name: Name,
  label: DisplayName ?? Name,
  ...getFilterTypeAndOperators(toKendoFieldType(Type)),
});

export const parseJSON = (jsonString = '{}') => {
  try {
    return JSON.parse(jsonString);
  } catch (e) {
    return {};
  }
};

export const shallowOmit = <
  TRecord extends Record<string, unknown> | undefined,
  TKey extends keyof NonNullable<TRecord>
>(
  obj: TRecord,
  keys: TKey[]
) => {
  const clone = { ...obj } as NonNullable<TRecord>;
  for (const key of keys) {
    delete (clone as NonNullable<TRecord>)[key];
  }

  return clone as Omit<typeof clone, TKey>;
};

export const queryField = (doc: DocumentNode) =>
  doc.definitions[0].kind === Kind.OPERATION_DEFINITION &&
  doc.definitions[0].selectionSet.kind === Kind.SELECTION_SET &&
  doc.definitions[0].selectionSet.selections[0].kind === Kind.FIELD
    ? doc.definitions[0].selectionSet.selections[0].name.value
    : undefined;

export const nodeToText = (node: ReactNode, resolvers?: ResolverMap): string => {
  const props: { children?: ReactNode } = (node as WithProps).props
    ? (node as WithProps).props
    : {};
  const [nodeType, nodeProps] = isValidElement(node) ? [node.type, node.props] : [null, null];
  if (nodeType && resolvers?.has(nodeType)) {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- manually checked above
    const resolver = resolvers.get(nodeType)!;
    return resolver(nodeProps);
  }

  return typeof node === 'string' || typeof node === 'number' || typeof node === 'boolean'
    ? node.toString()
    : Array.isArray(node)
    ? node.map((entry) => nodeToText(entry, resolvers)).join('')
    : !node || !props || !props.children
    ? ''
    : nodeToText(props.children, resolvers);
};
