import { useSingleDealTypeQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { DealType } from '../api';
  
  export const DealTypePage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Deal Types"
        queryHook={useSingleDealTypeQuery}
        Details={<DealTypeDetails />}
      />
    );
  };

  export const DealTypeDetails = () => {
    const [dealtype] = useSingleEntity<DealType>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Deal Types" EntityField="ID" />} value={dealtype?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Deal Types" EntityField="Name" />} value={dealtype?.Name} />
        <Field label={<EntityFieldDisplayName Entity="Deal Types" EntityField="Description" />} value={dealtype?.Description} />
        <Field label={<EntityFieldDisplayName Entity="Deal Types" EntityField="CreatedAt" />} value={dealtype?.CreatedAt &&
            DateTime.fromMillis(dealtype.CreatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Deal Types" EntityField="UpdatedAt" />} value={dealtype?.UpdatedAt &&
            DateTime.fromMillis(dealtype.UpdatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Deal Types" EntityField="DisplayName" />} value={dealtype?.DisplayName} />
      </div>
    );
  };
