import { useSingleTeamQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { Team } from '../api';
  
  export const TeamPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Latest Metadata Updates"
        queryHook={useSingleTeamQuery}
        Details={<TeamDetails />}
      />
    );
  };

  export const TeamDetails = () => {
    const [team] = useSingleEntity<Team>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Latest Metadata Updates" EntityField="ID" />} value={team?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Latest Metadata Updates" EntityField="Type" />} value={team?.Type} />
        <Field label={<EntityFieldDisplayName Entity="Latest Metadata Updates" EntityField="UpdatedAt" />} value={team?.UpdatedAt &&
            DateTime.fromMillis(team.UpdatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
      </div>
    );
  };
