import { useSingleTimeEntryQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { TimeEntry } from '../api';
  
  export const TimeEntryPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Time Entries"
        queryHook={useSingleTimeEntryQuery}
        Details={<TimeEntryDetails />}
      />
    );
  };

  export const TimeEntryDetails = () => {
    const [timeentry] = useSingleEntity<TimeEntry>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Time Entries" EntityField="ID" />} value={timeentry?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Time Entries" EntityField="Date" />} value={timeentry?.Date &&
            DateTime.fromMillis(timeentry.Date).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Time Entries" EntityField="Effort" />} value={timeentry?.Effort} />
        <Field label={<EntityFieldDisplayName Entity="Time Entries" EntityField="IsDeleted" />} value={timeentry?.IsDeleted} />
        <Field label={<EntityFieldDisplayName Entity="Time Entries" EntityField="CreatedAt" />} value={timeentry?.CreatedAt &&
            DateTime.fromMillis(timeentry.CreatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Time Entries" EntityField="UpdatedAt" />} value={timeentry?.UpdatedAt &&
            DateTime.fromMillis(timeentry.UpdatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Time Entries" EntityField="EmployeeID" />} value={<EntityLink Entity="Time Entries" EntityField="EmployeeID">{timeentry?.EmployeeID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Time Entries" EntityField="TaskID" />} value={<EntityLink Entity="Time Entries" EntityField="TaskID">{timeentry?.TaskID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Time Entries" EntityField="Comment" />} value={<BodyText>{timeentry?.Comment}</BodyText>} />
        <Field label={<EntityFieldDisplayName Entity="Time Entries" EntityField="ExternalSystemRecordID" />} value={timeentry?.ExternalSystemRecordID} />
        <Field label={<EntityFieldDisplayName Entity="Time Entries" EntityField="IsInvoiced" />} value={timeentry?.IsInvoiced} />
        <Field label={<EntityFieldDisplayName Entity="Time Entries" EntityField="IsLocked" />} value={timeentry?.IsLocked} />
        <Field label={<EntityFieldDisplayName Entity="Time Entries" EntityField="Employee" />} value={timeentry?.Employee} />
        <Field label={<EntityFieldDisplayName Entity="Time Entries" EntityField="Task" />} value={timeentry?.Task} />
      </div>
    );
  };
