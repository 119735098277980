import { useSingle_990FullQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { _990Full } from '../api';
  
  export const _990FullPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="990 Full"
        queryHook={useSingle_990FullQuery}
        Details={<_990FullDetails />}
      />
    );
  };

  export const _990FullDetails = () => {
    const [_990full] = useSingleEntity<_990Full>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="ID" />} value={_990full?.ID} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="EIN" />} value={_990full?.EIN} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="Name" />} value={_990full?.Name} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="ICO" />} value={_990full?.ICO} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="Street" />} value={_990full?.Street} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="City" />} value={_990full?.City} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="State" />} value={_990full?.State} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="Zip" />} value={_990full?.Zip} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="Group" />} value={_990full?.Group} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="Subsection" />} value={_990full?.Subsection} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="Affiliation" />} value={_990full?.Affiliation} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="Classification" />} value={_990full?.Classification} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="Ruling" />} value={_990full?.Ruling} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="Deductibility" />} value={_990full?.Deductibility} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="Foundation" />} value={_990full?.Foundation} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="Activity" />} value={_990full?.Activity} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="Organization" />} value={_990full?.Organization} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="Status" />} value={_990full?.Status} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="TaxPeriod" />} value={_990full?.TaxPeriod} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="TaxYear" />} value={_990full?.TaxYear} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="TaxMonth" />} value={_990full?.TaxMonth} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="AssetCode" />} value={_990full?.AssetCode} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="IncomeCode" />} value={_990full?.IncomeCode} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="FilingRequirementCode" />} value={_990full?.FilingRequirementCode} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="SortName" />} value={_990full?.SortName} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="PoliticalActivities" />} value={_990full?.PoliticalActivities} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="LobbyingActivities" />} value={_990full?.LobbyingActivities} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="FundraisingActivities" />} value={_990full?.FundraisingActivities} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="IsHospital" />} value={_990full?.IsHospital} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="GrantsToOrgs" />} value={_990full?.GrantsToOrgs} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="GrantsToIndividuals" />} value={_990full?.GrantsToIndividuals} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="HasInvestmentIncome" />} value={_990full?.HasInvestmentIncome} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="TotalInvestmentIncome" />} value={_990full?.TotalInvestmentIncome !== undefined
              ? formatCurrency(Math.floor(_990full.TotalInvestmentIncome), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="NumberEmployees" />} value={_990full?.NumberEmployees} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="HasForeignBankAcct" />} value={_990full?.HasForeignBankAcct} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="TotalGrossReceipts" />} value={_990full?.TotalGrossReceipts !== undefined
              ? formatCurrency(Math.floor(_990full.TotalGrossReceipts), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="TotalMemberIncome" />} value={_990full?.TotalMemberIncome !== undefined
              ? formatCurrency(Math.floor(_990full.TotalMemberIncome), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="TotalProgramServiceRevenue" />} value={_990full?.TotalProgramServiceRevenue !== undefined
              ? formatCurrency(Math.floor(_990full.TotalProgramServiceRevenue), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="ProgramServiceRevenueCodeA" />} value={_990full?.ProgramServiceRevenueCodeA} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="ProgramServiceRevenueA" />} value={_990full?.ProgramServiceRevenueA !== undefined
              ? formatCurrency(Math.floor(_990full.ProgramServiceRevenueA), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="ProgramServiceRevenueCodeB" />} value={_990full?.ProgramServiceRevenueCodeB} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="ProgramServiceRevenueB" />} value={_990full?.ProgramServiceRevenueB !== undefined
              ? formatCurrency(Math.floor(_990full.ProgramServiceRevenueB), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="ProgramServiceRevenueCodeC" />} value={_990full?.ProgramServiceRevenueCodeC} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="ProgramServiceRevenueC" />} value={_990full?.ProgramServiceRevenueC !== undefined
              ? formatCurrency(Math.floor(_990full.ProgramServiceRevenueC), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="ProgramServiceRevenueCodeD" />} value={_990full?.ProgramServiceRevenueCodeD} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="ProgramServiceRevenueD" />} value={_990full?.ProgramServiceRevenueD !== undefined
              ? formatCurrency(Math.floor(_990full.ProgramServiceRevenueD), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="ProgramServiceRevenueCodeE" />} value={_990full?.ProgramServiceRevenueCodeE} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="ProgramServiceRevenueE" />} value={_990full?.ProgramServiceRevenueE !== undefined
              ? formatCurrency(Math.floor(_990full.ProgramServiceRevenueE), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="GrossRentsFromRealEstate" />} value={_990full?.GrossRentsFromRealEstate !== undefined
              ? formatCurrency(Math.floor(_990full.GrossRentsFromRealEstate), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="RentalExpenseRealEstate" />} value={_990full?.RentalExpenseRealEstate !== undefined
              ? formatCurrency(Math.floor(_990full.RentalExpenseRealEstate), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="NetRentRealEstate" />} value={_990full?.NetRentRealEstate !== undefined
              ? formatCurrency(Math.floor(_990full.NetRentRealEstate), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="GrossSalesSecurities" />} value={_990full?.GrossSalesSecurities !== undefined
              ? formatCurrency(Math.floor(_990full.GrossSalesSecurities), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="CostBasisSecurities" />} value={_990full?.CostBasisSecurities !== undefined
              ? formatCurrency(Math.floor(_990full.CostBasisSecurities), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="NetGainSecurities" />} value={_990full?.NetGainSecurities !== undefined
              ? formatCurrency(Math.floor(_990full.NetGainSecurities), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="GrossFundraisingReceipts" />} value={_990full?.GrossFundraisingReceipts !== undefined
              ? formatCurrency(Math.floor(_990full.GrossFundraisingReceipts), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="FundraisingExpenses" />} value={_990full?.FundraisingExpenses !== undefined
              ? formatCurrency(Math.floor(_990full.FundraisingExpenses), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="FundraisingNetIncome" />} value={_990full?.FundraisingNetIncome !== undefined
              ? formatCurrency(Math.floor(_990full.FundraisingNetIncome), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="TotalRevenue" />} value={_990full?.TotalRevenue !== undefined
              ? formatCurrency(Math.floor(_990full.TotalRevenue), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="OfficerCompensation" />} value={_990full?.OfficerCompensation !== undefined
              ? formatCurrency(Math.floor(_990full.OfficerCompensation), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="DisqualifiedPersonCompensation" />} value={_990full?.DisqualifiedPersonCompensation !== undefined
              ? formatCurrency(Math.floor(_990full.DisqualifiedPersonCompensation), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="OtherSalaries" />} value={_990full?.OtherSalaries !== undefined
              ? formatCurrency(Math.floor(_990full.OtherSalaries), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="PensionContributions" />} value={_990full?.PensionContributions !== undefined
              ? formatCurrency(Math.floor(_990full.PensionContributions), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="OtherEmployeeBenefits" />} value={_990full?.OtherEmployeeBenefits !== undefined
              ? formatCurrency(Math.floor(_990full.OtherEmployeeBenefits), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="PayrollTaxes" />} value={_990full?.PayrollTaxes !== undefined
              ? formatCurrency(Math.floor(_990full.PayrollTaxes), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="ManagementFees" />} value={_990full?.ManagementFees !== undefined
              ? formatCurrency(Math.floor(_990full.ManagementFees), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="LegalFees" />} value={_990full?.LegalFees !== undefined
              ? formatCurrency(Math.floor(_990full.LegalFees), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="AccountingFees" />} value={_990full?.AccountingFees !== undefined
              ? formatCurrency(Math.floor(_990full.AccountingFees), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="LobbyingFees" />} value={_990full?.LobbyingFees !== undefined
              ? formatCurrency(Math.floor(_990full.LobbyingFees), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="ProfessionalFundraisingFees" />} value={_990full?.ProfessionalFundraisingFees !== undefined
              ? formatCurrency(Math.floor(_990full.ProfessionalFundraisingFees), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="InvestmentManagementFees" />} value={_990full?.InvestmentManagementFees !== undefined
              ? formatCurrency(Math.floor(_990full.InvestmentManagementFees), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="OtherFees" />} value={_990full?.OtherFees !== undefined
              ? formatCurrency(Math.floor(_990full.OtherFees), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="AdvertisingExpense" />} value={_990full?.AdvertisingExpense !== undefined
              ? formatCurrency(Math.floor(_990full.AdvertisingExpense), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="OfficeExpense" />} value={_990full?.OfficeExpense !== undefined
              ? formatCurrency(Math.floor(_990full.OfficeExpense), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="InformationTechnologyExpense" />} value={_990full?.InformationTechnologyExpense !== undefined
              ? formatCurrency(Math.floor(_990full.InformationTechnologyExpense), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="RoyaltyExpense" />} value={_990full?.RoyaltyExpense !== undefined
              ? formatCurrency(Math.floor(_990full.RoyaltyExpense), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="OccupancyExpense" />} value={_990full?.OccupancyExpense !== undefined
              ? formatCurrency(Math.floor(_990full.OccupancyExpense), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="TravelExpense" />} value={_990full?.TravelExpense !== undefined
              ? formatCurrency(Math.floor(_990full.TravelExpense), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="TravelExpensePublicOfficials" />} value={_990full?.TravelExpensePublicOfficials !== undefined
              ? formatCurrency(Math.floor(_990full.TravelExpensePublicOfficials), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="ConferenceExpense" />} value={_990full?.ConferenceExpense !== undefined
              ? formatCurrency(Math.floor(_990full.ConferenceExpense), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="InterestExpense" />} value={_990full?.InterestExpense !== undefined
              ? formatCurrency(Math.floor(_990full.InterestExpense), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="AffiliatePaymentExpense" />} value={_990full?.AffiliatePaymentExpense !== undefined
              ? formatCurrency(Math.floor(_990full.AffiliatePaymentExpense), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="DepreciationExpense" />} value={_990full?.DepreciationExpense !== undefined
              ? formatCurrency(Math.floor(_990full.DepreciationExpense), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="InsuranceExpense" />} value={_990full?.InsuranceExpense !== undefined
              ? formatCurrency(Math.floor(_990full.InsuranceExpense), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="Cash" />} value={_990full?.Cash !== undefined
              ? formatCurrency(Math.floor(_990full.Cash), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="Savings" />} value={_990full?.Savings !== undefined
              ? formatCurrency(Math.floor(_990full.Savings), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="PledgesGrantsReceivable" />} value={_990full?.PledgesGrantsReceivable !== undefined
              ? formatCurrency(Math.floor(_990full.PledgesGrantsReceivable), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="AccountsReceivable" />} value={_990full?.AccountsReceivable !== undefined
              ? formatCurrency(Math.floor(_990full.AccountsReceivable), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="ReceivablesFromOfficers" />} value={_990full?.ReceivablesFromOfficers !== undefined
              ? formatCurrency(Math.floor(_990full.ReceivablesFromOfficers), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="ReceivablesFromDisqualifiedPersons" />} value={_990full?.ReceivablesFromDisqualifiedPersons !== undefined
              ? formatCurrency(Math.floor(_990full.ReceivablesFromDisqualifiedPersons), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="NotesReceivable" />} value={_990full?.NotesReceivable !== undefined
              ? formatCurrency(Math.floor(_990full.NotesReceivable), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="InventoryAssetValue" />} value={_990full?.InventoryAssetValue !== undefined
              ? formatCurrency(Math.floor(_990full.InventoryAssetValue), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="PrepaidExpenseAssetValue" />} value={_990full?.PrepaidExpenseAssetValue !== undefined
              ? formatCurrency(Math.floor(_990full.PrepaidExpenseAssetValue), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="RealEstateAssetValue" />} value={_990full?.RealEstateAssetValue !== undefined
              ? formatCurrency(Math.floor(_990full.RealEstateAssetValue), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="PublicSecuritiesAssetValue" />} value={_990full?.PublicSecuritiesAssetValue !== undefined
              ? formatCurrency(Math.floor(_990full.PublicSecuritiesAssetValue), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="OtherSecuritiesAssetValue" />} value={_990full?.OtherSecuritiesAssetValue !== undefined
              ? formatCurrency(Math.floor(_990full.OtherSecuritiesAssetValue), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="ProgramInvestmentsAssetValue" />} value={_990full?.ProgramInvestmentsAssetValue !== undefined
              ? formatCurrency(Math.floor(_990full.ProgramInvestmentsAssetValue), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="IntangibleAssetValue" />} value={_990full?.IntangibleAssetValue !== undefined
              ? formatCurrency(Math.floor(_990full.IntangibleAssetValue), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="OtherAssets" />} value={_990full?.OtherAssets !== undefined
              ? formatCurrency(Math.floor(_990full.OtherAssets), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="TotalAssets" />} value={_990full?.TotalAssets !== undefined
              ? formatCurrency(Math.floor(_990full.TotalAssets), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="AccountsPayable" />} value={_990full?.AccountsPayable !== undefined
              ? formatCurrency(Math.floor(_990full.AccountsPayable), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="GrantsPayable" />} value={_990full?.GrantsPayable !== undefined
              ? formatCurrency(Math.floor(_990full.GrantsPayable), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="DeferredRevenue" />} value={_990full?.DeferredRevenue !== undefined
              ? formatCurrency(Math.floor(_990full.DeferredRevenue), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="TaxExemptBondLiability" />} value={_990full?.TaxExemptBondLiability !== undefined
              ? formatCurrency(Math.floor(_990full.TaxExemptBondLiability), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="EscrowAccountLiability" />} value={_990full?.EscrowAccountLiability !== undefined
              ? formatCurrency(Math.floor(_990full.EscrowAccountLiability), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="PayablesToOfficers" />} value={_990full?.PayablesToOfficers !== undefined
              ? formatCurrency(Math.floor(_990full.PayablesToOfficers), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="MortgagesNotesPayable" />} value={_990full?.MortgagesNotesPayable !== undefined
              ? formatCurrency(Math.floor(_990full.MortgagesNotesPayable), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="UnsecuredNotesPayable" />} value={_990full?.UnsecuredNotesPayable !== undefined
              ? formatCurrency(Math.floor(_990full.UnsecuredNotesPayable), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="OtherLiabilities" />} value={_990full?.OtherLiabilities !== undefined
              ? formatCurrency(Math.floor(_990full.OtherLiabilities), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="TotalLiabilities" />} value={_990full?.TotalLiabilities !== undefined
              ? formatCurrency(Math.floor(_990full.TotalLiabilities), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="UnrestrictedNetAssets" />} value={_990full?.UnrestrictedNetAssets !== undefined
              ? formatCurrency(Math.floor(_990full.UnrestrictedNetAssets), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="TemporarilyRestrictedNetAssets" />} value={_990full?.TemporarilyRestrictedNetAssets !== undefined
              ? formatCurrency(Math.floor(_990full.TemporarilyRestrictedNetAssets), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="PermanentlyRestrictedNetAssets" />} value={_990full?.PermanentlyRestrictedNetAssets !== undefined
              ? formatCurrency(Math.floor(_990full.PermanentlyRestrictedNetAssets), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="CapitalStockPrincipal" />} value={_990full?.CapitalStockPrincipal !== undefined
              ? formatCurrency(Math.floor(_990full.CapitalStockPrincipal), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="PaidInCapitalSurplus" />} value={_990full?.PaidInCapitalSurplus !== undefined
              ? formatCurrency(Math.floor(_990full.PaidInCapitalSurplus), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="RetainedEarnings" />} value={_990full?.RetainedEarnings !== undefined
              ? formatCurrency(Math.floor(_990full.RetainedEarnings), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="TotalNetAssets" />} value={_990full?.TotalNetAssets !== undefined
              ? formatCurrency(Math.floor(_990full.TotalNetAssets), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="TotalNetAssetsPlusLiabilities" />} value={_990full?.TotalNetAssetsPlusLiabilities !== undefined
              ? formatCurrency(Math.floor(_990full.TotalNetAssetsPlusLiabilities), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="ProgramServiceRevenueNameA" />} value={_990full?.ProgramServiceRevenueNameA} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="ProgramServiceRevenueNameB" />} value={_990full?.ProgramServiceRevenueNameB} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="ProgramServiceRevenueNameC" />} value={_990full?.ProgramServiceRevenueNameC} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="ProgramServiceRevenueNameD" />} value={_990full?.ProgramServiceRevenueNameD} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="ProgramServiceRevenueNameE" />} value={_990full?.ProgramServiceRevenueNameE} />
        <Field label={<EntityFieldDisplayName Entity="990 Full" EntityField="AccountID" />} value={_990full?.AccountID} />
      </div>
    );
  };
