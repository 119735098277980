import {
  CacheLookupPolicy,
  InteractionRequiredAuthError,
  InteractionStatus,
  PublicClientApplication,
} from '@azure/msal-browser';
import { MsalProvider, useMsal } from '@azure/msal-react';
import {
  PropsWithChildren,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomNavigationClient } from './CustomNavigationClient';
import { loginRequest } from './msal';
import { UserPrefs, defaultUserPrefs } from './userPrefs';

type AuthState = {
  userPrefs: UserPrefs;
  user: User;
  token: string;
  isAuthenticated: boolean;
  logout: VoidFunction;
  refreshToken: VoidFunction;
};

type User = {
  ID: number;
  name: string;
  email: string;
};

const emptyContext: AuthState = {
  userPrefs: defaultUserPrefs,
  user: { ID: -1, name: '', email: '' },
  token: '',
  isAuthenticated: false,
  logout: () => undefined,
  refreshToken: () => undefined,
};

const AuthContext = createContext(emptyContext);
export const useAuth = () => useContext(AuthContext);

const LocalProvider = ({ children }: { children: ReactNode }) => {
  const [authContext, setAuthContext] = useState(emptyContext);
  const { accounts, instance, inProgress } = useMsal();

  const refreshToken = useCallback(() => {
    const [account] = accounts;
    const tokenRequest = {
      scopes: loginRequest.scopes,
      account,
      cacheLookupPolicy: CacheLookupPolicy.RefreshTokenAndNetwork,
    };

    if (inProgress === InteractionStatus.None) {
      if (account) {
        instance
          .acquireTokenSilent(tokenRequest)
          .then(({ idToken }) => {
            setAuthContext((prev) => ({ ...prev, token: idToken }));
          })
          .catch((error) => {
            if (error instanceof InteractionRequiredAuthError) {
              instance.acquireTokenRedirect(tokenRequest);
            }
          });
      } else {
        instance.acquireTokenRedirect(tokenRequest);
      }
    }
  }, [accounts, inProgress, instance]);

  const logout = useCallback(() => {
    const [account] = accounts;
    instance.logout({ account });
  }, [accounts, instance]);

  useEffect(() => {
    const [account] = accounts;
    if (account) {
      setAuthContext((prev) => ({
        ...prev,
        user: { ID: 1, name: account.name ?? '[Unknown]', email: account.username },
        isAuthenticated: true,
        logout,
        refreshToken,
      }));
    } else {
      setAuthContext((prev) => ({ ...prev, isAuthenticated: false }));
    }
    refreshToken();
  }, [accounts, logout, refreshToken]);

  return <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>;
};

export const AuthProvider = ({
  children,
  msal,
}: PropsWithChildren<{ msal: PublicClientApplication }>) => {
  const navigate = useNavigate();
  msal.setNavigationClient(new CustomNavigationClient(navigate));
  return (
    <MsalProvider instance={msal}>
      <LocalProvider>{children}</LocalProvider>
    </MsalProvider>
  );
};
