import { useSingleQueueTypeQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { QueueType } from '../api';
  
  export const QueueTypePage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Queue Types"
        queryHook={useSingleQueueTypeQuery}
        Details={<QueueTypeDetails />}
      />
    );
  };

  export const QueueTypeDetails = () => {
    const [queuetype] = useSingleEntity<QueueType>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Queue Types" EntityField="ID" />} value={queuetype?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Queue Types" EntityField="Name" />} value={queuetype?.Name} />
        <Field label={<EntityFieldDisplayName Entity="Queue Types" EntityField="Description" />} value={<BodyText>{queuetype?.Description}</BodyText>} />
        <Field label={<EntityFieldDisplayName Entity="Queue Types" EntityField="DriverClass" />} value={queuetype?.DriverClass} />
        <Field label={<EntityFieldDisplayName Entity="Queue Types" EntityField="DriverImportPath" />} value={queuetype?.DriverImportPath} />
      </div>
    );
  };
