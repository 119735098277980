import { useSingleContactRoleQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { ContactRole } from '../api';
  
  export const ContactRolePage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Contact Roles"
        queryHook={useSingleContactRoleQuery}
        Details={<ContactRoleDetails />}
      />
    );
  };

  export const ContactRoleDetails = () => {
    const [contactrole] = useSingleEntity<ContactRole>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Contact Roles" EntityField="ID" />} value={contactrole?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Contact Roles" EntityField="Name" />} value={contactrole?.Name} />
        <Field label={<EntityFieldDisplayName Entity="Contact Roles" EntityField="Description" />} value={<BodyText>{contactrole?.Description}</BodyText>} />
        <Field label={<EntityFieldDisplayName Entity="Contact Roles" EntityField="Keywords" />} value={<BodyText>{contactrole?.Keywords}</BodyText>} />
      </div>
    );
  };
