import { kebabCase } from 'lodash';
import { Navigate, useLocation, useParams, useSearchParams } from 'react-router-dom';
import { useEntities } from '../context/EntitiesProvider';
import { EntityList } from './EntityList';

export const EntityRoute = () => {
  const { pathname } = useLocation();
  const [searchParams] = useSearchParams();
  const urlViewID = searchParams.get('ViewID');
  const ViewID = urlViewID !== null && isFinite(Number(urlViewID)) ? Number(urlViewID) : undefined;
  const { appName, entityName } = useParams();
  const { AllApplications } = useEntities();

  const appNameIsApp = AllApplications.some(({ Name }) => kebabCase(Name) === appName);
  const appNameIsEntity = AllApplications.some(({ ApplicationEntities }) =>
    ApplicationEntities.some(({ Entity }) => kebabCase(Entity) === appName)
  );

  const appFromUrl =
    !appName && !entityName
      ? AllApplications[0]
      : AllApplications.find(({ Name }) => kebabCase(Name) === appName) ??
        AllApplications.find(({ ApplicationEntities }) =>
          ApplicationEntities.some(({ Entity }) => kebabCase(Entity) === appName)
        );

  const entityFromUrl =
    appNameIsEntity && !appNameIsApp
      ? appFromUrl?.ApplicationEntities.find(({ Entity }) => kebabCase(Entity) === appName)
      : !entityName
      ? appFromUrl?.ApplicationEntities[0]
      : appFromUrl?.ApplicationEntities.find(({ Entity }) => kebabCase(Entity) === entityName);

  const { AllEntitiesByID } = useEntities();
  const entity = AllEntitiesByID[entityFromUrl?.EntityID ?? NaN];

  const incompleteUrl = !appName || !entityName;
  const dataNotFound = !appFromUrl || !entityFromUrl || !entity;

  return !AllApplications.length ? (
    <Navigate to="/error" />
  ) : incompleteUrl ? (
    <Navigate to={`/${kebabCase(appFromUrl?.Name)}/${kebabCase(entityFromUrl?.Entity)}`} />
  ) : dataNotFound ? (
    <Navigate to="/not-found" />
  ) : (
    <EntityList key={pathname} {...{ entity, ViewID }} />
  );
};
