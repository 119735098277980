import { useSingleAuditLogTypeQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { AuditLogType } from '../api';
  
  export const AuditLogTypePage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Audit Log Types"
        queryHook={useSingleAuditLogTypeQuery}
        Details={<AuditLogTypeDetails />}
      />
    );
  };

  export const AuditLogTypeDetails = () => {
    const [auditlogtype] = useSingleEntity<AuditLogType>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Audit Log Types" EntityField="ID" />} value={auditlogtype?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Audit Log Types" EntityField="ParentID" />} value={<EntityLink Entity="Audit Log Types" EntityField="ParentID">{auditlogtype?.ParentID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Audit Log Types" EntityField="Name" />} value={auditlogtype?.Name} />
        <Field label={<EntityFieldDisplayName Entity="Audit Log Types" EntityField="Description" />} value={<BodyText>{auditlogtype?.Description}</BodyText>} />
        <Field label={<EntityFieldDisplayName Entity="Audit Log Types" EntityField="AuthorizationID" />} value={<EntityLink Entity="Audit Log Types" EntityField="AuthorizationID">{auditlogtype?.AuthorizationID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Audit Log Types" EntityField="CreatedAt" />} value={auditlogtype?.CreatedAt &&
            DateTime.fromMillis(auditlogtype.CreatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Audit Log Types" EntityField="UpdatedAt" />} value={auditlogtype?.UpdatedAt &&
            DateTime.fromMillis(auditlogtype.UpdatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Audit Log Types" EntityField="Parent" />} value={auditlogtype?.Parent} />
      </div>
    );
  };
