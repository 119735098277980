import { FieldValidatorType } from '@progress/kendo-react-form';

export const min: (minValue: number) => FieldValidatorType =
  (minValue: number) =>
  (value: unknown, _: unknown, { name }: { name: string }) =>
    Array.isArray(value)
      ? value.length >= minValue
        ? ''
        : `${name} must contain at least ${minValue} items.`
      : typeof value === 'number'
      ? isNaN(value)
        ? `${name} must be a valid number.`
        : value >= minValue
        ? ''
        : `${name} must be greater than or equal to ${minValue}.`
      : typeof value === 'string'
      ? value.length >= minValue
        ? ''
        : `${name} must be at least ${minValue} characters long.`
      : '';

export const max: (maxValue: number) => FieldValidatorType =
  (maxValue: number) =>
  (value: unknown, _: unknown, { name }: { name: string }) =>
    Array.isArray(value)
      ? value.length <= maxValue
        ? ''
        : `${name} must contain no more than ${maxValue} items.`
      : typeof value === 'number'
      ? isNaN(value)
        ? `${name} must be a valid number.`
        : value <= maxValue
        ? ''
        : `${name} must be less than or equal to ${maxValue}.`
      : typeof value === 'string'
      ? value.length <= maxValue
        ? ''
        : `${name} must be no more than ${maxValue} characters long.`
      : '';
