import { makeVar, useReactiveVar } from '@apollo/client';
import { useCallback } from 'react';

export type UserPrefs = {
  accountsQuery?: string;
  contactsQuery?: string;
};

export const defaultUserPrefs = {
  //
};

export const userPrefsState = makeVar(defaultUserPrefs);

export const useUserPrefs = () => {
  const userPrefs = useReactiveVar<UserPrefs>(userPrefsState);

  const setUserPrefs = useCallback((newPrefs: UserPrefs) => {
    userPrefsState(newPrefs);
  }, []);

  return [userPrefs, setUserPrefs] as const;
};
