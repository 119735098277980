import { StackLayout as _StackLayout } from '@progress/kendo-react-layout';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { Title } from '../atoms';

type TabbedDetailProps = {
  title?: string | ReactNode;
  toolbar?: ReactNode;
  header?: ReactNode;
  tabs: ReactNode;
};

const Paper = styled.div`
  padding: ${({ theme }) => theme.spacing(2)};
`;

const StackLayout = styled(_StackLayout)`
  padding: ${({ theme }) => theme.spacing(2)};
`;

export const TabbedDetail = ({ header, title = '', toolbar = <></>, tabs }: TabbedDetailProps) => (
  <StackLayout orientation="vertical" gap={2}>
    <StackLayout orientation="horizontal" gap={2}>
      {typeof title === 'string' ? <Title>{title}</Title> : <>{title}</>}
      {toolbar}
    </StackLayout>
    {header && <>{header}</>}
    <Paper>{tabs}</Paper>
  </StackLayout>
);
