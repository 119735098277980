import { useSingleAIModelQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { AIModel } from '../api';
  
  export const AIModelPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="AI Models"
        queryHook={useSingleAIModelQuery}
        Details={<AIModelDetails />}
      />
    );
  };

  export const AIModelDetails = () => {
    const [aimodel] = useSingleEntity<AIModel>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="AI Models" EntityField="ID" />} value={aimodel?.ID} />
        <Field label={<EntityFieldDisplayName Entity="AI Models" EntityField="Name" />} value={aimodel?.Name} />
        <Field label={<EntityFieldDisplayName Entity="AI Models" EntityField="Vendor" />} value={aimodel?.Vendor} />
        <Field label={<EntityFieldDisplayName Entity="AI Models" EntityField="AIModelTypeID" />} value={<EntityLink Entity="AI Models" EntityField="AIModelTypeID">{aimodel?.AIModelTypeID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="AI Models" EntityField="Description" />} value={<BodyText>{aimodel?.Description}</BodyText>} />
        <Field label={<EntityFieldDisplayName Entity="AI Models" EntityField="DriverClass" />} value={aimodel?.DriverClass} />
        <Field label={<EntityFieldDisplayName Entity="AI Models" EntityField="DriverImportPath" />} value={aimodel?.DriverImportPath} />
        <Field label={<EntityFieldDisplayName Entity="AI Models" EntityField="IsActive" />} value={aimodel?.IsActive} />
        <Field label={<EntityFieldDisplayName Entity="AI Models" EntityField="CreatedAt" />} value={aimodel?.CreatedAt &&
            DateTime.fromMillis(aimodel.CreatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="AI Models" EntityField="UpdatedAt" />} value={aimodel?.UpdatedAt &&
            DateTime.fromMillis(aimodel.UpdatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
      </div>
    );
  };
