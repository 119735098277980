import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ViewFormFields } from '../components';
import { useUser } from '../context/UserProvider';
import { useModalControl } from '../hooks/useModalControl';

export const useViewEditor = () => {
  const [showSettings, hideSettings, settingsExpanded] = useModalControl();
  const toggleSettings = () => {
    settingsExpanded ? hideSettings() : showSettings();
  };

  const location = useLocation();
  useEffect(() => {
    hideSettings();
  }, [hideSettings, location]);

  const { createView, updateView, deleteView } = useUser();

  const saveView = useCallback(
    async ({ ViewID: ID, ...values }: ViewFormFields) => {
      let returnID = ID;
      try {
        if (ID !== undefined) {
          await updateView({ ID, ...values });
        } else {
          const res = await createView(values);
          if (res) {
            returnID = res.data?.CreateUserView?.ID;
          }
        }
        return returnID;
      } catch (e) {
        console.error(e);
      }
    },
    [createView, updateView]
  );

  return { toggleSettings, settingsExpanded, saveView, deleteView, hideSettings };
};
