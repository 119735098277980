import { ReactNode } from 'react';
import styled from 'styled-components';
import { nodeToText } from '../../system/util';
import { FieldValuePair } from './FieldValuePair';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: ${({ theme }) => theme.spacing(1)};
  background-color: ${({ theme }) => theme.colors.white};
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  :last-of-type {
    margin-bottom: 0;
  }
  & span {
    :first-of-type {
      max-width: 300px;
      min-width: 300px;
    }
  }
`;

type FieldProps = {
  label: ReactNode;
  value: ReactNode;
  omitMissing?: boolean;
};

export const Field = ({ label, value, omitMissing = true }: FieldProps) => {
  const valueString = value !== undefined && value !== null && nodeToText(value);

  return omitMissing && !valueString ? (
    <></>
  ) : (
    <Container>
      <FieldValuePair {...{ label, value }} direction="row" />
    </Container>
  );
};
