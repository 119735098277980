import { useSingleAuthorizationQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { Authorization } from '../api';
  
  export const AuthorizationPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Authorizations"
        queryHook={useSingleAuthorizationQuery}
        Details={<AuthorizationDetails />}
      />
    );
  };

  export const AuthorizationDetails = () => {
    const [authorization] = useSingleEntity<Authorization>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Authorizations" EntityField="ID" />} value={authorization?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Authorizations" EntityField="ParentID" />} value={<EntityLink Entity="Authorizations" EntityField="ParentID">{authorization?.ParentID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Authorizations" EntityField="Name" />} value={authorization?.Name} />
        <Field label={<EntityFieldDisplayName Entity="Authorizations" EntityField="IsActive" />} value={authorization?.IsActive} />
        <Field label={<EntityFieldDisplayName Entity="Authorizations" EntityField="UseAuditLog" />} value={authorization?.UseAuditLog} />
        <Field label={<EntityFieldDisplayName Entity="Authorizations" EntityField="Description" />} value={<BodyText>{authorization?.Description}</BodyText>} />
        <Field label={<EntityFieldDisplayName Entity="Authorizations" EntityField="CreatedAt" />} value={authorization?.CreatedAt &&
            DateTime.fromMillis(authorization.CreatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Authorizations" EntityField="UpdatedAt" />} value={authorization?.UpdatedAt &&
            DateTime.fromMillis(authorization.UpdatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
      </div>
    );
  };
