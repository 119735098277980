import { useSingleAccountQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { Account } from '../api';
  
  export const AccountPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Accounts"
        queryHook={useSingleAccountQuery}
        Details={<AccountDetails />}
      />
    );
  };

  export const AccountDetails = () => {
    const [account] = useSingleEntity<Account>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Accounts" EntityField="ID" />} value={account?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Accounts" EntityField="BCMID" />} value={account?.BCMID} />
        <Field label={<EntityFieldDisplayName Entity="Accounts" EntityField="Name" />} value={account?.Name} />
        <Field label={<EntityFieldDisplayName Entity="Accounts" EntityField="TaxID" />} value={account?.TaxID} />
        <Field label={<EntityFieldDisplayName Entity="Accounts" EntityField="Acronym" />} value={account?.Acronym} />
        <Field label={<EntityFieldDisplayName Entity="Accounts" EntityField="OperatingName" />} value={account?.OperatingName} />
        <Field label={<EntityFieldDisplayName Entity="Accounts" EntityField="DisplayName" />} value={account?.DisplayName} />
        <Field label={<EntityFieldDisplayName Entity="Accounts" EntityField="Description" />} value={account?.Description} />
        <Field label={<EntityFieldDisplayName Entity="Accounts" EntityField="AddressLine1" />} value={account?.AddressLine1} />
        <Field label={<EntityFieldDisplayName Entity="Accounts" EntityField="AddressLine2" />} value={account?.AddressLine2} />
        <Field label={<EntityFieldDisplayName Entity="Accounts" EntityField="AddressLine3" />} value={account?.AddressLine3} />
        <Field label={<EntityFieldDisplayName Entity="Accounts" EntityField="City" />} value={account?.City} />
        <Field label={<EntityFieldDisplayName Entity="Accounts" EntityField="StateProvince" />} value={account?.StateProvince} />
        <Field label={<EntityFieldDisplayName Entity="Accounts" EntityField="PostalCode" />} value={account?.PostalCode} />
        <Field label={<EntityFieldDisplayName Entity="Accounts" EntityField="Country" />} value={account?.Country} />
        <Field label={<EntityFieldDisplayName Entity="Accounts" EntityField="ISOCountryCode" />} value={account?.ISOCountryCode} />
        <Field label={<EntityFieldDisplayName Entity="Accounts" EntityField="Domain" />} value={account?.Domain} />
        <Field label={<EntityFieldDisplayName Entity="Accounts" EntityField="Website" />} value={<ExternalUrl>{account?.Website}</ExternalUrl>} />
        <Field label={<EntityFieldDisplayName Entity="Accounts" EntityField="LogoURL" />} value={<ExternalUrl>{account?.LogoURL}</ExternalUrl>} />
        <Field label={<EntityFieldDisplayName Entity="Accounts" EntityField="LeadershipPageURL" />} value={<ExternalUrl>{account?.LeadershipPageURL}</ExternalUrl>} />
        <Field label={<EntityFieldDisplayName Entity="Accounts" EntityField="PhoneNumber" />} value={account?.PhoneNumber} />
        <Field label={<EntityFieldDisplayName Entity="Accounts" EntityField="LinkedIn" />} value={<ExternalUrl>{account?.LinkedIn}</ExternalUrl>} />
        <Field label={<EntityFieldDisplayName Entity="Accounts" EntityField="Facebook" />} value={<ExternalUrl>{account?.Facebook}</ExternalUrl>} />
        <Field label={<EntityFieldDisplayName Entity="Accounts" EntityField="Logo" />} value={account?.Logo} />
        <Field label={<EntityFieldDisplayName Entity="Accounts" EntityField="LastReviewedDate" />} value={account?.LastReviewedDate &&
            DateTime.fromMillis(account.LastReviewedDate).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Accounts" EntityField="ActivityCount" />} value={account?.ActivityCount} />
        <Field label={<EntityFieldDisplayName Entity="Accounts" EntityField="LatestActivityDate" />} value={account?.LatestActivityDate &&
            DateTime.fromMillis(account.LatestActivityDate).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Accounts" EntityField="EarliestActivityDate" />} value={account?.EarliestActivityDate &&
            DateTime.fromMillis(account.EarliestActivityDate).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Accounts" EntityField="CreatedAt" />} value={account?.CreatedAt &&
            DateTime.fromMillis(account.CreatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Accounts" EntityField="UpdatedAt" />} value={account?.UpdatedAt &&
            DateTime.fromMillis(account.UpdatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Accounts" EntityField="Latest990ID" />} value={account?.Latest990ID} />
        <Field label={<EntityFieldDisplayName Entity="Accounts" EntityField="Subsection" />} value={account?.Subsection} />
        <Field label={<EntityFieldDisplayName Entity="Accounts" EntityField="TaxYear" />} value={account?.TaxYear} />
        <Field label={<EntityFieldDisplayName Entity="Accounts" EntityField="TaxMonth" />} value={account?.TaxMonth} />
        <Field label={<EntityFieldDisplayName Entity="Accounts" EntityField="TotalRevenue" />} value={account?.TotalRevenue !== undefined
              ? formatCurrency(Math.floor(account.TotalRevenue), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Accounts" EntityField="ManagementFees" />} value={account?.ManagementFees !== undefined
              ? formatCurrency(Math.floor(account.ManagementFees), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Accounts" EntityField="AccountingFees" />} value={account?.AccountingFees !== undefined
              ? formatCurrency(Math.floor(account.AccountingFees), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Accounts" EntityField="LegalFees" />} value={account?.LegalFees !== undefined
              ? formatCurrency(Math.floor(account.LegalFees), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Accounts" EntityField="AdvertisingExpense" />} value={account?.AdvertisingExpense !== undefined
              ? formatCurrency(Math.floor(account.AdvertisingExpense), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Accounts" EntityField="InformationTechnologyExpense" />} value={account?.InformationTechnologyExpense !== undefined
              ? formatCurrency(Math.floor(account.InformationTechnologyExpense), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Accounts" EntityField="TotalAssets" />} value={account?.TotalAssets !== undefined
              ? formatCurrency(Math.floor(account.TotalAssets), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Accounts" EntityField="NumberEmployees" />} value={account?.NumberEmployees} />
        <Field label={<EntityFieldDisplayName Entity="Accounts" EntityField="ProgramServiceRevenueNameA" />} value={account?.ProgramServiceRevenueNameA} />
        <Field label={<EntityFieldDisplayName Entity="Accounts" EntityField="ProgramServiceRevenueNameB" />} value={account?.ProgramServiceRevenueNameB} />
        <Field label={<EntityFieldDisplayName Entity="Accounts" EntityField="ProgramServiceRevenueNameC" />} value={account?.ProgramServiceRevenueNameC} />
        <Field label={<EntityFieldDisplayName Entity="Accounts" EntityField="ProgramServiceRevenueNameD" />} value={account?.ProgramServiceRevenueNameD} />
        <Field label={<EntityFieldDisplayName Entity="Accounts" EntityField="ProgramServiceRevenueNameE" />} value={account?.ProgramServiceRevenueNameE} />
        <Field label={<EntityFieldDisplayName Entity="Accounts" EntityField="ProgramServiceRevenueA" />} value={account?.ProgramServiceRevenueA !== undefined
              ? formatCurrency(Math.floor(account.ProgramServiceRevenueA), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Accounts" EntityField="ProgramServiceRevenueB" />} value={account?.ProgramServiceRevenueB !== undefined
              ? formatCurrency(Math.floor(account.ProgramServiceRevenueB), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Accounts" EntityField="ProgramServiceRevenueC" />} value={account?.ProgramServiceRevenueC !== undefined
              ? formatCurrency(Math.floor(account.ProgramServiceRevenueC), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Accounts" EntityField="ProgramServiceRevenueD" />} value={account?.ProgramServiceRevenueD !== undefined
              ? formatCurrency(Math.floor(account.ProgramServiceRevenueD), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Accounts" EntityField="ProgramServiceRevenueE" />} value={account?.ProgramServiceRevenueE !== undefined
              ? formatCurrency(Math.floor(account.ProgramServiceRevenueE), {
                hideZeroCents: true,
      }) : undefined} />
      </div>
    );
  };
