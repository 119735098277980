import { Route, Routes } from 'react-router-dom';
import { AppLayout } from '../components';
import { EntityPageRoute } from './EntityPageRoute';
import { EntityRoute } from './EntityRoute';
import Error from './Error';
import { NotAuthorized } from './NotAuthorized';
import NotFound from './NotFound';

export default function Index() {
  return (
    <Routes>
      <Route path="not-authorized" element={<NotAuthorized />} />
      <Route path="*" element={<AppLayout />}>
        <Route path="not-found" element={<NotFound />} />
        <Route path="error" element={<Error />} />

        <Route index element={<EntityRoute />} />
        <Route path=":appName/" element={<EntityRoute />} />
        <Route path=":appName/:entityName/" element={<EntityRoute />} />

        <Route path=":appName/:entityName/:recordID" element={<EntityPageRoute />} />
      </Route>
    </Routes>
  );
}
