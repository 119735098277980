import { Typography } from '@progress/kendo-react-common';
import styled from 'styled-components';

const TextArea = styled.div`
  background-color: ${({ theme }) => theme.colors.paper};
  border: 1px solid ${({ theme }) => theme.colors.divider};
  padding: ${({ theme }) => theme.spacing(1)};
  max-width: 50vw;
  max-height: 50vh;
  overflow-y: scroll;
  overflow-x: clip;
`;

export const BodyText = ({ children }: { children?: string }) => {
  return (
    <TextArea>
      {children?.split('\n').map((line, i) => (
        <Typography.p key={i}>{line}</Typography.p>
      ))}
    </TextArea>
  );
};
