import { NavigationClient, NavigationOptions } from '@azure/msal-browser';
import { NavigateFunction } from 'react-router-dom';

export class CustomNavigationClient extends NavigationClient {
  navigate: NavigateFunction;

  constructor(navigate: NavigateFunction) {
    super();
    this.navigate = navigate;
  }

  async navigateInternal(url: string, options: NavigationOptions) {
    const relativePath = url.replace(window.location.origin, '');
    this.navigate(relativePath, options.noHistory ? { replace: true } : {});

    return false;
  }
}
