import { useSingleIntegrationQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { Integration } from '../api';
  
  export const IntegrationPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Integrations"
        queryHook={useSingleIntegrationQuery}
        Details={<IntegrationDetails />}
      />
    );
  };

  export const IntegrationDetails = () => {
    const [integration] = useSingleEntity<Integration>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Integrations" EntityField="ID" />} value={integration?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Integrations" EntityField="Name" />} value={integration?.Name} />
        <Field label={<EntityFieldDisplayName Entity="Integrations" EntityField="Description" />} value={integration?.Description} />
        <Field label={<EntityFieldDisplayName Entity="Integrations" EntityField="NavigationBaseURL" />} value={<ExternalUrl>{integration?.NavigationBaseURL}</ExternalUrl>} />
        <Field label={<EntityFieldDisplayName Entity="Integrations" EntityField="ClassName" />} value={integration?.ClassName} />
        <Field label={<EntityFieldDisplayName Entity="Integrations" EntityField="ImportPath" />} value={integration?.ImportPath} />
        <Field label={<EntityFieldDisplayName Entity="Integrations" EntityField="BatchMaxRequestCount" />} value={integration?.BatchMaxRequestCount} />
        <Field label={<EntityFieldDisplayName Entity="Integrations" EntityField="BatchRequestWaitTime" />} value={integration?.BatchRequestWaitTime} />
        <Field label={<EntityFieldDisplayName Entity="Integrations" EntityField="CreatedAt" />} value={integration?.CreatedAt &&
            DateTime.fromMillis(integration.CreatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Integrations" EntityField="UpdatedAt" />} value={integration?.UpdatedAt &&
            DateTime.fromMillis(integration.UpdatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
      </div>
    );
  };
