import { useSingleLifecycleStageQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { LifecycleStage } from '../api';
  
  export const LifecycleStagePage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Lifecycle Stages"
        queryHook={useSingleLifecycleStageQuery}
        Details={<LifecycleStageDetails />}
      />
    );
  };

  export const LifecycleStageDetails = () => {
    const [lifecyclestage] = useSingleEntity<LifecycleStage>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Lifecycle Stages" EntityField="ID" />} value={lifecyclestage?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Lifecycle Stages" EntityField="Name" />} value={lifecyclestage?.Name} />
        <Field label={<EntityFieldDisplayName Entity="Lifecycle Stages" EntityField="Description" />} value={lifecyclestage?.Description} />
        <Field label={<EntityFieldDisplayName Entity="Lifecycle Stages" EntityField="CreatedAt" />} value={lifecyclestage?.CreatedAt &&
            DateTime.fromMillis(lifecyclestage.CreatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Lifecycle Stages" EntityField="UpdatedAt" />} value={lifecyclestage?.UpdatedAt &&
            DateTime.fromMillis(lifecyclestage.UpdatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
      </div>
    );
  };
