import { StackLayout as _StackLayout } from '@progress/kendo-react-layout';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { Title } from '../atoms';

type TablePageProps = {
  title: string;
  table: ReactNode;
};

const StackLayout = styled(_StackLayout)`
  padding: ${({ theme }) => theme.spacing(2)};
`;

export const TablePage = ({ title, table }: TablePageProps) => {
  return (
    <StackLayout orientation="vertical" gap={2}>
      <Title>{title}</Title>
      {table}
    </StackLayout>
  );
};
