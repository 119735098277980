import { useSingleUserViewQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { UserView } from '../api';
  
  export const UserViewPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="User Views"
        queryHook={useSingleUserViewQuery}
        Details={<UserViewDetails />}
      />
    );
  };

  export const UserViewDetails = () => {
    const [userview] = useSingleEntity<UserView>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="User Views" EntityField="ID" />} value={userview?.ID} />
        <Field label={<EntityFieldDisplayName Entity="User Views" EntityField="UserID" />} value={<EntityLink Entity="User Views" EntityField="UserID">{userview?.UserID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="User Views" EntityField="EntityID" />} value={<EntityLink Entity="User Views" EntityField="EntityID">{userview?.EntityID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="User Views" EntityField="Name" />} value={userview?.Name} />
        <Field label={<EntityFieldDisplayName Entity="User Views" EntityField="Description" />} value={<BodyText>{userview?.Description}</BodyText>} />
        <Field label={<EntityFieldDisplayName Entity="User Views" EntityField="IsShared" />} value={userview?.IsShared} />
        <Field label={<EntityFieldDisplayName Entity="User Views" EntityField="IsDefault" />} value={userview?.IsDefault} />
        <Field label={<EntityFieldDisplayName Entity="User Views" EntityField="GridState" />} value={<BodyText>{userview?.GridState}</BodyText>} />
        <Field label={<EntityFieldDisplayName Entity="User Views" EntityField="FilterState" />} value={<BodyText>{userview?.FilterState}</BodyText>} />
        <Field label={<EntityFieldDisplayName Entity="User Views" EntityField="CustomFilterState" />} value={userview?.CustomFilterState} />
        <Field label={<EntityFieldDisplayName Entity="User Views" EntityField="WhereClause" />} value={<BodyText>{userview?.WhereClause}</BodyText>} />
        <Field label={<EntityFieldDisplayName Entity="User Views" EntityField="CustomWhereClause" />} value={userview?.CustomWhereClause} />
        <Field label={<EntityFieldDisplayName Entity="User Views" EntityField="CreatedAt" />} value={userview?.CreatedAt &&
            DateTime.fromMillis(userview.CreatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="User Views" EntityField="UpdatedAt" />} value={userview?.UpdatedAt &&
            DateTime.fromMillis(userview.UpdatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="User Views" EntityField="UserName" />} value={userview?.UserName} />
        <Field label={<EntityFieldDisplayName Entity="User Views" EntityField="UserFirstLast" />} value={userview?.UserFirstLast} />
        <Field label={<EntityFieldDisplayName Entity="User Views" EntityField="UserEmail" />} value={<Email>{userview?.UserEmail}</Email>} />
        <Field label={<EntityFieldDisplayName Entity="User Views" EntityField="UserType" />} value={userview?.UserType} />
        <Field label={<EntityFieldDisplayName Entity="User Views" EntityField="Entity" />} value={userview?.Entity} />
        <Field label={<EntityFieldDisplayName Entity="User Views" EntityField="EntityBaseView" />} value={userview?.EntityBaseView} />
      </div>
    );
  };
