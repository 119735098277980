import { useSingleEmailServiceProvidersQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { EmailServiceProviders } from '../api';
  
  export const EmailServiceProvidersPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Email Service Providers"
        queryHook={useSingleEmailServiceProvidersQuery}
        Details={<EmailServiceProvidersDetails />}
      />
    );
  };

  export const EmailServiceProvidersDetails = () => {
    const [emailserviceproviders] = useSingleEntity<EmailServiceProviders>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Email Service Providers" EntityField="ID" />} value={emailserviceproviders?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Email Service Providers" EntityField="Domain" />} value={emailserviceproviders?.Domain} />
      </div>
    );
  };
