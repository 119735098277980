/* eslint-disable @typescript-eslint/no-explicit-any */
import { Button as _Button } from '@progress/kendo-react-buttons';
import { Typography } from '@progress/kendo-react-common';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import React from 'react';
import styled, { css } from 'styled-components';
import { useCreateUserViewMutation, useUpdateUserViewMutation } from '../../api';
import { useNotification } from '../../hooks/useNotification';
import { LoadingButton as _LoadingButton } from '../atoms';
import { FormTextArea, FormTextBox as _FormTextBox, max, min } from '../form';

const FormTextBox = styled(_FormTextBox)`
  width: 100%;
`;

const buttonStyles = css`
  margin: ${({ theme }) => theme.spacing(1)};
  margin-top: ${({ theme }) => theme.spacing(1)};
  width: 100%;
`;

const Button = styled(_Button)`
  ${buttonStyles}
`;

const LoadingButton = styled(_LoadingButton)`
  ${buttonStyles}
`;

type StoreUserViewDialogProps<TSelectedView extends Record<string, unknown> | undefined> = {
  state: any;
  selectedView: TSelectedView;
  onClose: VoidFunction;
};

export default function StoreUserViewDialog<
  TSelectedView extends Record<string, unknown> | undefined = Record<string, unknown> | undefined
>({ onClose, state, selectedView }: StoreUserViewDialogProps<TSelectedView>) {
  const [submitting, setSubmitting] = React.useState(false);
  const [createUserView] = useCreateUserViewMutation();
  const [updateUserView] = useUpdateUserViewMutation();
  const { sendNotification } = useNotification();

  const handleSave = async ({ Name, Description = '' }: Record<string, unknown>) => {
    setSubmitting(true);

    // TODO: UserID must be of logged in user
    const UserID = 1;
    const input = { ID: selectedView?.ID, ...state, Name, Description, UserID };

    if (Name) {
      const mutation = input.ID ? updateUserView : createUserView;
      try {
        await mutation({ variables: { input } });
      } catch (e) {
        console.error(e);
        sendNotification(e instanceof Error ? e.message : JSON.stringify(e), 'error');
      }
    }

    setSubmitting(false);
    onClose();
  };

  return (
    <Form
      onSubmit={handleSave}
      initialValues={selectedView}
      render={({ allowSubmit }: FormRenderProps) => (
        <Dialog onClose={onClose} title="Save Grid State">
          <FormElement>
            <Typography.p>Please enter the name to save the filters and grid state</Typography.p>
            <Field
              id={'Name'}
              name={'Name'}
              label={'View Name'}
              component={FormTextBox}
              validator={[min(1), max(50)]}
            />
            <Field
              id={'Description'}
              name={'Description'}
              label={'View Description'}
              component={FormTextArea}
              rows={3}
              validator={max(250)}
            />
            <DialogActionsBar>
              <Button onClick={onClose}>Cancel</Button>
              <LoadingButton type="submit" disabled={!allowSubmit} loading={submitting}>
                Save
              </LoadingButton>
            </DialogActionsBar>
          </FormElement>
        </Dialog>
      )}
    />
  );
}
