import { Reference } from '@apollo/client';
import { find, groupBy, NumericDictionary } from 'lodash';
import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  CurrentUserQuery,
  useCreateUserFavoriteMutation,
  useCreateUserViewMutation,
  useCurrentUserQuery,
  useDeleteUserFavoriteMutation,
  useDeleteUserViewMutation,
  UserFavorite,
  UserFavoriteScalarsFragmentDoc,
  UserView,
  UserViewScalarsFragmentDoc,
  useUpdateUserViewMutation,
  useUserQuery,
} from '../api';
import { NotAuthorized } from '../pages/NotAuthorized';
import { ensureArray } from '../system/util';

type User = {
  CurrentUser: CurrentUserQuery['CurrentUser'];
  ViewsByEntityID: NumericDictionary<UserView[]>;
  FavoritesByEntityID: NumericDictionary<UserFavorite[]>;
  createFavorite: (args: { EntityID: number; RecordID: number }) => Promise<unknown>;
  deleteFavorite: (args: { EntityID: number; RecordID: number }) => Promise<unknown>;
  createView: (input: {
    FilterState: string;
    GridState: string;
    Name: string;
    Description: string;
    EntityID: number;
  }) => Promise<void | { data?: null | { CreateUserView: { ID: number } } }>;
  updateView: (input: {
    ID: number;
    FilterState: string;
    GridState: string;
    Name: string;
    Description: string;
    EntityID: number;
  }) => Promise<unknown>;
  deleteView: (ViewID: number) => Promise<unknown>;
};

const emptyUser: User = {
  CurrentUser: {
    ID: -1,
    Name: '',
    Type: '',
    Email: '',
    IsActive: false,
    CreatedAt: 0,
    UpdatedAt: 0,
  },
  ViewsByEntityID: {},
  FavoritesByEntityID: {},
  createFavorite: () => Promise.resolve(),
  deleteFavorite: () => Promise.resolve(),
  createView: () => Promise.resolve(),
  updateView: () => Promise.resolve(),
  deleteView: () => Promise.resolve(),
};

const UserContext = createContext(emptyUser);
export const useUser = () => useContext(UserContext);

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<User>(emptyUser);

  const { data: { CurrentUser } = {} } = useCurrentUserQuery();
  const UserID = CurrentUser?.ID ?? -1;
  const { data } = useUserQuery({
    variables: { UserID },
    skip: !CurrentUser,
  });

  const views = useMemo(() => ensureArray(data?.UserViewsByUserID), [data?.UserViewsByUserID]);
  const favorites = useMemo(
    () => ensureArray(data?.UserFavoritesByUserID),
    [data?.UserFavoritesByUserID]
  );
  const ViewsByEntityID = useMemo(() => groupBy(views, 'EntityID'), [views]);
  const FavoritesByEntityID = useMemo(() => groupBy(favorites, 'EntityID'), [favorites]);

  const [createUserFavorite] = useCreateUserFavoriteMutation();
  const [deleteUserFavorite] = useDeleteUserFavoriteMutation();

  const [createUserView] = useCreateUserViewMutation();
  const [updateUserView] = useUpdateUserViewMutation();
  const [deleteUserView] = useDeleteUserViewMutation();

  const createFavorite = useCallback(
    ({ EntityID, RecordID }: Parameters<User['createFavorite']>[0]) =>
      createUserFavorite({
        variables: { input: { RecordID, UserID, EntityID } },
        optimisticResponse: {
          CreateUserFavorite: {
            ID: -1,
            UserID,
            EntityID,
            RecordID,
            CreatedAt: +new Date(),
            UpdatedAt: +new Date(),
            Entity: '',
            EntityBaseTable: '',
            EntityBaseView: '',
            __typename: 'UserFavorite',
          },
        },
        update: (cache, response) => {
          const ref = cache.writeFragment({
            id: cache.identify({
              ID: response.data?.CreateUserFavorite.ID,
              __typename: 'UserFavorite',
            }),
            fragment: UserFavoriteScalarsFragmentDoc,
            data: response.data?.CreateUserFavorite,
          });
          cache.modify({
            fields: {
              UserFavoritesByUserID: (existingRefs = []) => [...existingRefs, ref],
            },
          });
        },
      }),
    [UserID, createUserFavorite]
  );

  const deleteFavorite = useCallback(
    ({ EntityID, RecordID }: Parameters<User['deleteFavorite']>[0]) => {
      const userFavorite = find(favorites, { EntityID, RecordID });
      return userFavorite
        ? deleteUserFavorite({
            variables: { ID: userFavorite.ID },
            optimisticResponse: ({ ID }) => ({ DeleteUserFavorite: ID }),
            update: (cache) => {
              const id = cache.identify({ ID: userFavorite.ID, __typename: 'UserFavorite' });
              cache.modify({
                fields: {
                  UserFavoritesByUserID: (existingRefs: Reference[] = [], { readField }) =>
                    existingRefs.filter((ref) => readField('ID', ref) !== userFavorite.ID),
                },
              });
              cache.evict({ id });
              cache.gc();
            },
          })
        : Promise.resolve();
    },
    [deleteUserFavorite, favorites]
  );

  const createView = useCallback(
    ({
      FilterState,
      GridState,
      Name,
      Description,
      EntityID,
    }: Parameters<User['createView']>[0]) => {
      return createUserView({
        variables: {
          input: {
            UserID, // This should come from the API, not the client
            Name,
            Description,
            FilterState,
            GridState,
            EntityID,
            IsDefault: false, // TODO: Add controls for this
            IsShared: false, // TODO: Add controls for this
            CustomFilterState: false, // What is this for?
            CustomWhereClause: false, // What is this for?
            WhereClause: '', // What is this for?
          },
        },
        optimisticResponse: ({ input }) => ({
          CreateUserView: {
            ID: -1,
            UserID: input.UserID,
            EntityID: input.EntityID,
            Name: input.Name,
            Description: input.Description,
            IsShared: input.IsShared,
            IsDefault: input.IsDefault,
            GridState: input.GridState,
            FilterState: input.FilterState,
            CustomFilterState: input.CustomFilterState,
            WhereClause: input.WhereClause,
            CustomWhereClause: input.CustomWhereClause,
            CreatedAt: +new Date(),
            UpdatedAt: +new Date(),
            UserName: '',
            UserFirstLast: '',
            UserEmail: '',
            UserType: '',
            Entity: '',
            EntityBaseView: '',
            __typename: 'UserView',
          },
        }),
        update: (cache, response) => {
          const ref = cache.writeFragment({
            id: cache.identify({
              ID: response.data?.CreateUserView.ID,
              __typename: 'UserView',
            }),
            fragment: UserViewScalarsFragmentDoc,
            data: response.data?.CreateUserView,
          });
          cache.modify({
            fields: {
              UserViewsByUserID: (existingRefs: Reference[] = []) => [...existingRefs, ref],
            },
          });
        },
      });
    },
    [UserID, createUserView]
  );

  const updateView = useCallback(
    ({
      ID,
      FilterState,
      GridState,
      Name,
      Description,
      EntityID,
    }: Parameters<User['updateView']>[0]) => {
      return updateUserView({
        variables: {
          input: {
            ID,
            UserID, // This should come from the API, not the client
            Name,
            Description,
            FilterState,
            GridState,
            EntityID,
            IsDefault: false, // TODO: Add controls for this
            IsShared: false, // TODO: Add controls for this
            CustomFilterState: false, // What is this for?
            CustomWhereClause: false, // What is this for?
            WhereClause: '', // What is this for?
          },
        },
      });
    },
    [UserID, updateUserView]
  );

  const deleteView = useCallback(
    (ID: number) => {
      return deleteUserView({
        variables: { ID },
        update: (cache) => {
          const id = cache.identify({ ID, __typename: 'UserView' });
          cache.modify({
            fields: {
              UserViewsByUserID: (existingRefs: Reference[] = [], { readField }) =>
                existingRefs.filter((ref) => readField('ID', ref) !== ID),
            },
          });
          cache.evict({ id });
          cache.gc();
        },
      });
    },
    [deleteUserView]
  );

  useEffect(() => {
    CurrentUser &&
      setUser({
        CurrentUser,
        ViewsByEntityID,
        FavoritesByEntityID,
        createFavorite,
        deleteFavorite,
        createView,
        updateView,
        deleteView,
      });
  }, [
    createFavorite,
    createUserFavorite,
    createUserView,
    createView,
    CurrentUser,
    deleteFavorite,
    deleteUserFavorite,
    deleteUserView,
    deleteView,
    favorites,
    FavoritesByEntityID,
    updateUserView,
    updateView,
    UserID,
    views,
    ViewsByEntityID,
  ]);

  return UserID === -1 || !CurrentUser?.IsActive ? (
    <NotAuthorized />
  ) : (
    <UserContext.Provider value={user}>{children}</UserContext.Provider>
  );
};
