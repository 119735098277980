import { useSingleUserRecordLogQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { UserRecordLog } from '../api';
  
  export const UserRecordLogPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="User Record Logs"
        queryHook={useSingleUserRecordLogQuery}
        Details={<UserRecordLogDetails />}
      />
    );
  };

  export const UserRecordLogDetails = () => {
    const [userrecordlog] = useSingleEntity<UserRecordLog>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="User Record Logs" EntityField="ID" />} value={userrecordlog?.ID} />
        <Field label={<EntityFieldDisplayName Entity="User Record Logs" EntityField="UserID" />} value={<EntityLink Entity="User Record Logs" EntityField="UserID">{userrecordlog?.UserID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="User Record Logs" EntityField="EntityID" />} value={<EntityLink Entity="User Record Logs" EntityField="EntityID">{userrecordlog?.EntityID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="User Record Logs" EntityField="RecordID" />} value={userrecordlog?.RecordID} />
        <Field label={<EntityFieldDisplayName Entity="User Record Logs" EntityField="EarliestAt" />} value={userrecordlog?.EarliestAt &&
            DateTime.fromMillis(userrecordlog.EarliestAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="User Record Logs" EntityField="LatestAt" />} value={userrecordlog?.LatestAt &&
            DateTime.fromMillis(userrecordlog.LatestAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="User Record Logs" EntityField="TotalCount" />} value={userrecordlog?.TotalCount} />
        <Field label={<EntityFieldDisplayName Entity="User Record Logs" EntityField="Entity" />} value={userrecordlog?.Entity} />
        <Field label={<EntityFieldDisplayName Entity="User Record Logs" EntityField="UserName" />} value={userrecordlog?.UserName} />
        <Field label={<EntityFieldDisplayName Entity="User Record Logs" EntityField="UserFirstLast" />} value={userrecordlog?.UserFirstLast} />
        <Field label={<EntityFieldDisplayName Entity="User Record Logs" EntityField="UserEmail" />} value={<Email>{userrecordlog?.UserEmail}</Email>} />
        <Field label={<EntityFieldDisplayName Entity="User Record Logs" EntityField="UserSupervisor" />} value={userrecordlog?.UserSupervisor} />
        <Field label={<EntityFieldDisplayName Entity="User Record Logs" EntityField="UserSupervisorEmail" />} value={<Email>{userrecordlog?.UserSupervisorEmail}</Email>} />
      </div>
    );
  };
