import { useSingleTaxReturnDetailQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { TaxReturnDetail } from '../api';
  
  export const TaxReturnDetailPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Tax Return Details"
        queryHook={useSingleTaxReturnDetailQuery}
        Details={<TaxReturnDetailDetails />}
      />
    );
  };

  export const TaxReturnDetailDetails = () => {
    const [taxreturndetail] = useSingleEntity<TaxReturnDetail>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Tax Return Details" EntityField="ID" />} value={taxreturndetail?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Tax Return Details" EntityField="TaxReturnID" />} value={<EntityLink Entity="Tax Return Details" EntityField="TaxReturnID">{taxreturndetail?.TaxReturnID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Tax Return Details" EntityField="Type" />} value={taxreturndetail?.Type} />
        <Field label={<EntityFieldDisplayName Entity="Tax Return Details" EntityField="Name" />} value={taxreturndetail?.Name} />
        <Field label={<EntityFieldDisplayName Entity="Tax Return Details" EntityField="StartingAmount" />} value={taxreturndetail?.StartingAmount !== undefined
              ? formatCurrency(Math.floor(taxreturndetail.StartingAmount), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Return Details" EntityField="EndingAmount" />} value={taxreturndetail?.EndingAmount !== undefined
              ? formatCurrency(Math.floor(taxreturndetail.EndingAmount), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Return Details" EntityField="Code" />} value={taxreturndetail?.Code} />
      </div>
    );
  };
