import { SnackbarProvider } from 'notistack';
import { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';
import { Provider as ApolloProvider } from './apollo';
import { AuthProvider, msalInstance } from './auth';
import { FullScreenSpinner } from './components';
import { EntitiesProvider } from './context/EntitiesProvider';
import { UserProvider } from './context/UserProvider';
import Pages from './pages';
import { AppTheme } from './theme';

const GlobalStyle = createGlobalStyle`
  //
`;

function App() {
  return (
    <AppTheme>
      <GlobalStyle />
      <SnackbarProvider>
        <BrowserRouter>
          <Suspense fallback={<FullScreenSpinner />}>
            <AuthProvider msal={msalInstance}>
              <ApolloProvider>
                <UserProvider>
                  <EntitiesProvider>
                    <Pages />
                  </EntitiesProvider>
                </UserProvider>
              </ApolloProvider>
            </AuthProvider>
          </Suspense>
        </BrowserRouter>
      </SnackbarProvider>
    </AppTheme>
  );
}

export default App;
