import { find } from 'lodash';
import { useEntities } from '../../context/EntitiesProvider';

type EntityFieldDisplayNameProps = {
  Entity: string;
  EntityField: string;
};

export const EntityFieldDisplayName = ({ Entity, EntityField }: EntityFieldDisplayNameProps) => {
  const { AllEntitiesByName } = useEntities();
  const field = find(AllEntitiesByName[Entity].EntityFields, { Name: EntityField });
  return <>{field?.DisplayName ?? field?.Name ?? EntityField}</>;
};
