import { useSingleCampaignQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { Campaign } from '../api';
  
  export const CampaignPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Campaigns"
        queryHook={useSingleCampaignQuery}
        Details={<CampaignDetails />}
      />
    );
  };

  export const CampaignDetails = () => {
    const [campaign] = useSingleEntity<Campaign>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Campaigns" EntityField="ID" />} value={campaign?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Campaigns" EntityField="BCMID" />} value={campaign?.BCMID} />
        <Field label={<EntityFieldDisplayName Entity="Campaigns" EntityField="Name" />} value={campaign?.Name} />
        <Field label={<EntityFieldDisplayName Entity="Campaigns" EntityField="EmployeeID" />} value={<EntityLink Entity="Campaigns" EntityField="EmployeeID">{campaign?.EmployeeID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Campaigns" EntityField="StatusID" />} value={campaign?.StatusID} />
        <Field label={<EntityFieldDisplayName Entity="Campaigns" EntityField="ExternalSystemCreatedAt" />} value={campaign?.ExternalSystemCreatedAt &&
            DateTime.fromMillis(campaign.ExternalSystemCreatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Campaigns" EntityField="CompanyIntegrationID" />} value={<EntityLink Entity="Campaigns" EntityField="CompanyIntegrationID">{campaign?.CompanyIntegrationID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Campaigns" EntityField="ExternalSystemRecordID" />} value={campaign?.ExternalSystemRecordID} />
        <Field label={<EntityFieldDisplayName Entity="Campaigns" EntityField="CreatedAt" />} value={campaign?.CreatedAt &&
            DateTime.fromMillis(campaign.CreatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Campaigns" EntityField="UpdatedAt" />} value={campaign?.UpdatedAt &&
            DateTime.fromMillis(campaign.UpdatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Campaigns" EntityField="Employee" />} value={campaign?.Employee} />
      </div>
    );
  };
