import { gql, useQuery } from '@apollo/client';
import { Skeleton } from '@progress/kendo-react-indicators';
import { find, kebabCase } from 'lodash';
import { Link, useParams } from 'react-router-dom';
import { useEntities } from '../../context/EntitiesProvider';

type EntityLinkProps = {
  Entity: string;
  EntityField: string;
  children?: number;
};

const entityQuery = (ClassName: string, nameField = '', fallbackNameField = '') => gql`
  query ${ClassName}Name($ID: Int!) {
    ${ClassName}(ID: $ID) { 
      ID
      ${nameField}
      ${fallbackNameField}
    }
  }
`;

export const EntityLink = ({ Entity, EntityField, children }: EntityLinkProps) => {
  const invalidID = typeof children !== 'number' || children === -1;
  const { appName } = useParams();
  const { AllEntitiesByName, AllApplications } = useEntities();
  const entity = AllEntitiesByName[Entity];
  const entityField = find(entity?.EntityFields, { Name: EntityField });
  const relatedEntity = AllEntitiesByName[entityField?.RelatedEntity ?? ''];

  const entityNameField = find(relatedEntity?.EntityFields, 'IsNameField');
  const entityFallbackNameField = find(relatedEntity?.EntityFields, { Name: 'Name' });

  const urlApp = AllApplications.find(({ Name }) => kebabCase(Name) === appName)?.Name;
  const defaultApp = AllApplications.find(
    ({ Name }) => kebabCase(Name) === entityField?.RelatedEntity
  )?.Name;

  const { data } = useQuery(
    entityQuery(
      entityField?.RelatedEntityClassName ?? '',
      entityNameField?.Name,
      entityFallbackNameField?.Name
    ),
    {
      variables: { ID: children },
      skip: invalidID || !entityField || !(entityNameField || entityFallbackNameField),
    }
  );

  const name = data ? (
    data[relatedEntity.ClassName]?.[entityNameField?.Name ?? ''] ??
    data[relatedEntity.ClassName]?.[entityFallbackNameField?.Name ?? ''] ?? <Skeleton />
  ) : (
    <Skeleton />
  );

  return (
    <Link
      to={`/${kebabCase(urlApp ?? defaultApp)}/${kebabCase(
        entityField?.RelatedEntity
      )}/${children}`}
    >
      {name}
    </Link>
  );
};
