import { Typography } from '@progress/kendo-react-common';
import { Loader, LoaderSize } from '@progress/kendo-react-indicators';
import styled from 'styled-components';

const Page = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
`;

export const FullScreenSpinner = ({
  size = 'large',
  message = '',
}: {
  size?: LoaderSize;
  message?: string;
}) => {
  return (
    <Page>
      <Loader size={size} />
      {message && (
        <Typography.p align="center" marginTop={2}>
          {message}
        </Typography.p>
      )}
    </Page>
  );
};
