import styled from 'styled-components';

const StyledA = styled.a({
  color: 'red',
  transition: '0.3s',
  textDecoration: 'none',
  '&:hover': {
    color: 'blue',
  },
});

type ExternalUrlTagProps = {
  url?: string;
};

export const ExternalUrlTag = ({ url = '' }: ExternalUrlTagProps) => {
  return (
    <StyledA href={url} target="_blank" rel="noreferrer">
      {url.includes('http') ? url : `https://${url}`}
    </StyledA>
  );
};
