import { useSingleTaxReturnContractorQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { TaxReturnContractor } from '../api';
  
  export const TaxReturnContractorPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Tax Return Contractors"
        queryHook={useSingleTaxReturnContractorQuery}
        Details={<TaxReturnContractorDetails />}
      />
    );
  };

  export const TaxReturnContractorDetails = () => {
    const [taxreturncontractor] = useSingleEntity<TaxReturnContractor>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Tax Return Contractors" EntityField="ID" />} value={taxreturncontractor?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Tax Return Contractors" EntityField="TaxReturnID" />} value={<EntityLink Entity="Tax Return Contractors" EntityField="TaxReturnID">{taxreturncontractor?.TaxReturnID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Tax Return Contractors" EntityField="Name" />} value={taxreturncontractor?.Name} />
        <Field label={<EntityFieldDisplayName Entity="Tax Return Contractors" EntityField="Type" />} value={taxreturncontractor?.Type} />
        <Field label={<EntityFieldDisplayName Entity="Tax Return Contractors" EntityField="Amount" />} value={taxreturncontractor?.Amount !== undefined
              ? formatCurrency(Math.floor(taxreturncontractor.Amount), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Tax Return Contractors" EntityField="AddressLine1" />} value={taxreturncontractor?.AddressLine1} />
        <Field label={<EntityFieldDisplayName Entity="Tax Return Contractors" EntityField="City" />} value={taxreturncontractor?.City} />
        <Field label={<EntityFieldDisplayName Entity="Tax Return Contractors" EntityField="StateProvince" />} value={taxreturncontractor?.StateProvince} />
        <Field label={<EntityFieldDisplayName Entity="Tax Return Contractors" EntityField="PostalCode" />} value={taxreturncontractor?.PostalCode} />
        <Field label={<EntityFieldDisplayName Entity="Tax Return Contractors" EntityField="AccountID" />} value={<EntityLink Entity="Tax Return Contractors" EntityField="AccountID">{taxreturncontractor?.AccountID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Tax Return Contractors" EntityField="Account" />} value={taxreturncontractor?.Account} />
      </div>
    );
  };
