import { useSingleQueueTaskQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { QueueTask } from '../api';
  
  export const QueueTaskPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Queue Tasks"
        queryHook={useSingleQueueTaskQuery}
        Details={<QueueTaskDetails />}
      />
    );
  };

  export const QueueTaskDetails = () => {
    const [queuetask] = useSingleEntity<QueueTask>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Queue Tasks" EntityField="ID" />} value={queuetask?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Queue Tasks" EntityField="QueueID" />} value={<EntityLink Entity="Queue Tasks" EntityField="QueueID">{queuetask?.QueueID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Queue Tasks" EntityField="Status" />} value={queuetask?.Status} />
        <Field label={<EntityFieldDisplayName Entity="Queue Tasks" EntityField="StartedAt" />} value={queuetask?.StartedAt &&
            DateTime.fromMillis(queuetask.StartedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Queue Tasks" EntityField="EndedAt" />} value={queuetask?.EndedAt &&
            DateTime.fromMillis(queuetask.EndedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Queue Tasks" EntityField="Output" />} value={<BodyText>{queuetask?.Output}</BodyText>} />
        <Field label={<EntityFieldDisplayName Entity="Queue Tasks" EntityField="ErrorMessage" />} value={<BodyText>{queuetask?.ErrorMessage}</BodyText>} />
        <Field label={<EntityFieldDisplayName Entity="Queue Tasks" EntityField="Comments" />} value={<BodyText>{queuetask?.Comments}</BodyText>} />
      </div>
    );
  };
