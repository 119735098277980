import { useSingleAuditLogQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { AuditLog } from '../api';
  
  export const AuditLogPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Audit Logs"
        queryHook={useSingleAuditLogQuery}
        Details={<AuditLogDetails />}
      />
    );
  };

  export const AuditLogDetails = () => {
    const [auditlog] = useSingleEntity<AuditLog>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Audit Logs" EntityField="ID" />} value={auditlog?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Audit Logs" EntityField="AuditLogTypeID" />} value={<EntityLink Entity="Audit Logs" EntityField="AuditLogTypeID">{auditlog?.AuditLogTypeID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Audit Logs" EntityField="UserID" />} value={<EntityLink Entity="Audit Logs" EntityField="UserID">{auditlog?.UserID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Audit Logs" EntityField="AuthorizationID" />} value={<EntityLink Entity="Audit Logs" EntityField="AuthorizationID">{auditlog?.AuthorizationID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Audit Logs" EntityField="Status" />} value={auditlog?.Status} />
        <Field label={<EntityFieldDisplayName Entity="Audit Logs" EntityField="Details" />} value={<BodyText>{auditlog?.Details}</BodyText>} />
        <Field label={<EntityFieldDisplayName Entity="Audit Logs" EntityField="EntityID" />} value={<EntityLink Entity="Audit Logs" EntityField="EntityID">{auditlog?.EntityID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Audit Logs" EntityField="RecordID" />} value={auditlog?.RecordID} />
        <Field label={<EntityFieldDisplayName Entity="Audit Logs" EntityField="CreatedAt" />} value={auditlog?.CreatedAt &&
            DateTime.fromMillis(auditlog.CreatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Audit Logs" EntityField="UpdatedAt" />} value={auditlog?.UpdatedAt &&
            DateTime.fromMillis(auditlog.UpdatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Audit Logs" EntityField="AuditLogType" />} value={auditlog?.AuditLogType} />
        <Field label={<EntityFieldDisplayName Entity="Audit Logs" EntityField="User" />} value={auditlog?.User} />
        <Field label={<EntityFieldDisplayName Entity="Audit Logs" EntityField="Entity" />} value={auditlog?.Entity} />
      </div>
    );
  };
