import { useSingleCampaignStepQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { CampaignStep } from '../api';
  
  export const CampaignStepPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Campaign Steps"
        queryHook={useSingleCampaignStepQuery}
        Details={<CampaignStepDetails />}
      />
    );
  };

  export const CampaignStepDetails = () => {
    const [campaignstep] = useSingleEntity<CampaignStep>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Campaign Steps" EntityField="ID" />} value={campaignstep?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Campaign Steps" EntityField="CampaignID" />} value={<EntityLink Entity="Campaign Steps" EntityField="CampaignID">{campaignstep?.CampaignID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Campaign Steps" EntityField="Sequence" />} value={campaignstep?.Sequence} />
        <Field label={<EntityFieldDisplayName Entity="Campaign Steps" EntityField="Subject" />} value={campaignstep?.Subject} />
        <Field label={<EntityFieldDisplayName Entity="Campaign Steps" EntityField="Body" />} value={<BodyText>{campaignstep?.Body}</BodyText>} />
        <Field label={<EntityFieldDisplayName Entity="Campaign Steps" EntityField="ExternalSystemRecordID" />} value={campaignstep?.ExternalSystemRecordID} />
        <Field label={<EntityFieldDisplayName Entity="Campaign Steps" EntityField="CreatedAt" />} value={campaignstep?.CreatedAt &&
            DateTime.fromMillis(campaignstep.CreatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Campaign Steps" EntityField="UpdatedAt" />} value={campaignstep?.UpdatedAt &&
            DateTime.fromMillis(campaignstep.UpdatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
      </div>
    );
  };
