import { find, kebabCase, some } from 'lodash';
import { useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Entity, useEntities } from '../context/EntitiesProvider';

export const useEntityNavigation = (entity: Entity) => {
  const navigate = useNavigate();
  const { appName } = useParams();
  const { AllApplications } = useEntities();

  const appFromUrl = AllApplications.find(({ Name }) => kebabCase(Name) === appName);
  const navAppName =
    appFromUrl && some(appFromUrl.ApplicationEntities, { EntityID: entity.ID })
      ? appFromUrl.Name
      : find(
          AllApplications.flatMap(({ ApplicationEntities }) => ApplicationEntities),
          {
            EntityID: entity.ID,
          }
        )?.Application;

  const navigateToEntity = useCallback(
    (id: unknown) => {
      const validID = id !== null && id !== undefined && isFinite(Number(id));
      const url = `/${kebabCase(navAppName)}/${kebabCase(entity.Name)}/${id}`;
      navAppName !== undefined && validID ? navigate(url) : navigate('/not-found');
    },
    [entity.Name, navAppName, navigate]
  );

  return { navigateToEntity };
};
