import { useSingleUserFavoriteQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { UserFavorite } from '../api';
  
  export const UserFavoritePage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="User Favorites"
        queryHook={useSingleUserFavoriteQuery}
        Details={<UserFavoriteDetails />}
      />
    );
  };

  export const UserFavoriteDetails = () => {
    const [userfavorite] = useSingleEntity<UserFavorite>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="User Favorites" EntityField="ID" />} value={userfavorite?.ID} />
        <Field label={<EntityFieldDisplayName Entity="User Favorites" EntityField="UserID" />} value={<EntityLink Entity="User Favorites" EntityField="UserID">{userfavorite?.UserID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="User Favorites" EntityField="EntityID" />} value={<EntityLink Entity="User Favorites" EntityField="EntityID">{userfavorite?.EntityID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="User Favorites" EntityField="RecordID" />} value={userfavorite?.RecordID} />
        <Field label={<EntityFieldDisplayName Entity="User Favorites" EntityField="CreatedAt" />} value={userfavorite?.CreatedAt &&
            DateTime.fromMillis(userfavorite.CreatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="User Favorites" EntityField="UpdatedAt" />} value={userfavorite?.UpdatedAt &&
            DateTime.fromMillis(userfavorite.UpdatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="User Favorites" EntityField="Entity" />} value={userfavorite?.Entity} />
        <Field label={<EntityFieldDisplayName Entity="User Favorites" EntityField="EntityBaseTable" />} value={userfavorite?.EntityBaseTable} />
        <Field label={<EntityFieldDisplayName Entity="User Favorites" EntityField="EntityBaseView" />} value={userfavorite?.EntityBaseView} />
      </div>
    );
  };
