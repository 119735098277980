import { Typography } from '@progress/kendo-react-common';
import styled from 'styled-components';

const Box = styled.main`
  display: flex;
  align-items: center;
  flex-grow: 1;
  flex-direction: column;
  min-height: 100vh;
`;

const Container = styled.div`
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  @media (min-width: 600px) {
    padding-left: ${({ theme }) => theme.spacing(6)};
    padding-right: ${({ theme }) => theme.spacing(6)};
  }
  @media (min-width: 900px) {
    max-width: 900px;
  }
`;

const Message = styled(Typography.h3)`
  color: ${({ theme }) => theme.colors.primary};
  text-align: center;
  margin: ${({ theme }) => theme.spacing(4, 0)};
`;

export const NotAuthorized = () => {
  return (
    <Box>
      <Container>
        <Box>
          <Message>
            You are not authorized to access the app. Contact your system administrator.
          </Message>
        </Box>
      </Container>
    </Box>
  );
};

export default Error;
