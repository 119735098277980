import { Skeleton } from '@progress/kendo-react-indicators';
import { StackLayout } from '@progress/kendo-react-layout';
import { isValidElement, ReactNode } from 'react';
import styled from 'styled-components';
import { useLoadingContext } from '../../context/LoadingContext';

const Value = styled.span`
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
`;

const Label = styled.span`
  flex-shrink: 0;
  font-size: 12px;
  line-height: 24px;
  font-weight: 500;
`;

const isReactNode = (value: unknown): value is ReactNode =>
  typeof value === 'string' ||
  typeof value === 'number' ||
  typeof value === 'boolean' ||
  isValidElement(value);

export const FieldValuePair = ({
  label,
  value,
  inverted,
  hideUndefined,
  direction,
  loading,
  gap,
}: {
  label: ReactNode;
  value?: unknown;
  inverted?: boolean;
  hideUndefined?: boolean;
  gap?: number;
  direction?: 'row' | 'column';
  loading?: boolean;
}) => {
  const loadingContext = useLoadingContext();
  const valueElement = isReactNode(value) ? value : <>{String(value ?? '')}</>;
  return hideUndefined && !value ? (
    <></>
  ) : inverted ? (
    <StackLayout orientation={direction === 'row' ? 'horizontal' : 'vertical'} gap={gap}>
      <Value>{label}</Value>
      {loading || loadingContext ? (
        <Skeleton />
      ) : (
        <Label color="textSecondary">{valueElement}</Label>
      )}
    </StackLayout>
  ) : (
    <StackLayout orientation={direction === 'row' ? 'horizontal' : 'vertical'} gap={gap}>
      <Label color="textSecondary">{label}</Label>
      {loading || loadingContext ? <Skeleton /> : <Value>{valueElement}</Value>}
    </StackLayout>
  );
};
