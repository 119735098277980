import { useSinglePaymentQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { Payment } from '../api';
  
  export const PaymentPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Payments"
        queryHook={useSinglePaymentQuery}
        Details={<PaymentDetails />}
      />
    );
  };

  export const PaymentDetails = () => {
    const [payment] = useSingleEntity<Payment>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Payments" EntityField="ID" />} value={payment?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Payments" EntityField="BCMID" />} value={payment?.BCMID} />
        <Field label={<EntityFieldDisplayName Entity="Payments" EntityField="InvoiceID" />} value={<EntityLink Entity="Payments" EntityField="InvoiceID">{payment?.InvoiceID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Payments" EntityField="PaymentDate" />} value={payment?.PaymentDate &&
            DateTime.fromMillis(payment.PaymentDate).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Payments" EntityField="Amount" />} value={payment?.Amount !== undefined
              ? formatCurrency(Math.floor(payment.Amount), {
                hideZeroCents: true,
      }) : undefined} />
        <Field label={<EntityFieldDisplayName Entity="Payments" EntityField="Description" />} value={<BodyText>{payment?.Description}</BodyText>} />
        <Field label={<EntityFieldDisplayName Entity="Payments" EntityField="CompanyIntegrationID" />} value={<EntityLink Entity="Payments" EntityField="CompanyIntegrationID">{payment?.CompanyIntegrationID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Payments" EntityField="ExternalSystemRecordID" />} value={payment?.ExternalSystemRecordID} />
        <Field label={<EntityFieldDisplayName Entity="Payments" EntityField="DocumentNumber" />} value={payment?.DocumentNumber} />
        <Field label={<EntityFieldDisplayName Entity="Payments" EntityField="Comments" />} value={<BodyText>{payment?.Comments}</BodyText>} />
        <Field label={<EntityFieldDisplayName Entity="Payments" EntityField="CreatedAt" />} value={payment?.CreatedAt &&
            DateTime.fromMillis(payment.CreatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Payments" EntityField="UpdatedAt" />} value={payment?.UpdatedAt &&
            DateTime.fromMillis(payment.UpdatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Payments" EntityField="AccountID" />} value={<EntityLink Entity="Payments" EntityField="AccountID">{payment?.AccountID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Payments" EntityField="Company" />} value={payment?.Company} />
        <Field label={<EntityFieldDisplayName Entity="Payments" EntityField="CompanyID" />} value={payment?.CompanyID} />
        <Field label={<EntityFieldDisplayName Entity="Payments" EntityField="Account" />} value={payment?.Account} />
        <Field label={<EntityFieldDisplayName Entity="Payments" EntityField="AccountWebsite" />} value={payment?.AccountWebsite} />
        <Field label={<EntityFieldDisplayName Entity="Payments" EntityField="Contact" />} value={payment?.Contact} />
        <Field label={<EntityFieldDisplayName Entity="Payments" EntityField="ContactEmail" />} value={<Email>{payment?.ContactEmail}</Email>} />
      </div>
    );
  };
