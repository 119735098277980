import { useSingleContactQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { Contact } from '../api';
  
  export const ContactPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Contacts"
        queryHook={useSingleContactQuery}
        Details={<ContactDetails />}
      />
    );
  };

  export const ContactDetails = () => {
    const [contact] = useSingleEntity<Contact>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Contacts" EntityField="ID" />} value={contact?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Contacts" EntityField="BCMID" />} value={contact?.BCMID} />
        <Field label={<EntityFieldDisplayName Entity="Contacts" EntityField="FirstName" />} value={contact?.FirstName} />
        <Field label={<EntityFieldDisplayName Entity="Contacts" EntityField="NickName" />} value={contact?.NickName} />
        <Field label={<EntityFieldDisplayName Entity="Contacts" EntityField="LastName" />} value={contact?.LastName} />
        <Field label={<EntityFieldDisplayName Entity="Contacts" EntityField="AccountID" />} value={<EntityLink Entity="Contacts" EntityField="AccountID">{contact?.AccountID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Contacts" EntityField="LastReviewedDate" />} value={contact?.LastReviewedDate &&
            DateTime.fromMillis(contact.LastReviewedDate).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Contacts" EntityField="Title" />} value={contact?.Title} />
        <Field label={<EntityFieldDisplayName Entity="Contacts" EntityField="RoleID" />} value={<EntityLink Entity="Contacts" EntityField="RoleID">{contact?.RoleID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Contacts" EntityField="LevelID" />} value={<EntityLink Entity="Contacts" EntityField="LevelID">{contact?.LevelID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Contacts" EntityField="Email1" />} value={<Email>{contact?.Email1}</Email>} />
        <Field label={<EntityFieldDisplayName Entity="Contacts" EntityField="Email2" />} value={<Email>{contact?.Email2}</Email>} />
        <Field label={<EntityFieldDisplayName Entity="Contacts" EntityField="EmailSource" />} value={contact?.EmailSource} />
        <Field label={<EntityFieldDisplayName Entity="Contacts" EntityField="PhoneNumber" />} value={contact?.PhoneNumber} />
        <Field label={<EntityFieldDisplayName Entity="Contacts" EntityField="ProfilePictureURL" />} value={<ExternalUrl>{contact?.ProfilePictureURL}</ExternalUrl>} />
        <Field label={<EntityFieldDisplayName Entity="Contacts" EntityField="Twitter" />} value={<ExternalUrl>{contact?.Twitter}</ExternalUrl>} />
        <Field label={<EntityFieldDisplayName Entity="Contacts" EntityField="Instagram" />} value={<ExternalUrl>{contact?.Instagram}</ExternalUrl>} />
        <Field label={<EntityFieldDisplayName Entity="Contacts" EntityField="LinkedIn" />} value={<ExternalUrl>{contact?.LinkedIn}</ExternalUrl>} />
        <Field label={<EntityFieldDisplayName Entity="Contacts" EntityField="Facebook" />} value={<ExternalUrl>{contact?.Facebook}</ExternalUrl>} />
        <Field label={<EntityFieldDisplayName Entity="Contacts" EntityField="EmailStatus" />} value={contact?.EmailStatus} />
        <Field label={<EntityFieldDisplayName Entity="Contacts" EntityField="Prefix" />} value={contact?.Prefix} />
        <Field label={<EntityFieldDisplayName Entity="Contacts" EntityField="Suffix" />} value={contact?.Suffix} />
        <Field label={<EntityFieldDisplayName Entity="Contacts" EntityField="Tags" />} value={<BodyText>{contact?.Tags}</BodyText>} />
        <Field label={<EntityFieldDisplayName Entity="Contacts" EntityField="Status" />} value={contact?.Status} />
        <Field label={<EntityFieldDisplayName Entity="Contacts" EntityField="ActivityCount" />} value={contact?.ActivityCount} />
        <Field label={<EntityFieldDisplayName Entity="Contacts" EntityField="LatestActivityDate" />} value={contact?.LatestActivityDate &&
            DateTime.fromMillis(contact.LatestActivityDate).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Contacts" EntityField="EarliestActivityDate" />} value={contact?.EarliestActivityDate &&
            DateTime.fromMillis(contact.EarliestActivityDate).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Contacts" EntityField="CreatedAt" />} value={contact?.CreatedAt &&
            DateTime.fromMillis(contact.CreatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Contacts" EntityField="UpdatedAt" />} value={contact?.UpdatedAt &&
            DateTime.fromMillis(contact.UpdatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Contacts" EntityField="FirstLast" />} value={contact?.FirstLast} />
        <Field label={<EntityFieldDisplayName Entity="Contacts" EntityField="EmailDomain" />} value={contact?.EmailDomain} />
        <Field label={<EntityFieldDisplayName Entity="Contacts" EntityField="Account" />} value={contact?.Account} />
        <Field label={<EntityFieldDisplayName Entity="Contacts" EntityField="Domain" />} value={contact?.Domain} />
        <Field label={<EntityFieldDisplayName Entity="Contacts" EntityField="Website" />} value={<ExternalUrl>{contact?.Website}</ExternalUrl>} />
        <Field label={<EntityFieldDisplayName Entity="Contacts" EntityField="Role" />} value={contact?.Role} />
        <Field label={<EntityFieldDisplayName Entity="Contacts" EntityField="Level" />} value={contact?.Level} />
      </div>
    );
  };
