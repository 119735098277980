import { Button, ButtonProps } from '@progress/kendo-react-buttons';
import { Loader as _Loader } from '@progress/kendo-react-indicators';
import styled from 'styled-components';

export type LoadingButtonProps = ButtonProps & {
  loading?: boolean;
};

const Loader = styled(_Loader)`
  margin-left: ${({ theme }) => theme.spacing(1)};
`;

export const LoadingButton = ({ loading, children, disabled, ...props }: LoadingButtonProps) => {
  return (
    <Button disabled={disabled || loading} {...props}>
      {children}
      {loading && <Loader />}
    </Button>
  );
};
