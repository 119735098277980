import { Reveal } from '@progress/kendo-react-animation';
import { Icon } from '@progress/kendo-react-common';
import { StackLayout } from '@progress/kendo-react-layout';
import { PropsWithChildren } from 'react';
import styled from 'styled-components';

const ExpansionStack = styled(StackLayout)`
  border: 1px solid ${({ theme }) => theme.colors.divider};
`;

const ExpansionHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  padding: ${({ theme }) => theme.spacing(2)};
`;

const ExpansionTitle = styled.span`
  color: ${({ theme }) => theme.colors.secondary};
`;

type ExpanderProps = PropsWithChildren<{
  expanded?: boolean;
  title: string;
  onToggle?: () => void;
}>;

export const Expander = ({ expanded, children, onToggle, title }: ExpanderProps) => {
  return (
    <div>
      <ExpansionStack orientation="vertical">
        <ExpansionHeader onClick={onToggle}>
          <ExpansionTitle>{title}</ExpansionTitle>
          <Icon name={expanded ? 'chevron-up' : 'chevron-down'} size="large" />
        </ExpansionHeader>
        <Reveal transitionEnterDuration={150} transitionExitDuration={150}>
          {expanded && <div>{children}</div>}
        </Reveal>
      </ExpansionStack>
    </div>
  );
};
