import { useSingleActivityQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { Activity } from '../api';
  
  export const ActivityPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Activities"
        queryHook={useSingleActivityQuery}
        Details={<ActivityDetails />}
      />
    );
  };

  export const ActivityDetails = () => {
    const [activity] = useSingleEntity<Activity>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Activities" EntityField="ID" />} value={activity?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Activities" EntityField="BCMID" />} value={activity?.BCMID} />
        <Field label={<EntityFieldDisplayName Entity="Activities" EntityField="EmployeeID" />} value={<EntityLink Entity="Activities" EntityField="EmployeeID">{activity?.EmployeeID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Activities" EntityField="ContactID" />} value={<EntityLink Entity="Activities" EntityField="ContactID">{activity?.ContactID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Activities" EntityField="AccountID" />} value={<EntityLink Entity="Activities" EntityField="AccountID">{activity?.AccountID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Activities" EntityField="DealID" />} value={<EntityLink Entity="Activities" EntityField="DealID">{activity?.DealID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Activities" EntityField="ActivityDate" />} value={activity?.ActivityDate &&
            DateTime.fromMillis(activity.ActivityDate).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Activities" EntityField="IsActive" />} value={activity?.IsActive} />
        <Field label={<EntityFieldDisplayName Entity="Activities" EntityField="Description" />} value={<BodyText>{activity?.Description}</BodyText>} />
        <Field label={<EntityFieldDisplayName Entity="Activities" EntityField="Summary" />} value={<BodyText>{activity?.Summary}</BodyText>} />
        <Field label={<EntityFieldDisplayName Entity="Activities" EntityField="Type" />} value={activity?.Type} />
        <Field label={<EntityFieldDisplayName Entity="Activities" EntityField="Attachment" />} value={activity?.Attachment} />
        <Field label={<EntityFieldDisplayName Entity="Activities" EntityField="CompanyIntegrationID" />} value={<EntityLink Entity="Activities" EntityField="CompanyIntegrationID">{activity?.CompanyIntegrationID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Activities" EntityField="ExternalSystemRecordID" />} value={activity?.ExternalSystemRecordID} />
        <Field label={<EntityFieldDisplayName Entity="Activities" EntityField="CreatedAt" />} value={activity?.CreatedAt &&
            DateTime.fromMillis(activity.CreatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Activities" EntityField="UpdatedAt" />} value={activity?.UpdatedAt &&
            DateTime.fromMillis(activity.UpdatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Activities" EntityField="AttachmentID" />} value={<EntityLink Entity="Activities" EntityField="AttachmentID">{activity?.AttachmentID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Activities" EntityField="Title" />} value={activity?.Title} />
        <Field label={<EntityFieldDisplayName Entity="Activities" EntityField="IsOpened" />} value={activity?.IsOpened} />
        <Field label={<EntityFieldDisplayName Entity="Activities" EntityField="IsBounced" />} value={activity?.IsBounced} />
        <Field label={<EntityFieldDisplayName Entity="Activities" EntityField="IsReplied" />} value={activity?.IsReplied} />
        <Field label={<EntityFieldDisplayName Entity="Activities" EntityField="EmployeeFirstName" />} value={activity?.EmployeeFirstName} />
        <Field label={<EntityFieldDisplayName Entity="Activities" EntityField="EmployeeLastName" />} value={activity?.EmployeeLastName} />
        <Field label={<EntityFieldDisplayName Entity="Activities" EntityField="EmployeeEmail" />} value={<Email>{activity?.EmployeeEmail}</Email>} />
        <Field label={<EntityFieldDisplayName Entity="Activities" EntityField="EmployeeSupervisor" />} value={activity?.EmployeeSupervisor} />
        <Field label={<EntityFieldDisplayName Entity="Activities" EntityField="EmployeeSupervisorFirstName" />} value={activity?.EmployeeSupervisorFirstName} />
        <Field label={<EntityFieldDisplayName Entity="Activities" EntityField="EmployeeSupervisorLastName" />} value={activity?.EmployeeSupervisorLastName} />
        <Field label={<EntityFieldDisplayName Entity="Activities" EntityField="EmployeeSupervisorEmail" />} value={<Email>{activity?.EmployeeSupervisorEmail}</Email>} />
        <Field label={<EntityFieldDisplayName Entity="Activities" EntityField="Account" />} value={activity?.Account} />
        <Field label={<EntityFieldDisplayName Entity="Activities" EntityField="AccountWebsite" />} value={activity?.AccountWebsite} />
        <Field label={<EntityFieldDisplayName Entity="Activities" EntityField="ContactFirstName" />} value={activity?.ContactFirstName} />
        <Field label={<EntityFieldDisplayName Entity="Activities" EntityField="ContactLastName" />} value={activity?.ContactLastName} />
        <Field label={<EntityFieldDisplayName Entity="Activities" EntityField="ContactEmail" />} value={<Email>{activity?.ContactEmail}</Email>} />
        <Field label={<EntityFieldDisplayName Entity="Activities" EntityField="Deal" />} value={<BodyText>{activity?.Deal}</BodyText>} />
        <Field label={<EntityFieldDisplayName Entity="Activities" EntityField="Integration" />} value={activity?.Integration} />
        <Field label={<EntityFieldDisplayName Entity="Activities" EntityField="Company" />} value={activity?.Company} />
        <Field label={<EntityFieldDisplayName Entity="Activities" EntityField="CompanyID" />} value={activity?.CompanyID} />
      </div>
    );
  };
