import { useSingleProjectQuery } from '../api';
  import { SingleEntityPage } from '../components/organisms/SingleEntityPage';
  import { DateTime } from 'luxon';
  import { formatCurrency } from '../system/util';
  import { ExternalUrl, Field, useSingleEntity, Email, EntityLink, EntityFieldDisplayName, BodyText } from '../components';
  import { Project } from '../api';
  
  export const ProjectPage = ({ RecordID }: { RecordID: number }) => {
    return (
      <SingleEntityPage
        RecordID={RecordID}
        EntityName="Projects"
        queryHook={useSingleProjectQuery}
        Details={<ProjectDetails />}
      />
    );
  };

  export const ProjectDetails = () => {
    const [project] = useSingleEntity<Project>();
    return (
      <div>
        <Field label={<EntityFieldDisplayName Entity="Projects" EntityField="ID" />} value={project?.ID} />
        <Field label={<EntityFieldDisplayName Entity="Projects" EntityField="Name" />} value={project?.Name} />
        <Field label={<EntityFieldDisplayName Entity="Projects" EntityField="ExternalSystemRecordID" />} value={project?.ExternalSystemRecordID} />
        <Field label={<EntityFieldDisplayName Entity="Projects" EntityField="StartDate" />} value={project?.StartDate &&
            DateTime.fromMillis(project.StartDate).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Projects" EntityField="DueDate" />} value={project?.DueDate &&
            DateTime.fromMillis(project.DueDate).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Projects" EntityField="IsDeleted" />} value={project?.IsDeleted} />
        <Field label={<EntityFieldDisplayName Entity="Projects" EntityField="CreatedAt" />} value={project?.CreatedAt &&
            DateTime.fromMillis(project.CreatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Projects" EntityField="UpdatedAt" />} value={project?.UpdatedAt &&
            DateTime.fromMillis(project.UpdatedAt).toLocaleString(DateTime.DATETIME_SHORT)} />
        <Field label={<EntityFieldDisplayName Entity="Projects" EntityField="FixedFee" />} value={project?.FixedFee} />
        <Field label={<EntityFieldDisplayName Entity="Projects" EntityField="ProjectRate" />} value={project?.ProjectRate} />
        <Field label={<EntityFieldDisplayName Entity="Projects" EntityField="CompanyIntegrationID" />} value={<EntityLink Entity="Projects" EntityField="CompanyIntegrationID">{project?.CompanyIntegrationID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Projects" EntityField="EmployeeID" />} value={<EntityLink Entity="Projects" EntityField="EmployeeID">{project?.EmployeeID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Projects" EntityField="AccountID" />} value={project?.AccountID} />
        <Field label={<EntityFieldDisplayName Entity="Projects" EntityField="BillingTypeID" />} value={<EntityLink Entity="Projects" EntityField="BillingTypeID">{project?.BillingTypeID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Projects" EntityField="RateTypeID" />} value={<EntityLink Entity="Projects" EntityField="RateTypeID">{project?.RateTypeID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Projects" EntityField="StatusID" />} value={<EntityLink Entity="Projects" EntityField="StatusID">{project?.StatusID}</EntityLink>} />
        <Field label={<EntityFieldDisplayName Entity="Projects" EntityField="BudgetAmount" />} value={project?.BudgetAmount} />
        <Field label={<EntityFieldDisplayName Entity="Projects" EntityField="Employee" />} value={project?.Employee} />
        <Field label={<EntityFieldDisplayName Entity="Projects" EntityField="BillingType" />} value={project?.BillingType} />
        <Field label={<EntityFieldDisplayName Entity="Projects" EntityField="RateType" />} value={project?.RateType} />
        <Field label={<EntityFieldDisplayName Entity="Projects" EntityField="Status" />} value={project?.Status} />
      </div>
    );
  };
